import { ProgressInfoAttributes } from '../../../../Pages/Reports/components/data';
import { APIStatus } from '../../../../types/unions';

export interface IApplicantData {
  applicantId: number;
  brokerageId: number;
  companyName: string;
  createdAt: string;
  email: string;
  officeAddress: string | null;
  qboConnectedBy: string;
  qboState: string;
  refNumber: string;
  termCondition: boolean;
  updatedAt: string;
  isProfileCompleted: boolean;
  ongoingApplicationId: number | null;
  qboAccountLinked?: boolean;
  companyLegalName: string;
  businessPhone: string;
  companyDomain: string;
  companyAddress: string;
  street: string;
  city: string;
  country: string;
  province: string;
  postalCode: string;
  mailingAddressSameAsOffice: boolean;
  mailingAddress: string;
  mailingStreet: string;
  mailingCity: string;
  mailingCountry: string;
  mailingProvince: string;
  mailingPostalCode: string;
  dunsNumber: string | null;
  dunsNumberCompanyName: string | null;
  onboarding: boolean;
  usKey: string | null;
}

export interface IWIPData {
  id: number | null;
  applicantId: number | null;
  progressInfo: Partial<ProgressInfoAttributes>[];
  updatedInfo?: string;
  revenueBasis?: null | string;
  reportInfo: Record<string, any> | null;
  updatedAt: string | null;
}

export interface IAllWIPData {
  status: APIStatus | null;
  error: any;
  // type: "REPORT_PROGRESS";
  allWIPdata: IWIPData;
  message?: string;
  type?:
    | 'REMOVE_WIP_API'
    | 'GET_WIP_DATA_BY_APPLICATIONID_API'
    | 'UPDATE_WIP_API'
    | 'REFRESH_WIP_API'
    | 'ADD_WIP_API'
    | 'UPDATE_REVNUE_API';
}

export const allWIPdataAPI = {
  progressInfo: [],
  applicantId: null,
  id: null,
  reportInfo: null,
  updatedAt: null,
};

const initialState: IAllWIPData = {
  error: null,
  status: null,
  allWIPdata: allWIPdataAPI,
};

export default initialState;
