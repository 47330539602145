import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { APIStatus } from 'src/types/unions';
import { BASEURL } from '../../../App';
import callAPI from '../../../utils/callAPI';
import { tokenExipry } from '../../tokenExpiry';
import { isRejectedActionWithPayload } from '../Documents/slice';
import { IApplicantData } from '../Reports/WorkInProgress/state';

export interface BrokerageDashboardState {
  user: any;
  loading: boolean;
  error: any;
  initialApplication?: boolean;
  submitApplication?: any;
  applicantsByBrokrageData: IApplicantData[] | null;
  // Need refactor
  submitPersonalFinanceForm: boolean;
  status: APIStatus | null;
  type?:
    | 'GET_APPLICANT_BY_BROKERAGE'
    | 'GET_APPLICANT_PROFILE_STATUS'
    | 'GET_ONGOING_APPLICANT'
    | 'SUBMITTED_APPLICATION_STATUS_CHANGE'
    | 'ACCOUNTING_DATA_PERMISSION_APPROVE'
    | 'ACCOUNTING_DATA_PERMISSION_DECLINED'
    | null;
  selectedApplicantData: IApplicantData | null;
}

const initialState: BrokerageDashboardState = {
  user: null,
  loading: false,
  error: null,
  status: null,
  initialApplication: false,
  submitApplication: null,
  applicantsByBrokrageData: null,
  submitPersonalFinanceForm: false,
  selectedApplicantData: null,
};

export const BrokerageDashboard = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

// applicant brokerage all data

export const ApplicantBrokerage = createAsyncThunk(
  'BrokerageDashboard/ApplicantBrokerage',
  async () => {
    try {
      try {
        const response = await callAPI(
          `${BASEURL}/user/list-applicant-by-brokerage`,
          'GET'
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

// Ongoing applicant Initial Allpication
export const InitialApplication = createAsyncThunk(
  'BrokerageDashboard/InitialApplication',
  async ({ id }: { id: number | null }) => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.post(
          `${BASEURL}/application/initial-application`,
          { applicantId: id },
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);
// Ongoing applicant all data
export const OngoingApplicant = createAsyncThunk(
  'BrokerageDashboard/OngoingApplicant',
  async ({ id }: { id: number | null | string }) => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/application/ongoing/${id}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.request(config);

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

// applicant profile status=> isProfileCompleted : boolean
export const checkUserProfileStatus = createAsyncThunk(
  'broker/applicantUserProfileStatus',
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await callAPI(`user/applicant/get/${id}`, 'GET');
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

//aplicant permission
export const Applicantpermission = createAsyncThunk(
  'CreateApplicationForm/Applicantpermission',
  async (
    Adddata: {
      applicantId: number | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios.post(
          `${BASEURL}/application/sent-report-consent`,
          Adddata,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// permission post
export const permission = createAsyncThunk(
  'CreateApplicationForm/permission',

  async (
    AddPermission: {
      id: number | null;
      token: string | null;
      userType: string | null;
      permission: boolean | null;
      deniedReason: string | null;
    },
    { rejectWithValue }
  ) => {
    try {
      // const Token = localStorage.getItem("Token");
      const config = {
        headers: {
          // Authorization: `Bearer ${Token}`,
          // "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASEURL}/permission/assign`,
        AddPermission,
        config
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// check permissions status
export const PermissionStatus = createAsyncThunk(
  'CreateApplicationForm/permissionStatus',
  async (id: any, { rejectWithValue }) => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.get(
          `${BASEURL}/permission/check-status/${id}`,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Need Refactor
// Set submit for all Personal Finance forms
export const SubmitPersonalFinanceForms = createAsyncThunk(
  'CreateApplicationForm/submitPersonalFinanceForms',
  async (state: boolean) => {
    return new Promise<any>((resolve) => {
      resolve({ submitPersonalFinanceForm: !!state }); // Resolve the promise after a delay (simulating an async operation)
    });
  }
);

// final approval submit application
export const SubmitApplication = createAsyncThunk(
  'CreateApplicationForm/submitApplication',
  async (
    Adddata: {
      applicationId: number | null;
      isApproved: boolean | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios.post(
          `${BASEURL}/application/submit-application`,
          Adddata,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// final Approve/Declined application by broker
export const SubmitApplicationBroker = createAsyncThunk(
  'CreateApplicationForm/SubmitApplicationBroker',
  async (
    payload: {
      applicationId: number | null;
      isApproved: boolean | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        `application/final-finish`,
        'POST',
        payload,
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

const BrokerageDashboardSlice = createSlice({
  name: 'BrokerageDashboard',
  initialState,
  reducers: {
    removeBrokerDashboardType(state, action) {
      state.type = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // applicant brokerage all data
      .addCase(ApplicantBrokerage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.type = 'GET_APPLICANT_BY_BROKERAGE';
        state.status = 'loading';
      })
      .addCase(
        ApplicantBrokerage.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.applicantsByBrokrageData = action?.payload?.data;
          state.type = 'GET_APPLICANT_BY_BROKERAGE';
          state.status = 'succeed';
        }
      )
      .addCase(ApplicantBrokerage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
        state.type = 'GET_APPLICANT_BY_BROKERAGE';
        state.status = 'failed';
      })
      // Initial Allpication
      .addCase(InitialApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.type = 'ACCOUNTING_DATA_PERMISSION_DECLINED';
        state.status = 'loading';
      })
      .addCase(
        InitialApplication.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
          state.type = 'ACCOUNTING_DATA_PERMISSION_DECLINED';
          state.status = 'succeed';
        }
      )
      .addCase(InitialApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = isRejectedActionWithPayload(action);
        state.type = 'ACCOUNTING_DATA_PERMISSION_DECLINED';
        state.status = 'failed';
      })
      // OnGoingApplicant
      .addCase(OngoingApplicant.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.type = 'GET_ONGOING_APPLICANT';
        state.status = 'loading';
      })
      .addCase(
        OngoingApplicant.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
          state.type = 'GET_ONGOING_APPLICANT';
          state.status = 'succeed';
        }
      )
      .addCase(OngoingApplicant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
        state.type = 'GET_ONGOING_APPLICANT';
        state.status = 'failed';
      })
      // Applicant permission
      .addCase(Applicantpermission.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.type = 'ACCOUNTING_DATA_PERMISSION_APPROVE';
        state.status = 'loading';
      })
      .addCase(
        Applicantpermission.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
          state.type = 'ACCOUNTING_DATA_PERMISSION_APPROVE';
          state.status = 'succeed';
        }
      )
      .addCase(Applicantpermission.rejected, (state, action) => {
        state.loading = false;
        state.error = isRejectedActionWithPayload(action);
        state.type = 'ACCOUNTING_DATA_PERMISSION_APPROVE';
        state.status = 'failed';
      })
      // permission post

      .addCase(permission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(permission.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(permission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      // permission status
      .addCase(PermissionStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        PermissionStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(PermissionStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Need Refactor
      .addCase(SubmitPersonalFinanceForms.fulfilled, (state, action) => {
        state.loading = false;
        state.submitPersonalFinanceForm =
          action.payload?.submitPersonalFinanceForm;
      })

      // submit application
      .addCase(SubmitApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        SubmitApplication.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.submitApplication = action.payload;
        }
      )
      .addCase(SubmitApplication.rejected, (state, action) => {
        state.loading = false;
        state.submitApplication = false;
      })
      // checkUserProfileStatus application
      .addCase(checkUserProfileStatus.pending, (state) => {
        state.status = 'loading';
        state.type = 'GET_APPLICANT_PROFILE_STATUS';
      })
      .addCase(
        checkUserProfileStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = 'succeed';
          state.type = 'GET_APPLICANT_PROFILE_STATUS';
          state.selectedApplicantData = action.payload.data;
        }
      )
      .addCase(checkUserProfileStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_APPLICANT_PROFILE_STATUS';
        state.error = isRejectedActionWithPayload(action);
      })

      //Broker final Approve/Declined application
      .addCase(SubmitApplicationBroker.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.type = 'SUBMITTED_APPLICATION_STATUS_CHANGE';
      })
      .addCase(
        SubmitApplicationBroker.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = 'succeed';
          state.type = 'SUBMITTED_APPLICATION_STATUS_CHANGE';
          state.submitApplication = action.payload;
        }
      )
      .addCase(SubmitApplicationBroker.rejected, (state, action) => {
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
        state.type = 'SUBMITTED_APPLICATION_STATUS_CHANGE';
      });
  },
});

export default BrokerageDashboardSlice.reducer;
export const { removeBrokerDashboardType } = BrokerageDashboardSlice.actions;
