import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { modals, ModalsProvider } from '@mantine/modals';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AutoComplete from 'react-google-autocomplete';
import { CiCircleInfo } from 'react-icons/ci';
import { FaTimes } from 'react-icons/fa';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppToast, useObjectComparator } from 'src/hooks';
import { calculateDateRanges } from 'src/Pages/ApplicantPages/ApplicantProfile/utils';
import {
  ApplicantProfileUpdate,
  FinancialYearDateRange,
} from 'src/Redux/Applicant/ApplicantProfile/ApplicantProfile';
import { fetchApplicantDetails } from 'src/Redux/Broker/BrokerProfile/BrokerProfile';
import {
  ApplicantBrokerage,
  Applicantpermission,
  InitialApplication,
} from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { RootState, useAppDispatch, useAppSelector } from 'src/Redux/Store';
import {
  addFocusEventListenerOnInput,
  checkLeapYear,
  formatAddress,
  removeFocusEventListenerOnInput,
  trimStringValues,
} from 'src/utils/helpers';
import { ApplicatProfileSchema } from 'src/ValidationSchema/Auth/Index';
import { AutoPopulateModal } from '../BrokerDashboard/BrokerDashboard';
import ApplicantProfileUpdateAppListModal from './component/ApplicantProfileUpdateAppListModal';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDvWG26al2Ons0JrDhxHWuaDPha0TA7kzs';

const BrokerApplicantProfile = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [autoPopulateModalOpen, setAutoPopulateModalOpen] = useState(false);
  const [qboModal, setQboModal] = useState(false);

  const [isProfileUpdate, setIsProfileUpdate] = useState(false);
  const [applicantId, setApplicantId] = useState<string>('');
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const [isFocused, setIsFocused] = useState<Record<string, boolean>>({
    companyName: false,
    email: false,
    companyLegalName: false,
    companyAddress: false,
    mailingAddress: false,
    businessPhone: false,
    companyDomain: false,
  });
  const [applicationId, setApplicationId] = useState<number | null>(null);
  const [loader, setLoader] = useState<{
    confirmBtnLoader: boolean;
    declinedBtnloader: boolean;
  }>({ confirmBtnLoader: false, declinedBtnloader: false });
  const [isProfileUpdateModal, setIsProfileUpdateModal] = useState(false);
  const [applicationList, setApplicationList] = useState<IApplication[]>([]);
  const [disableParentTooltip, setDisableParentTooltip] = useState({
    companyLegalName: false,
    companyAddress: false,
  });
  const [fiscalYearDateRange, setFiscalYearDateRange] =
    useState<FinancialYearDateRange | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const Toast = useToast();
  const companyAddressRef: any = useRef(null);
  const mailingAddressRef: any = useRef(null);
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );
  // const applicantProfile = useAppSelector((state) => state.applicantProfile);
  const toast = useAppToast();

  const selectedApplicantId = searchParams.get('applicantId');

  const brokerdata = useSelector((state: RootState) => state?.broker);
  const userApplicantData = brokerdata?.user;
  const { areDifferent, setInitialValues, setNewValues } =
    useObjectComparator();
  const fiscalYearEndDateRef = useRef<DatePickerInput>(null);

  // useEffect(() => {
  //   const { status, type, applicantAppList, error } = applicantProfile;
  //   // console.log('applicantAppList', applicantAppList, type);

  //   switch (status) {
  //     case 'succeed':
  //       if (type === 'GET_APPLICANT_APP_LIST' && isSubmitForm) {
  //         console.log('applicantAppList', applicantAppList);
  //         if (applicantAppList?.data && !!applicantAppList?.data?.length) {
  //           setIsProfileUpdateModal(true);
  //         } else {
  //         }
  //       }
  //       break;
  //     case 'failed':
  //       if (type === 'GET_APPLICANT_APP_LIST') {
  //         toast({
  //           title: error,
  //           status: 'error',
  //         });
  //       }
  //       break;

  //     default:
  //       break;
  //   }
  // }, [applicantProfile.status]);

  useEffect(() => {
    if (!brokerageDashboard.applicantsByBrokrageData) {
      dispatch(ApplicantBrokerage());
    } else {
      setApplicantOptions(brokerageDashboard.applicantsByBrokrageData);
    }
    if (selectedApplicantId) {
      setApplicantId(selectedApplicantId);
      dispatch(fetchApplicantDetails({ applicantId: selectedApplicantId }));
    }
  }, []);

  useEffect(() => {
    const selectApplicationData = applicantOptions?.find(
      (item: any) => item?.applicantId === Number(applicantId)
    );

    if (selectApplicationData) {
      setApplicationId(selectApplicationData?.ongoingApplicationId);
    }
  }, [applicantId]);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData, user, error } =
      brokerageDashboard;

    switch (status) {
      case 'loading':
        if (type === 'ACCOUNTING_DATA_PERMISSION_APPROVE') {
          setLoader({ ...loader, confirmBtnLoader: true });
        }
        if (type === 'ACCOUNTING_DATA_PERMISSION_DECLINED') {
          setLoader({ ...loader, declinedBtnloader: true });
        }
        break;
      case 'succeed':
        if (
          type === 'GET_APPLICANT_BY_BROKERAGE' &&
          !!applicantsByBrokrageData?.length
        ) {
          setApplicantOptions(applicantsByBrokrageData);
        }
        //comment below code -> logic change for multiple application -> (initially if one application then first time on complete profile create application autopopulate modal come and after on update application exist then after update api direct navigate to form)
        // if (type === 'GET_ONGOING_APPLICANT' && isSubmitForm) {
        //   const { user } = brokerageDashboard;
        //   if (user?.data?.applicationId) {
        //     setIsSubmitForm(false);
        //     navigate(`/application/${applicationId}`);
        //   } else {
        //     setIsSubmitForm(false);
        //     setQboModal(true);
        //   }
        // }
        if (
          (type === 'ACCOUNTING_DATA_PERMISSION_APPROVE' ||
            type === 'ACCOUNTING_DATA_PERMISSION_DECLINED') &&
          (autoPopulateModalOpen || qboModal)
        ) {
          toast({
            title: user?.data?.msg,
            status: 'success',
          });
          if (type === 'ACCOUNTING_DATA_PERMISSION_APPROVE') {
            setLoader({ ...loader, confirmBtnLoader: false });
          } else if (type === 'ACCOUNTING_DATA_PERMISSION_DECLINED') {
            setLoader({ ...loader, declinedBtnloader: false });
          }
          navigate(`/application/${user?.data?.applicationId}`);
        }
        break;
      case 'failed':
        if (
          (type === 'ACCOUNTING_DATA_PERMISSION_APPROVE' ||
            type === 'ACCOUNTING_DATA_PERMISSION_DECLINED') &&
          (autoPopulateModalOpen || qboModal)
        ) {
          toast({
            title: error,
            status: 'error',
          });
          setLoader({
            ...loader,
            confirmBtnLoader: false,
            declinedBtnloader: false,
          });
        }
        break;

      default:
        break;
    }
  }, [brokerageDashboard.status, applicantId]);

  // comment below code -> logic change for multiple application
  // useEffect(() => {
  //   const { status, type, user } = brokerdata;
  //   if (status === 'succeed') {
  //     if (type === 'GET_APPLICANT') {
  //       if (user.data?.isProfileCompleted && isSubmitForm) {
  //         // dispatch(OngoingApplicant({ id: applicantId }) as any);
  //       }
  //     }
  //   }
  // }, [brokerdata.status, applicantId]);

  //coment below code for hide ApplicantList model - on update only update profile - do not show AppList Model,QBOModel
  // useEffect(() => {
  //   const { status, type, user } = brokerdata;
  //   if (status === 'succeed') {
  //     if (type === 'GET_APPLICANT') {
  //       if (
  //         user.data?.isProfileCompleted &&
  //         isSubmitForm &&
  //         !isProfileUpdateModal
  //       ) {
  //         setIsSubmitForm(false);
  //         setQboModal(true);
  //       } else {
  //         setIsSubmitForm(false);
  //         setIsProfileUpdateModal(false);
  //       }
  //     }
  //   }
  // }, [brokerdata.status, applicantId]);

  useEffect(() => {
    const { status, type, user } = brokerdata;
    if (status === 'succeed') {
      if (type === 'GET_APPLICANT') {
        setIsSubmitForm(false);
      }
    }
  }, [brokerdata.status, applicantId]);

  useEffect(() => {
    if (userApplicantData && userApplicantData?.data && applicantId) {
      const dateRanges = calculateDateRanges(
        new Date(userApplicantData?.data?.fiscalYearEnd)
      );
      setFiscalYearDateRange(dateRanges);
      formik.setValues({
        companyName: userApplicantData?.data?.companyName ?? '',
        email: userApplicantData?.data?.email ?? '',
        officeAddress: userApplicantData?.data?.officeAddress ?? '',
        companyLegalName: userApplicantData?.data?.companyLegalName ?? '',
        postalCode: userApplicantData?.data?.postalCode ?? '',
        street: userApplicantData?.data?.street ?? '',
        city: userApplicantData?.data?.city ?? '',
        country: userApplicantData?.data?.country ?? '',
        province: userApplicantData?.data?.province ?? '',
        businessPhone: userApplicantData?.data?.businessPhone ?? '',
        companyDomain: userApplicantData?.data?.companyDomain ?? '',
        mailingAddressSameAsOffice:
          userApplicantData?.data?.mailingAddressSameAsOffice ?? true,
        mailingStreet: userApplicantData?.data?.mailingStreet ?? '',
        mailingCity: userApplicantData?.data?.mailingCity ?? '',
        mailingCountry: userApplicantData?.data?.mailingCountry ?? '',
        mailingProvince: userApplicantData?.data?.mailingProvince ?? '',
        mailingPostalCode: userApplicantData?.data?.mailingPostalCode ?? '',
        companyAddress: userApplicantData?.data?.companyAddress ?? '',
        mailingAddress: userApplicantData?.data?.mailingAddress ?? '',
        fiscalYearEnd: userApplicantData?.data?.fiscalYearEnd ?? '',
      });
      setInitialValues({
        companyName: userApplicantData?.data?.companyName ?? '',
        email: userApplicantData?.data?.email ?? '',
        officeAddress: userApplicantData?.data?.officeAddress ?? '',
        companyLegalName: userApplicantData?.data?.companyLegalName ?? '',
        postalCode: userApplicantData?.data?.postalCode ?? '',
        street: userApplicantData?.data?.street ?? '',
        city: userApplicantData?.data?.city ?? '',
        country: userApplicantData?.data?.country ?? '',
        province: userApplicantData?.data?.province ?? '',
        businessPhone: userApplicantData?.data?.businessPhone ?? '',
        companyDomain: userApplicantData?.data?.companyDomain ?? '',
        mailingAddressSameAsOffice:
          userApplicantData?.data?.mailingAddressSameAsOffice ?? true,
        mailingStreet: userApplicantData?.data?.mailingStreet ?? '',
        mailingCity: userApplicantData?.data?.mailingCity ?? '',
        mailingCountry: userApplicantData?.data?.mailingCountry ?? '',
        mailingProvince: userApplicantData?.data?.mailingProvince ?? '',
        mailingPostalCode: userApplicantData?.data?.mailingPostalCode ?? '',
        companyAddress: userApplicantData?.data?.companyAddress ?? '',
        mailingAddress: userApplicantData?.data?.mailingAddress ?? '',
        fiscalYearEnd: userApplicantData?.data?.fiscalYearEnd ?? '',
      });
    }
  }, [userApplicantData, applicantId]);

  const confirmAutoPopulate = () => {
    try {
      dispatch(
        Applicantpermission({
          applicantId: parseFloat(applicantId),
        }) as any
      );
    } catch (error: any) {
      Toast({
        title: error.message,
        status: 'error',
        isClosable: true,
        duration: 1000,
        position: 'top-right',
      });
    }
  };
  const declinedAutoPopulate = () => {
    try {
      dispatch(
        InitialApplication({
          id: parseFloat(applicantId),
        }) as any
      );
    } catch (error: any) {
      Toast({
        title: error.message,
        status: 'error',
        isClosable: true,
        duration: 1000,
        position: 'top-right',
      });
    }
  };

  const handleApplicantSelect = async (
    event: React.ChangeEvent<HTMLSelectElement> | string
  ) => {
    const selectedId = typeof event === 'string' ? event : event.target.value;
    setApplicantId(selectedId);

    if (selectedId) {
      const actionResult = await dispatch(
        fetchApplicantDetails({ applicantId: selectedId }) as any
      );
      const response = actionResult?.payload;
      if (response?.payload?.status === 401) {
        navigate('/login');
      }
    } else {
      formik.resetForm();
    }
  };

  const brokerApplicantProfileUpdate = async (values: FormValues) => {
    let res = '';
    try {
      const newValues: any = structuredClone(values);
      if (userType !== 'applicant') {
        delete newValues?.companyName;
        delete newValues?.email;
      }
      if (applicantId) {
        const actionResult = await dispatch(
          ApplicantProfileUpdate({
            requestData: trimStringValues(newValues),
            applicantId: applicantId,
          })
        );
        const response = actionResult?.payload;
        res = response?.msg;
        const resStatus = response?.status;

        if (resStatus === 200) {
          setIsSubmitForm(true);
          Toast({
            title: response?.msg,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
          dispatch(fetchApplicantDetails({ applicantId: applicantId }) as any);
        } else {
          if (resStatus === 401) {
            setIsSubmitForm(false);
            navigate('/login');
          } else {
            setIsSubmitForm(false);
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  interface FormValues {
    companyName: string;
    email: string;
    officeAddress: string;
    companyLegalName: string | null;
    postalCode: string | null;
    street: string | null;
    city: string | null;
    country: string | null;
    province: string | null;
    businessPhone: string | null;
    companyDomain: string | null;
    companyAddress: string | null;
    mailingAddressSameAsOffice: boolean;
    mailingStreet: string | null;
    mailingCity: string | null;
    mailingCountry: string | null;
    mailingProvince: string | null;
    mailingPostalCode: string | null;
    mailingAddress: string | null;
    fiscalYearEnd: string | null;
  }

  const initialValues: FormValues = {
    companyName: '',
    email: '',
    officeAddress: '',
    companyLegalName: null,
    postalCode: null,
    street: null,
    city: null,
    country: null,
    province: null,
    businessPhone: null,
    companyDomain: null,
    companyAddress: null,
    mailingAddressSameAsOffice: true,
    mailingStreet: null,
    mailingCity: null,
    mailingCountry: null,
    mailingProvince: null,
    mailingPostalCode: null,
    mailingAddress: null,
    fiscalYearEnd: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ApplicatProfileSchema,
    onSubmit: async (values: FormValues, { resetForm }) => {
      setIsProfileUpdate(true);
      let res = '';
      try {
        if (!areDifferent) {
          setIsProfileUpdate(false);
          return;
        } else {
          brokerApplicantProfileUpdate(values);
        }
        //show Applicant List Model changes - cmnt below code to hide Applicant List Model 8/11
        // else {
        //   // dispatch(getApplicantAppList({ id: Number(applicantId) }) as any);
        //   const actionResult = await dispatch(
        //     getApplicantAppList({ id: Number(applicantId) }) as any
        //   );
        //   const response = actionResult?.payload;
        //   const resStatus = response?.status;
        //   console.log('response', response, actionResult);

        //   if (resStatus === 200) {
        //     if (response?.data && !!response?.data?.length) {
        //       setApplicationList(response?.data);
        //       setIsProfileUpdateModal(true);
        //     } else {
        //       brokerApplicantProfileUpdate(values);
        //     }
        //   } else {
        //     Toast({
        //       title: response?.msg,
        //       status: 'error',
        //       isClosable: true,
        //       duration: 3000,
        //       position: 'top-right',
        //     });
        //   }
        // }

        // comment below code -> logic change for multiple application
        // const newValues: any = structuredClone(values);
        // if (userType !== 'applicant') {
        //   delete newValues?.companyName;
        //   delete newValues?.email;
        // }
        // if (applicantId) {
        //   const actionResult = await dispatch(
        //     ApplicantProfileUpdate({
        //       requestData: trimStringValues(newValues),
        //       applicantId: applicantId,
        //     })
        //   );
        //   const response = actionResult?.payload;
        //   res = response?.msg;
        //   const resStatus = response?.status;

        //   if (resStatus === 200) {
        //     setIsSubmitForm(true);
        //     Toast({
        //       title: response?.msg,
        //       status: 'success',
        //       isClosable: true,
        //       duration: 3000,
        //       position: 'top-right',
        //     });
        //     dispatch(
        //       fetchApplicantDetails({ applicantId: applicantId }) as any
        //     );
        //   } else {
        //     if (resStatus === 401) {
        //       setIsSubmitForm(false);
        //       navigate('/login');
        //     } else {
        //       setIsSubmitForm(false);
        //       Toast({
        //         title: res,
        //         status: 'error',
        //         isClosable: true,
        //         duration: 3000,
        //         position: 'top-right',
        //       });
        //     }
        //   }
        // }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
      setIsProfileUpdate(false);
    },
  });

  const handleClear = (key: string) => {
    formik.setFieldValue(key, '');
    if (key === 'companyAddress') {
      companyAddressRef.current.value = '';
      formik.setValues((prev: any) => {
        return {
          ...prev,
          postalCode: null,
          street: null,
          city: null,
          country: null,
          province: null,
          companyAddress: null,
        };
      });
    } else {
      mailingAddressRef.current.value = '';
      formik.setValues((prev: any) => {
        return {
          ...prev,
          mailingStreet: null,
          mailingCity: null,
          mailingCountry: null,
          mailingProvince: null,
          mailingPostalCode: null,
          mailingAddress: null,
        };
      });
    }
  };

  useEffect(() => {
    setNewValues(formik.values);
  }, [formik.values]);

  const fetchAddressDetails = (postalCode: any) => {
    // `https://api.geoapify.com/v1/geocode/autocomplete?text=${394180}&type=postcode&apiKey=AIzaSyDvWG26al2Ons0JrDhxHWuaDPha0TA7kzs`
    if (postalCode) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=AIzaSyDvWG26al2Ons0JrDhxHWuaDPha0TA7kzs`
      )
        .then((res) => res.json())
        .then((res) => {
          console.log('res', res);

          res?.results[0]?.address_components?.forEach((item: any) => {
            const types = item.types;

            if (
              types.includes('administrative_area_level_2') ||
              types.includes('administrative_area_level_3')
            ) {
              formik.setFieldValue('city', item.long_name);
            }
            if (types.includes('administrative_area_level_1')) {
              formik.setFieldValue('province', item.long_name);
            }
            // if (types.includes('country')) {
            //   formik.setFieldValue('country', item.long_name);
            // }
            if (types.includes('postal_code')) {
              formik.setFieldValue('postalCode', item.long_name);
            }
          });
        });
    }
  };

  const debouncedFetch = useCallback(
    debounce((postalCode) => {
      fetchAddressDetails(postalCode);
    }, 1000),
    []
  );

  const handlePostalCodeChange = (e: any) => {
    formik.setFieldValue('postalCode', e.target.value);
    debouncedFetch(e.target.value);
  };

  const getFiscalYearTooltip = () => {
    return !!formik?.values?.fiscalYearEnd ? (
      <Box
        padding={1}
        display={'flex'}
        flexDirection={'column'}
        gap={1}
        style={{ whiteSpace: 'nowrap' }}
      >
        <Text fontSize="xs">
          Most Recent Interim Financial Statement(
          {fiscalYearDateRange?.mostRecentInterim?.start} -{' '}
          {fiscalYearDateRange?.mostRecentInterim?.end})
        </Text>

        <Text fontSize="xs">
          Most Recent Fiscal Year End(
          {fiscalYearDateRange?.mostRecentFiscalYear?.start} -{' '}
          {fiscalYearDateRange?.mostRecentFiscalYear?.end})
        </Text>
        <Text fontSize="xs">
          1 - Year Previous Fiscal Year End(
          {fiscalYearDateRange?.oneYearPrevious?.start} -{' '}
          {fiscalYearDateRange?.oneYearPrevious?.end})
        </Text>
        <Text fontSize="xs">
          2 - Year Previous Fiscal Year End(
          {fiscalYearDateRange?.twoYearsPrevious?.start} -{' '}
          {fiscalYearDateRange?.twoYearsPrevious?.end})
        </Text>
      </Box>
    ) : (
      'please select Fiscal year ends on'
    );
  };

  const userType = localStorage.getItem('userType');

  console.log('formik', formik, userApplicantData);

  return (
    <>
      <Flex flexDirection={'column'} columnGap={5} py={5} marginLeft={5}>
        <Box
          maxW="max-content"
          display={'flex'}
          alignItems={'center'}
          gap={2}
          justifyContent={'center'}
          margin={'0 auto'}
        >
          <Select
            w="100%"
            defaultValue={applicantId}
            onChange={handleApplicantSelect}
            value={applicantId}
            name="userData.brokerageId"
            borderRadius="5px"
            bg={'#114684'}
            color={'white'}
            textAlign={'center'}
          >
            <option
              value={''}
              style={{ color: 'black' }}
              label="Select Applicant"
            />
            {applicantOptions?.map((user?: any, index?: number) => (
              <option
                style={{ color: 'black' }}
                key={index}
                value={user?.applicantId ?? ''}
                label={user?.companyName ?? ''}
              />
            ))}
          </Select>
        </Box>
      </Flex>
      {applicantId && (
        <Container
          textAlign="center"
          boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
          h="auto"
          w={['90%', '80%', '70%', '45%']}
          //   marginTop={10}
          p="0px 22px 0px 22px"
          borderRadius="20px"
          maxW={'100%'}
        >
          <Box>
            <Text
              fontWeight="600"
              color="#114684"
              textAlign="center"
              fontSize={20}
              pt="10px"
            >
              Applicant Profile
            </Text>
          </Box>

          <form onSubmit={formik.handleSubmit}>
            <ModalsProvider>
              <Box>
                <Box>
                  <Tooltip label="Company Name" placement="right" hasArrow>
                    <Box display="flex" mb={'5px'} mt={2}>
                      <FormControl position="relative">
                        {(isFocused?.companyName ||
                          formik?.values?.companyName) && (
                          <FormLabel
                            position="absolute"
                            left="12px"
                            top={
                              isFocused?.companyName ||
                              formik?.values?.companyName
                                ? '-1px'
                                : '50%'
                            }
                            fontSize={'13px'}
                            transform="translateY(-50%)"
                            transition="0.2s"
                            px="2px"
                            pointerEvents="none"
                            zIndex={3}
                            background={
                              isFocused?.companyName ||
                              formik?.values?.companyName
                                ? 'white'
                                : 'none'
                            }
                          >
                            Company Name{' '}
                            <span style={{ color: 'red' }}> *</span>
                          </FormLabel>
                        )}
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="text"
                          placeholder={
                            !isFocused?.companyName ||
                            !formik?.values?.companyName
                              ? 'Company Name'
                              : ''
                          }
                          name="companyName"
                          value={formik.values.companyName}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          bg={'#f0f5f9'}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            setIsFocused({ ...isFocused, companyName: false });
                          }}
                          onFocus={() =>
                            setIsFocused({ ...isFocused, companyName: true })
                          }
                          border={
                            isFocused?.companyName ||
                            formik?.values?.companyName
                              ? '1px solid gray'
                              : 'none'
                          }
                          _hover={{
                            border:
                              isFocused?.companyName ||
                              formik?.values?.companyName
                                ? '1px solid gray'
                                : 'none',
                          }}
                          borderRadius={'5px'}
                          // focusBorderColor="transparent"
                        />
                      </FormControl>
                    </Box>
                  </Tooltip>
                  {formik.touched.companyName && formik.errors.companyName && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.companyName}
                    </div>
                  )}
                </Box>

                <Tooltip label="Email Address" placement="right" hasArrow>
                  <Box
                    display="flex"
                    mb={'5px'}
                    mt={
                      isFocused?.email || formik?.values?.email ? '13px' : '2px'
                    }
                  >
                    <FormControl position="relative">
                      {(isFocused?.email || formik?.values?.email) && (
                        <FormLabel
                          position="absolute"
                          left="12px"
                          top={
                            isFocused?.email || formik?.values?.email
                              ? '-1px'
                              : '50%'
                          }
                          fontSize={'13px'}
                          transform="translateY(-50%)"
                          transition="0.2s"
                          px="2px"
                          pointerEvents="none"
                          zIndex={3}
                          background={
                            isFocused?.email || formik?.values?.email
                              ? 'white'
                              : 'none'
                          }
                        >
                          Email Address <span style={{ color: 'red' }}> *</span>
                        </FormLabel>
                      )}
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="email"
                        readOnly={true}
                        cursor={'not-allowed'}
                        placeholder={
                          !isFocused?.email || !formik?.values?.email
                            ? 'Email Address'
                            : ''
                        }
                        name="email"
                        borderRadius="5px"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        // focusBorderColor="transparent"
                        bg={'#f0f5f9'}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          setIsFocused({ ...isFocused, email: false });
                        }}
                        onFocus={() =>
                          setIsFocused({ ...isFocused, email: true })
                        }
                        border={
                          isFocused?.email || formik?.values?.email
                            ? '1px solid gray'
                            : 'none'
                        }
                        _hover={{
                          border:
                            isFocused?.email || formik?.values?.email
                              ? '1px solid gray'
                              : 'none',
                        }}
                      />
                    </FormControl>
                  </Box>
                </Tooltip>
                {formik.touched.email && formik.errors.email && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.email}
                  </div>
                )}

                {/* <Tooltip label="Office Address" placement="right" hasArrow>
                <Box display="flex" mb={['20px', '5px']} mt={2}>
                  <AutoComplete
                    apiKey={GOOGLE_MAPS_API_KEY}
                    defaultValue={formik?.values?.officeAddress ?? ''}
                    onPlaceSelected={(place) => {
                      if (place && place.formatted_address) {
                        formik.setFieldValue(
                          'officeAddress',
                          place.formatted_address
                        );
                      } else {
                        formik.setFieldValue('officeAddress', '');
                      }
                    }}
                    onBlur={formik.handleBlur}
                    className="googlelocation"
                    ref={autoCompleteRef}
                    style={{
                      fontSize: '13px',
                    }}
                  />
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    bg={'#f0f5f9'}
                    pr={2}
                    cursor={'pointer'}
                    _hover={{ color: '#114684' }}
                  >
                    {formik?.values?.officeAddress && (
                      <FaTimes onClick={() => handleClear('officeAddress')} />
                    )}
                  </Box>
                </Box>
              </Tooltip>
              {formik.touched.officeAddress && formik.errors.officeAddress && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '15px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {formik.errors.officeAddress}
                </div>
              )} */}

                <Box>
                  <Tooltip
                    label="Company’s Legal Name"
                    placement="right"
                    hasArrow
                    isDisabled={disableParentTooltip?.companyLegalName}
                  >
                    <Box
                      display="flex"
                      mb={'5px'}
                      mt={
                        isFocused?.companyLegalName ||
                        formik?.values?.companyLegalName
                          ? '13px'
                          : '2px'
                      }
                    >
                      <FormControl position="relative">
                        {(isFocused?.companyLegalName ||
                          formik?.values?.companyLegalName) && (
                          <FormLabel
                            position="absolute"
                            left="12px"
                            top={
                              isFocused?.companyLegalName ||
                              formik?.values?.companyLegalName
                                ? '-1px'
                                : '50%'
                            }
                            fontSize={'13px'}
                            transform="translateY(-50%)"
                            transition="0.2s"
                            px="2px"
                            pointerEvents="none"
                            zIndex={3}
                            background={
                              isFocused?.companyLegalName ||
                              formik?.values?.companyLegalName
                                ? 'white'
                                : 'none'
                            }
                          >
                            Company’s Legal Name{' '}
                            <span style={{ color: 'red' }}> *</span>
                          </FormLabel>
                        )}
                        <InputGroup>
                          <Input
                            fontSize={'13px'}
                            w="100%"
                            type="text"
                            readOnly={
                              !!userApplicantData?.data?.companyLegalName
                            }
                            cursor={
                              !!userApplicantData?.data?.companyLegalName
                                ? 'not-allowed'
                                : 'default'
                            }
                            placeholder={
                              !isFocused?.companyLegalName ||
                              !formik?.values?.companyLegalName
                                ? 'Company’s Legal Name'
                                : ''
                            }
                            name="companyLegalName"
                            borderRadius="5px"
                            value={formik.values.companyLegalName ?? ''}
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // focusBorderColor="transparent"
                            bg={'#f0f5f9'}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              setIsFocused({
                                ...isFocused,
                                companyLegalName: false,
                              });
                            }}
                            onFocus={() =>
                              setIsFocused({
                                ...isFocused,
                                companyLegalName: true,
                              })
                            }
                            border={
                              isFocused?.companyLegalName ||
                              formik?.values?.companyLegalName
                                ? '1px solid gray'
                                : 'none'
                            }
                            _hover={{
                              border:
                                isFocused?.companyLegalName ||
                                formik?.values?.companyLegalName
                                  ? '1px solid gray'
                                  : 'none',
                            }}
                          />
                          <InputRightElement
                            onMouseEnter={() =>
                              setDisableParentTooltip({
                                ...disableParentTooltip,
                                companyLegalName: true,
                              })
                            }
                            onMouseLeave={() =>
                              setDisableParentTooltip({
                                ...disableParentTooltip,
                                companyLegalName: false,
                              })
                            }
                          >
                            <Tooltip
                              label="Please ensure the accuracy of the legal name. Modifying this will incur an additional charge."
                              placement="right"
                              hasArrow
                            >
                              <Box>
                                <HiMiniInformationCircle
                                  size={18}
                                  color="#114684"
                                  cursor="pointer"
                                />
                              </Box>
                            </Tooltip>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                    </Box>
                  </Tooltip>
                  {formik.touched.companyLegalName &&
                    formik.errors.companyLegalName && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.companyLegalName}
                      </div>
                    )}
                </Box>
                <Box>
                  <Tooltip
                    label="Company address"
                    placement="right"
                    hasArrow
                    isDisabled={disableParentTooltip?.companyAddress}
                  >
                    <Box
                      display="flex"
                      mb={['20px', '5px']}
                      mt={
                        isFocused?.companyAddress ||
                        formik?.values?.companyAddress
                          ? '13px'
                          : '2px'
                      }
                    >
                      <FormControl position="relative">
                        {(isFocused?.companyAddress ||
                          formik?.values?.companyAddress) && (
                          <FormLabel
                            position="absolute"
                            left="12px"
                            top={
                              isFocused?.companyAddress ||
                              formik?.values?.companyAddress
                                ? '-1px'
                                : '50%'
                            }
                            fontSize={'13px'}
                            transform="translateY(-50%)"
                            transition="0.2s"
                            px="2px"
                            pointerEvents="none"
                            zIndex={3}
                            background={
                              isFocused?.companyAddress ||
                              formik?.values?.companyAddress
                                ? 'white'
                                : 'none'
                            }
                          >
                            Company address{' '}
                            <span style={{ color: 'red' }}> *</span>
                          </FormLabel>
                        )}
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <AutoComplete
                            apiKey={GOOGLE_MAPS_API_KEY}
                            id="companyAddress"
                            // defaultValue={formik?.values?.companyAddress ?? ''}
                            value={formik?.values?.companyAddress ?? ''}
                            onChange={(e: any) => {
                              // console.log('e', e?.target?.value);

                              if (!e?.target?.value?.length) {
                                handleClear('companyAddress');
                              } else {
                                formik.setFieldValue(
                                  'companyAddress',
                                  e?.target?.value
                                );
                              }
                            }}
                            onPlaceSelected={(place) => {
                              if (place && place?.formatted_address) {
                                place?.address_components?.forEach(
                                  (item: any) => {
                                    const types = item.types;
                                    // if (
                                    //   types.includes('route') ||
                                    //   types.includes('sublocality_level_1')
                                    // ) {
                                    //   formik.setFieldValue(
                                    //     'street',
                                    //     item.long_name
                                    //   );
                                    // }
                                    if (
                                      // types.includes(
                                      //   'administrative_area_level_2'
                                      // ) ||
                                      // types.includes(
                                      //   'administrative_area_level_3'
                                      // )
                                      types.includes('locality')
                                    ) {
                                      formik.setFieldValue(
                                        'city',
                                        item.long_name
                                      );
                                    }
                                    if (
                                      types.includes(
                                        'administrative_area_level_1'
                                      )
                                    ) {
                                      formik.setFieldValue(
                                        'province',
                                        item.long_name
                                      );
                                    }
                                    if (types.includes('country')) {
                                      formik.setFieldValue(
                                        'country',
                                        item.long_name
                                      );
                                    }
                                    if (types.includes('postal_code')) {
                                      formik.setFieldValue(
                                        'postalCode',
                                        item.long_name
                                      );
                                    }
                                  }
                                );
                                formik.setFieldValue(
                                  'street',
                                  formatAddress(place?.address_components)
                                );
                                formik.setFieldValue(
                                  'companyAddress',
                                  place?.formatted_address
                                );
                              } else {
                                formik.setFieldValue('companyAddress', '');
                              }
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              setIsFocused({
                                ...isFocused,
                                companyAddress: false,
                              });
                            }}
                            onFocus={() =>
                              setIsFocused({
                                ...isFocused,
                                companyAddress: true,
                              })
                            }
                            border={
                              isFocused?.companyAddress ||
                              formik?.values?.companyAddress
                                ? '1px solid gray'
                                : 'none'
                            }
                            _hover={{
                              border:
                                isFocused?.companyAddress ||
                                formik?.values?.companyAddress
                                  ? '1px solid gray'
                                  : 'none',
                            }}
                            className="googlelocation"
                            ref={companyAddressRef}
                            style={{
                              fontSize: '13px',
                              cursor: !!userApplicantData?.data?.companyAddress
                                ? 'not-allowed'
                                : 'default',
                            }}
                            options={{
                              types: [],
                            }}
                            disabled={!!userApplicantData?.data?.companyAddress}
                          />
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            bg={'#f0f5f9'}
                            pr={2}
                            cursor={
                              !!userApplicantData?.data?.companyAddress
                                ? 'not-allowed'
                                : 'pointer'
                            }
                            _hover={{ color: '#114684' }}
                            gap={2}
                          >
                            {formik?.values?.companyAddress && (
                              <FaTimes
                                onClick={() =>
                                  !!userApplicantData?.data?.companyAddress
                                    ? {}
                                    : handleClear('companyAddress')
                                }
                              />
                            )}
                            <Box
                              onMouseEnter={() =>
                                setDisableParentTooltip({
                                  ...disableParentTooltip,
                                  companyAddress: true,
                                })
                              }
                              onMouseLeave={() =>
                                setDisableParentTooltip({
                                  ...disableParentTooltip,
                                  companyAddress: false,
                                })
                              }
                            >
                              <Tooltip
                                label="Please ensure the accuracy of the Postal/Zip Code. Modifying this will incur an additional charge."
                                placement="right"
                                hasArrow
                              >
                                <Box>
                                  <HiMiniInformationCircle
                                    size={18}
                                    color="#114684"
                                    cursor="pointer"
                                  />
                                </Box>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                      </FormControl>
                    </Box>
                  </Tooltip>
                  {formik.touched.companyAddress &&
                    formik.errors.companyAddress && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.companyAddress}
                      </div>
                    )}
                  {formik.errors.postalCode &&
                    !formik.errors.companyAddress &&
                    !formik?.values?.postalCode && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.postalCode}
                      </div>
                    )}
                </Box>
                <Box
                  display={'flex'}
                  flexDir={'column'}
                  gap={'5px'}
                  mt={2}
                  mb={2}
                >
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                  >
                    Use the Office address as the mailing address?
                    <span style={{ color: 'red' }}>
                      {' '}
                      <span style={{ color: 'red' }}>*</span>
                    </span>
                  </Text>
                  <Stack spacing={5} direction="row">
                    <Checkbox
                      colorScheme="green"
                      isChecked={
                        formik.values.mailingAddressSameAsOffice === true
                      }
                      onChange={(e) => {
                        // formik.setFieldValue(
                        //   'mailingAddressSameAsOffice',
                        //   e.target.checked
                        // );
                        formik.setValues((prev: any) => {
                          return {
                            ...prev,
                            mailingAddressSameAsOffice: e.target.checked,
                            mailingStreet: null,
                            mailingCity: null,
                            mailingCountry: null,
                            mailingProvince: null,
                            mailingPostalCode: null,
                            mailingAddress: null,
                          };
                        });
                      }}
                    >
                      Yes
                    </Checkbox>
                    <Checkbox
                      colorScheme="red"
                      isChecked={
                        formik.values.mailingAddressSameAsOffice === false
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          'mailingAddressSameAsOffice',
                          !e.target.checked
                        );
                      }}
                    >
                      No
                    </Checkbox>
                  </Stack>
                </Box>
                {!formik?.values?.mailingAddressSameAsOffice && (
                  <Box>
                    <Tooltip label="Mailing Address" placement="right" hasArrow>
                      <Box
                        display="flex"
                        mb={['20px', '5px']}
                        mt={
                          isFocused?.mailingAddress ||
                          formik?.values?.mailingAddress
                            ? '13px'
                            : '2px'
                        }
                      >
                        <FormControl position="relative">
                          {(isFocused?.mailingAddress ||
                            formik?.values?.mailingAddress) && (
                            <FormLabel
                              position="absolute"
                              left="12px"
                              top={
                                isFocused?.mailingAddress ||
                                formik?.values?.mailingAddress
                                  ? '-1px'
                                  : '50%'
                              }
                              fontSize={'13px'}
                              transform="translateY(-50%)"
                              transition="0.2s"
                              px="2px"
                              pointerEvents="none"
                              zIndex={3}
                              background={
                                isFocused?.mailingAddress ||
                                formik?.values?.mailingAddress
                                  ? 'white'
                                  : 'none'
                              }
                            >
                              Mailing address{' '}
                              <span style={{ color: 'red' }}> *</span>
                            </FormLabel>
                          )}
                          <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <AutoComplete
                              apiKey={GOOGLE_MAPS_API_KEY}
                              id="mailingAddress"
                              defaultValue={
                                formik?.values?.mailingAddress ?? ''
                              }
                              onChange={(e: any) => {
                                if (!e?.target?.value?.length) {
                                  handleClear('mailingAddress');
                                }
                              }}
                              onPlaceSelected={(place) => {
                                if (place && place?.formatted_address) {
                                  console.log(
                                    'place?.address_components',
                                    place?.address_components
                                  );

                                  place?.address_components?.forEach(
                                    (item: any) => {
                                      const types = item.types;
                                      // if (
                                      //   types.includes('route') ||
                                      //   types.includes('sublocality_level_1')
                                      // ) {
                                      //   formik.setFieldValue(
                                      //     'mailingStreet',
                                      //     item.long_name
                                      //   );
                                      // }
                                      if (
                                        // types.includes(
                                        //   'administrative_area_level_2'
                                        // ) ||
                                        // types.includes(
                                        //   'administrative_area_level_3'
                                        // )
                                        types.includes('locality')
                                      ) {
                                        formik.setFieldValue(
                                          'mailingCity',
                                          item.long_name
                                        );
                                      }
                                      if (
                                        types.includes(
                                          'administrative_area_level_1'
                                        )
                                      ) {
                                        formik.setFieldValue(
                                          'mailingProvince',
                                          item.long_name
                                        );
                                      }
                                      if (types.includes('country')) {
                                        formik.setFieldValue(
                                          'mailingCountry',
                                          item.long_name
                                        );
                                      }
                                      if (types.includes('postal_code')) {
                                        formik.setFieldValue(
                                          'mailingPostalCode',
                                          item.long_name
                                        );
                                      }
                                    }
                                  );
                                  formik.setFieldValue(
                                    'mailingStreet',
                                    formatAddress(place?.address_components)
                                  );
                                  formik.setFieldValue(
                                    'mailingAddress',
                                    place?.formatted_address
                                  );
                                } else {
                                  formik.setFieldValue('mailingAddress', '');
                                }
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                setIsFocused({
                                  ...isFocused,
                                  mailingAddress: false,
                                });
                              }}
                              onFocus={() =>
                                setIsFocused({
                                  ...isFocused,
                                  mailingAddress: true,
                                })
                              }
                              border={
                                isFocused?.mailingAddress ||
                                formik?.values?.mailingAddress
                                  ? '1px solid gray'
                                  : 'none'
                              }
                              _hover={{
                                border:
                                  isFocused?.mailingAddress ||
                                  formik?.values?.mailingAddress
                                    ? '1px solid gray'
                                    : 'none',
                              }}
                              className="googlelocation"
                              ref={mailingAddressRef}
                              style={{
                                fontSize: '13px',
                              }}
                              options={{
                                types: [],
                              }}
                            />
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              bg={'#f0f5f9'}
                              pr={2}
                              cursor={'pointer'}
                              _hover={{ color: '#114684' }}
                            >
                              {formik?.values?.mailingAddress && (
                                <FaTimes
                                  onClick={() => handleClear('mailingAddress')}
                                />
                              )}
                            </Box>
                          </Box>
                        </FormControl>
                      </Box>
                    </Tooltip>
                    {formik.touched.mailingAddress &&
                      formik.errors.mailingAddress && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.mailingAddress}
                        </div>
                      )}
                    {formik.errors.mailingPostalCode &&
                      !formik.errors.mailingAddress &&
                      !formik?.values?.mailingPostalCode && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.mailingPostalCode}
                        </div>
                      )}
                  </Box>
                )}

                <Flex gap={1} flexWrap={'nowrap'} alignItems={'center'}>
                  <Box flexGrow={1}>
                    <Tooltip
                      label="Fiscal Year End Date"
                      placement="right"
                      hasArrow
                    >
                      <Box
                        display="flex"
                        mb={'5px'}
                        mt={
                          isFocused?.fiscalYearEnd ||
                          formik?.values?.fiscalYearEnd
                            ? '13px'
                            : '2px'
                        }
                      >
                        <FormControl position="relative">
                          {(isFocused?.fiscalYearEnd ||
                            formik?.values?.fiscalYearEnd) && (
                            <FormLabel
                              position="absolute"
                              left="12px"
                              top={
                                isFocused?.fiscalYearEnd ||
                                formik?.values?.fiscalYearEnd
                                  ? '-1px'
                                  : '50%'
                              }
                              fontSize={'13px'}
                              transform="translateY(-50%)"
                              transition="0.2s"
                              px="2px"
                              pointerEvents="none"
                              zIndex={3}
                              background={
                                isFocused?.fiscalYearEnd ||
                                formik?.values?.fiscalYearEnd
                                  ? 'white'
                                  : 'none'
                              }
                            >
                              Fiscal year ends on{' '}
                              <span style={{ color: 'red' }}> *</span>
                            </FormLabel>
                          )}
                          <DatePickerInput
                            ref={fiscalYearEndDateRef}
                            value={
                              formik.values.fiscalYearEnd !== null
                                ? formik.values.fiscalYearEnd
                                : undefined
                            }
                            onChange={(date: Date | null) => {
                              formik.setFieldValue('fiscalYearEnd', date);
                              const dateRanges = calculateDateRanges(date);
                              setFiscalYearDateRange(dateRanges);
                              const isLeapYear = checkLeapYear(
                                moment(date).clone().year()
                              );
                              if (
                                moment(date).format('DD/MM') === '28/02' &&
                                isLeapYear
                              ) {
                                modals.open({
                                  // title: 'Info',
                                  modalId: 'FiscalYearStartInfoModal',
                                  children: (
                                    <>
                                      <VStack textAlign={'center'}>
                                        <CiCircleInfo
                                          color="#3182ce"
                                          fontSize={64}
                                        />
                                        <Text as={'h2'} fontSize={18}>
                                          Financial year starts on 1st March.
                                        </Text>
                                      </VStack>
                                    </>
                                  ),
                                });
                              } else if (
                                moment(date).format('DD/MM') === '29/02' &&
                                isLeapYear
                              ) {
                                modals.open({
                                  // title: 'Info',
                                  modalId: 'FiscalYear29FebInfoModal',
                                  children: (
                                    <>
                                      <VStack textAlign={'center'}>
                                        <CiCircleInfo
                                          color="red"
                                          fontSize={64}
                                        />
                                        <Text fontSize={18}>
                                          You can not select 29 febuary as
                                          fiscal year end date.
                                        </Text>
                                      </VStack>
                                    </>
                                  ),
                                });
                                formik.setFieldValue('fiscalYearEnd', null);
                                setFiscalYearDateRange(null);
                                return;
                              }
                            }}
                            locale="en"
                            placeholder={
                              !isFocused?.fiscalYearEnd ||
                              !formik?.values?.fiscalYearEnd
                                ? 'Fiscal year ends on'
                                : ''
                            }
                            className={`react-datepicker-component react-datepicker-input input  ${isFocused?.fiscalYearEnd || formik?.values?.fiscalYearEnd ? 'floatingLabel' : 'fiscalYearEndDate'}`}
                            showOnInputClick={true}
                            onShow={() => {
                              addFocusEventListenerOnInput(
                                fiscalYearEndDateRef
                              );
                              setIsFocused({
                                ...isFocused,
                                fiscalYearEnd: true,
                              });
                            }}
                            onHide={() => {
                              removeFocusEventListenerOnInput(
                                fiscalYearEndDateRef
                              );
                              // formik.handleBlur(e);
                              setIsFocused({
                                ...isFocused,
                                fiscalYearEnd: false,
                              });
                            }}
                            displayFormat="DD/MM"
                            minDate={moment(new Date()).startOf('y')}
                            maxDate={moment(new Date()).endOf('y')}
                          />
                        </FormControl>
                      </Box>
                    </Tooltip>
                    {formik.touched.fiscalYearEnd &&
                      formik.errors.fiscalYearEnd && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.fiscalYearEnd}
                        </div>
                      )}
                  </Box>
                  <Tooltip
                    label={getFiscalYearTooltip()}
                    placement="right"
                    hasArrow
                    background={'grey'}
                    minWidth={
                      !!formik?.values?.fiscalYearEnd ? '350px' : 'auto'
                    }
                  >
                    <Box>
                      <HiMiniInformationCircle
                        size={18}
                        color="#114684"
                        cursor="pointer"
                      />
                    </Box>
                  </Tooltip>
                </Flex>

                {/* {!!formik?.values?.fiscalYearEnd && (
                  <>
                    <Box>
                      <List
                        style={{
                          listStylePosition: 'inside',
                          marginLeft: 0,
                          paddingLeft: 0,
                        }}
                      >
                        <List.Item>
                          Most Recent Interim Financial Statement:{' '}
                          {fiscalYearDateRange?.mostRecentInterim?.start} -{' '}
                          {fiscalYearDateRange?.mostRecentInterim?.end}
                        </List.Item>
                        <List.Item>
                          Most Recent Fiscal Year End:{' '}
                          {fiscalYearDateRange?.mostRecentFiscalYear?.start} -{' '}
                          {fiscalYearDateRange?.mostRecentFiscalYear?.end}
                        </List.Item>
                        <List.Item>
                          1-Year Previous Fiscal Year End:{' '}
                          {fiscalYearDateRange?.oneYearPrevious?.start} -{' '}
                          {fiscalYearDateRange?.oneYearPrevious?.end}
                        </List.Item>
                        <List.Item>
                          2-Year Previous Fiscal Year End:{' '}
                          {fiscalYearDateRange?.twoYearsPrevious?.start} -{' '}
                          {fiscalYearDateRange?.twoYearsPrevious?.end}
                        </List.Item>
                      </List>
                    </Box>
                  </>
                )} */}

                <Flex gap={'7px'} alignItems={'center'} flexWrap={'wrap'}>
                  <Box flex={1} minW={'130px'}>
                    <Tooltip label="Business Phone" placement="right" hasArrow>
                      <Box
                        display="flex"
                        mb={'5px'}
                        mt={
                          isFocused?.businessPhone ||
                          formik?.values?.businessPhone
                            ? '13px'
                            : '2px'
                        }
                      >
                        <FormControl position="relative">
                          {(isFocused?.businessPhone ||
                            formik?.values?.businessPhone) && (
                            <FormLabel
                              position="absolute"
                              left="12px"
                              top={
                                isFocused?.businessPhone ||
                                formik?.values?.businessPhone
                                  ? '-1px'
                                  : '50%'
                              }
                              fontSize={'13px'}
                              transform="translateY(-50%)"
                              transition="0.2s"
                              px="2px"
                              pointerEvents="none"
                              zIndex={3}
                              background={
                                isFocused?.businessPhone ||
                                formik?.values?.businessPhone
                                  ? 'white'
                                  : 'none'
                              }
                            >
                              Business Phone{' '}
                              <span style={{ color: 'red' }}> *</span>
                            </FormLabel>
                          )}
                          <Input
                            fontSize={'13px'}
                            w="100%"
                            type="text"
                            placeholder={
                              !isFocused?.businessPhone ||
                              !formik?.values?.businessPhone
                                ? 'Business Phone'
                                : ''
                            }
                            name="businessPhone"
                            borderRadius="5px"
                            value={formik.values.businessPhone ?? ''}
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // focusBorderColor="transparent"
                            bg={'#f0f5f9'}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              setIsFocused({
                                ...isFocused,
                                businessPhone: false,
                              });
                            }}
                            onFocus={() =>
                              setIsFocused({
                                ...isFocused,
                                businessPhone: true,
                              })
                            }
                            border={
                              isFocused?.businessPhone ||
                              formik?.values?.businessPhone
                                ? '1px solid gray'
                                : 'none'
                            }
                            _hover={{
                              border:
                                isFocused?.businessPhone ||
                                formik?.values?.businessPhone
                                  ? '1px solid gray'
                                  : 'none',
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Tooltip>
                    {formik.touched.businessPhone &&
                      formik.errors.businessPhone && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.businessPhone}
                        </div>
                      )}
                  </Box>
                  <Box flex={1} minW={'130px'}>
                    <Tooltip label="Company Domain" placement="right" hasArrow>
                      <Box
                        display="flex"
                        mb={'5px'}
                        mt={
                          isFocused?.companyDomain ||
                          formik?.values?.companyDomain
                            ? '13px'
                            : '2px'
                        }
                      >
                        <FormControl position="relative">
                          {(isFocused?.companyDomain ||
                            formik?.values?.companyDomain) && (
                            <FormLabel
                              position="absolute"
                              left="12px"
                              top={
                                isFocused?.companyDomain ||
                                formik?.values?.companyDomain
                                  ? '-1px'
                                  : '50%'
                              }
                              fontSize={'13px'}
                              transform="translateY(-50%)"
                              transition="0.2s"
                              px="2px"
                              pointerEvents="none"
                              zIndex={3}
                              background={
                                isFocused?.companyDomain ||
                                formik?.values?.companyDomain
                                  ? 'white'
                                  : 'none'
                              }
                            >
                              Company Domain{' '}
                              <span style={{ color: 'red' }}> *</span>
                            </FormLabel>
                          )}
                          <Input
                            fontSize={'13px'}
                            w="100%"
                            type="text"
                            placeholder={
                              !isFocused?.companyDomain ||
                              !formik?.values?.companyDomain
                                ? 'Company Domain'
                                : ''
                            }
                            name="companyDomain"
                            borderRadius="5px"
                            value={formik.values.companyDomain ?? ''}
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // focusBorderColor="transparent"
                            bg={'#f0f5f9'}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              setIsFocused({
                                ...isFocused,
                                companyDomain: false,
                              });
                            }}
                            onFocus={() =>
                              setIsFocused({
                                ...isFocused,
                                companyDomain: true,
                              })
                            }
                            border={
                              isFocused?.companyDomain ||
                              formik?.values?.companyDomain
                                ? '1px solid gray'
                                : 'none'
                            }
                            _hover={{
                              border:
                                isFocused?.companyDomain ||
                                formik?.values?.companyDomain
                                  ? '1px solid gray'
                                  : 'none',
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Tooltip>
                    {formik.touched.companyDomain &&
                      formik.errors.companyDomain && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.companyDomain}
                        </div>
                      )}
                  </Box>
                </Flex>
                <Flex justifyContent="space-evenly" columnGap={5}>
                  <Box mt="20px" w="100%" mb={10}>
                    <Button
                      isLoading={isProfileUpdate}
                      loadingText="Update"
                      type="submit"
                      bg="#114684"
                      color="white"
                      w="100%"
                      _hover={{
                        bg: '#114684',
                        color: 'white',
                        cursor: areDifferent ? 'pointer' : 'not-allowed',
                      }}
                      isDisabled={!areDifferent}
                    >
                      Update
                    </Button>
                  </Box>
                </Flex>
              </Box>
            </ModalsProvider>
          </form>
        </Container>
      )}
      <AutoPopulateModal
        isOpen={qboModal}
        onClose={() => setQboModal(false)}
        onConfirm={() => {
          setQboModal(false);
          setAutoPopulateModalOpen(true);
        }}
        onDecline={declinedAutoPopulate}
        confirmButtonText="Yes"
        declinedButtonText="No"
        title={`Do you use QuickBooks (QBO) online?`}
        loader={loader}
      />
      <AutoPopulateModal
        isOpen={autoPopulateModalOpen}
        onClose={() => setAutoPopulateModalOpen(false)}
        onConfirm={confirmAutoPopulate}
        onDecline={declinedAutoPopulate}
        confirmButtonText="Approve"
        declinedButtonText="Decline"
        title={`Can we have your permission to access your accounting data to
        generate financial reports for insurance purposes?`}
        subTitle={`Why do we need this`}
        tooltip={`Securely pulling financial information from your accounting system significantly expedites the bonding and insurance underwriting process`}
        loader={loader}
      />
      <ApplicantProfileUpdateAppListModal
        isOpen={isProfileUpdateModal}
        onClose={() => setIsProfileUpdateModal(false)}
        onConfirm={() => brokerApplicantProfileUpdate(formik?.values)}
        applicationList={applicationList}
        onDecline={() => {
          formik.setValues({
            companyName: userApplicantData?.data?.companyName ?? '',
            email: userApplicantData?.data?.email ?? '',
            officeAddress: userApplicantData?.data?.officeAddress ?? '',
            companyLegalName: userApplicantData?.data?.companyLegalName ?? '',
            postalCode: userApplicantData?.data?.postalCode ?? '',
            street: userApplicantData?.data?.street ?? '',
            city: userApplicantData?.data?.city ?? '',
            country: userApplicantData?.data?.country ?? '',
            province: userApplicantData?.data?.province ?? '',
            businessPhone: userApplicantData?.data?.businessPhone ?? '',
            companyDomain: userApplicantData?.data?.companyDomain ?? '',
            mailingAddressSameAsOffice:
              userApplicantData?.data?.mailingAddressSameAsOffice ?? true,
            mailingStreet: userApplicantData?.data?.mailingStreet ?? '',
            mailingCity: userApplicantData?.data?.mailingCity ?? '',
            mailingCountry: userApplicantData?.data?.mailingCountry ?? '',
            mailingProvince: userApplicantData?.data?.mailingProvince ?? '',
            mailingPostalCode: userApplicantData?.data?.mailingPostalCode ?? '',
            companyAddress: userApplicantData?.data?.companyAddress ?? '',
            mailingAddress: userApplicantData?.data?.mailingAddress ?? '',
            fiscalYearEnd: userApplicantData?.data?.fiscalYearEnd ?? '',
          });
          setIsProfileUpdateModal(false);
        }}
      />
    </>
  );
};

export default BrokerApplicantProfile;
