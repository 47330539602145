import { APIStatus } from 'src/types/unions';

//Subscription single Applicaiton Plan
export enum SunscriptionSingleApplicaitonPlan {
  SINGLE_APP = 'Single Application -- out of subscription',
}

//Subscription Monthly Plans
export enum SubscriptionMonthlyPlans {
  STARTER = 'Starter -- Monthly',
  GROWTH = 'Growth -- Monthly',
  SCALE = 'Scale -- Monthly',
  OPTIMIZE = 'Optimize -- Monthly',
}

//Subscription Yearly Plans
export enum SubscriptionYearlyPlans {
  STARTER = 'Starter -- Yearly',
  GROWTH = 'Growth -- Yearly',
  SCALE = 'Scale -- Yearly',
  OPTIMIZE = 'Optimize -- Yearly',
}

// SubscriptionPlan Types
export enum SubscriptionPlanTypes {
  // INTRO = 'Intro',
  STARTER = 'Starter',
  GROWTH = 'Growth',
  SCALE = 'Scale',
  OPTIMIZE = 'Optimize',
}

// SubscriptionPlan Category
export enum PlanCategory {
  Subscription = 'subscription',
  OneTime = 'one-time',
}

export type planCategory = 'subscription' | 'one-time';

//plan cycle
export type planPayType = 'monthly' | 'yearly';

// SubscriptionPlan Cycle
export enum SubscriptionPlanCycles {
  monthly = 'M',
  yearly = 'Y',
}

export interface IStripePlanBase {
  id: string;
  object: string;
  active: boolean;
  attributes: string[];
  created: number;
  default_price: string;
  description: string | null;
  images: any[];
  livemode: boolean;
  marketing_features: string[];
  metadata: stripeMetadata;
  name: string;
  package_dimensions: string | null;
  shippable: boolean | null;
  statement_descriptor: string | null;
  tax_code: string;
  type: string;
  unit_label: string | null;
  updated: number;
  url: string | null;
  prices: stripePrice[];
}

export interface stripeMetadata {
  avCostPerApplication: string;
  expiry: string;
  pricePerMonth: string;
  pricePerYear: string;
  totalApplications: string;
}

export interface stripePrice {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: string | null;
  livemode: boolean;
  lookup_key: string | null;
  metadata: {};
  nickname: string | null;
  product: string;
  recurring: {
    aggregate_usage: string | null;
    interval: string | null;
    interval_count: number;
    meter: string | null;
    trial_period_days: string | null;
    usage_type: string | null;
  } | null;
  tax_behavior: string | null;
  tiers_mode: string | null;
  transform_quantity: string | null;
  type: string | null;
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface IFaqs {
  faqId: string;
  question: string;
  answer: string;
}

export interface PurchasedPlanInfo {
  purchasedPlanId: number;
  brokerageId: number;
  subscriptionPlanId: string;
  price: string;
  stripeId: string;
  stripeSession: Record<string, any>;
  totalApplications: number;
  usedApplications: number;
  remainingApplications: number;
  purchasedDate: string;
  planExpiryDate: string;
  planStatus: string;
  paymentStatus: string;
  checkoutSessionStatus: string;
  createdAt: string;
  updatedAt: string;
  name: string;
}
export interface IInitialState {
  error: any;
  uploadReportsData?: IFaqs | null;
  faqsData?: IFaqs[];
  status: APIStatus | null;
  type?:
    | 'GET_FAQS'
    | 'GET_PLANS'
    | 'PURCHASE_PLAN'
    | 'GET_PURCHASED_PLAN_DETAILS'
    | null;
  plans: IStripePlanBase[];
  stripeURL: string | null;
  purchasedPlanDetails: PurchasedPlanInfo | null;
}

const initialState: IInitialState = {
  error: null,
  status: null,
  uploadReportsData: null,
  faqsData: [],
  plans: [],
  stripeURL: null,
  purchasedPlanDetails: null,
};

export default initialState;
