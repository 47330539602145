import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import { Modal } from '@mantine/core';
import { CiCircleInfo } from 'react-icons/ci';

interface ValidateModelProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  submitLoader: boolean;
}

const ValidateOCRReportModel = ({
  isOpen,
  onClose,
  onConfirm,
  submitLoader,
}: ValidateModelProps) => {
  return (
    <Modal
      opened={isOpen}
      onClose={() => onClose()}
      //   title="OCR Validate"
      //   overlayProps={{
      //     opacity: 0.1,
      //     blur: 3,
      //   }}
      size={'md'}
      zIndex={300}
      withCloseButton={false}
    >
      <Box margin={'10px 10px 0 10px'}>
        <VStack textAlign={'center'}>
          <CiCircleInfo color="#3182ce" fontSize={64} />
          <Text fontSize={16} my={3}>
            Once it is validated no more editing possible, if changes are
            required, need to upload new document.
          </Text>
        </VStack>
        <Flex justifyContent={'center'} gap={2} alignItems={'center'} mt={3}>
          <Button type="button" onClick={onClose} isDisabled={submitLoader}>
            Cancel
          </Button>
          <Button
            type="button"
            onClick={onConfirm}
            colorScheme="blue"
            loadingText="Submit"
            isLoading={submitLoader}
            isDisabled={submitLoader}
          >
            Submit
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ValidateOCRReportModel;
