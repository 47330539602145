import moment from 'moment';
import { FinancialYearDateRange } from 'src/Redux/Applicant/ApplicantProfile/ApplicantProfile';
import { checkLeapYear } from 'src/utils/helpers';

export const calculateDateRanges = (endDate: Date | null) => {
  // Parse endDate using Moment.js

  const end = moment(endDate, 'DD MMMM YYYY');

  const isTwentyEightFeb = moment(endDate).format('DD/MM') === '28/02';
  const daysToAddMostRecentInterim =
    isTwentyEightFeb && checkLeapYear(end.clone().year()) ? 2 : 1;
  const daysToAddMostRecentFiscalYear =
    isTwentyEightFeb && checkLeapYear(end.clone().subtract(1, 'year').year())
      ? 2
      : 1;
  const daysToAddoneYearPrevious =
    isTwentyEightFeb && checkLeapYear(end.clone().subtract(2, 'year').year())
      ? 2
      : 1;
  const daysToAddtwoYearsPrevious =
    isTwentyEightFeb && checkLeapYear(end.clone().subtract(3, 'year').year())
      ? 2
      : 1;

  // Calculate each required date range
  const ranges: FinancialYearDateRange = {
    mostRecentInterim: {
      start: end
        .clone()
        .add(daysToAddMostRecentInterim, 'day')
        .format('DD MMM YYYY'),
      end: end.clone().add(1, 'year').format('DD MMM YYYY'),
    },
    mostRecentFiscalYear: {
      start: end
        .clone()
        .subtract(1, 'year')
        .add(daysToAddMostRecentFiscalYear, 'day')
        .format('DD MMM YYYY'),
      end: end.format('DD MMM YYYY'),
    },
    oneYearPrevious: {
      start: end
        .clone()
        .subtract(2, 'years')
        .add(daysToAddoneYearPrevious, 'day')
        .format('DD MMM YYYY'),
      end: end.clone().subtract(1, 'year').format('DD MMM YYYY'),
    },
    twoYearsPrevious: {
      start: end
        .clone()
        .subtract(3, 'years')
        .add(daysToAddtwoYearsPrevious, 'day')
        .format('DD MMM YYYY'),
      end: end.clone().subtract(2, 'years').format('DD MMM YYYY'),
    },
  };

  return ranges;
};
