import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASEURL } from 'src/App';
import { ApplicationFilter } from 'src/types/enums';
import callAPI from 'src/utils/callAPI';
import { isRejectedActionWithPayload } from '../Broker/Documents/slice';
import { tokenExipry } from '../tokenExpiry';
import initialState, { PDFPreferenceLegalFormData } from './state';

export const getBrokerDashboardAppList = createAsyncThunk(
  'appliactionsStore/getBrokerDashboardAppList',
  async () => {
    try {
      try {
        const response = await callAPI(
          `${BASEURL}/application/broker-dashboard-app-list`,
          'GET'
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getApplications = createAsyncThunk(
  'appliactionsStore/getApplications',
  async (
    { applicationFilter }: { applicationFilter: ApplicationFilter },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(
        `application/listApplications?applicationFilter=${applicationFilter}`,
        'GET'
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getApplicationById = createAsyncThunk(
  'appliactionsStore/getApplicationById',
  async ({ applicationId }: { applicationId: number }, { rejectWithValue }) => {
    try {
      const res = await callAPI(`/application/${applicationId}`, 'GET');

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getGlobalHistory = createAsyncThunk(
  'appliactionsStore/getGlobalHistory',
  async ({ applicationId }: { applicationId: number }, { rejectWithValue }) => {
    try {
      const res = await callAPI(
        `history/list?applicationId=${applicationId}&modelName=Application`,
        'GET'
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getApplicationUserkey = createAsyncThunk(
  'appliactionsStore/getApplicationUserkey',
  async (payload: { applicationId: number }, { rejectWithValue }) => {
    try {
      const res = await callAPI(`user/applicant/uskey`, 'POST', payload);

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const refreshDuns = createAsyncThunk(
  'appliactionsStore/refreshDuns',
  async (
    payload: { applicationId: number; applicantId: number },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(`application/refresh-duns`, 'POST', payload);

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getPDFPreferanceData = createAsyncThunk(
  'appliactionsStore/getPDFPreferanceData',
  async (
    payload: { applicationId: number; formName: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(`pdfSummary/filing/statuses`, 'POST', payload);

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);
export const savePDFPreferanceData = createAsyncThunk(
  'appliactionsStore/savePDFPreferanceData',
  async (
    payload: { applicationId: number; legal: PDFPreferenceLegalFormData[] },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(
        `pdfSummary/update/filing/statuses`,
        'PUT',
        payload
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const appliactionsStore = createSlice({
  name: 'appliactionsStore',
  initialState,
  reducers: {
    removeType(state, action) {
      state.type = null;
    },
  },
  extraReducers: (builder) => {
    // get application by status
    builder
      .addCase(getApplications.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_APPLICATIONS_BY_STATUS';
      })
      .addCase(getApplications.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_APPLICATIONS_BY_STATUS';
        state.applications = action.payload.data;
      })
      .addCase(getApplications.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_APPLICATIONS_BY_STATUS';
        state.error = isRejectedActionWithPayload(action);
      })
      // GET application by id
      .addCase(getApplicationById.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_APPLICATION_BY_ID';
      })
      .addCase(getApplicationById.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_APPLICATION_BY_ID';
        state.currentApplication = action.payload.data;
      })
      .addCase(getApplicationById.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_APPLICATION_BY_ID';
        state.error = isRejectedActionWithPayload(action);
      })

      // GET application user key
      .addCase(getGlobalHistory.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_APPLICATION_HISTORY';
      })
      .addCase(getGlobalHistory.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_APPLICATION_HISTORY';
        state.applicationHistory = action.payload.data;
      })
      .addCase(getGlobalHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_APPLICATION_HISTORY';
        state.error = isRejectedActionWithPayload(action);
      })

      // GET application global history
      .addCase(getApplicationUserkey.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_APPLICATION_USER_KEY';
      })
      .addCase(getApplicationUserkey.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_APPLICATION_USER_KEY';
        state.userKey = action.payload.data;
      })
      .addCase(getApplicationUserkey.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_APPLICATION_USER_KEY';
        state.error = isRejectedActionWithPayload(action);
      })

      //application refresh-duns
      .addCase(refreshDuns.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'APPLICATION_REFRESH_DUNS';
      })
      .addCase(refreshDuns.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'APPLICATION_REFRESH_DUNS';
        state.dunsData = action.payload.data;
      })
      .addCase(refreshDuns.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'APPLICATION_REFRESH_DUNS';
        state.error = isRejectedActionWithPayload(action);
      })
      //get pdf preference data
      .addCase(getPDFPreferanceData.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_PDF_PREFERENCES';
      })
      .addCase(getPDFPreferanceData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_PDF_PREFERENCES';
        state.pdfPreferences = action.payload.data;
      })
      .addCase(getPDFPreferanceData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_PDF_PREFERENCES';
        state.error = isRejectedActionWithPayload(action);
      })
      //save pdf preference data
      .addCase(savePDFPreferanceData.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'SAVE_PDF_PREFERENCES';
      })
      .addCase(savePDFPreferanceData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'SAVE_PDF_PREFERENCES';
        state.data = action.payload;
      })
      .addCase(savePDFPreferanceData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'SAVE_PDF_PREFERENCES';
        state.error = isRejectedActionWithPayload(action);
      })

      //Broker dashboard get all app list
      .addCase(getBrokerDashboardAppList.pending, (state) => {
        state.error = null;
        state.type = 'GET_BROKER_DASHBOARD_APP_LIST';
        state.status = 'loading';
      })
      .addCase(getBrokerDashboardAppList.fulfilled, (state, action) => {
        state.brokerDashboardAppList = action?.payload?.data;
        state.type = 'GET_BROKER_DASHBOARD_APP_LIST';
        state.status = 'succeed';
      })
      .addCase(getBrokerDashboardAppList.rejected, (state, action) => {
        state.error = action.payload as null;
        state.type = 'GET_BROKER_DASHBOARD_APP_LIST';
        state.status = 'failed';
      });
  },
});

const appliactionsSlice = appliactionsStore.reducer;
export const { removeType } = appliactionsStore.actions;

export default appliactionsSlice;
