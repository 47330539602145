import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { fetchApplicantUserData } from '../../../Redux/Applicant/ApplicantProfile/ApplicantProfile';
import { ApplicantBrokerage } from '../../../Redux/Broker/Dashboard/BrokerageDashboard';
import {
  getWIPDataByApplicantId,
  updateRevenue,
} from '../../../Redux/Broker/Reports/WorkInProgress/slice';
import { IApplicantData } from '../../../Redux/Broker/Reports/WorkInProgress/state';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../Redux/Store';
import useAppToast from '../../../hooks/useAppToast';
import MantineTable from '../components/MantineTable';

const ReportWorkingProgress = () => {
  const [selectedBrokerageId, setSelectedBrokerageId] = useState<string>('');
  const [selectedApplicantData, setSelectedApplicantData] =
    useState<IApplicantData>();
  const [isloadingApp, setIsloadingApp] = useState<boolean>(false);
  const [progressInfo, setProgressInfo] = useState<any>(null);
  const [progressInfoLoading, setProgressInfoLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [reportBasisBy, setReportBasisBy] = useState<string>('');
  const [otherValue, setOtherValue] = useState<string>('');
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [showAllProject, setShowAllProject] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const toast = useAppToast();
  // store state
  const progressReport = useAppSelector((state) => state.brokerReportProgress);
  const applicantProfile = useAppSelector((state) => state.applicantProfile);
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );

  useEffect(() => {
    if (brokerageDashboard.applicantsByBrokrageData) {
      const selectedApplicantData =
        brokerageDashboard.applicantsByBrokrageData.find(
          (elm: any) => elm.applicantId == selectedBrokerageId
        );
      setSelectedApplicantData(selectedApplicantData);
    }
  }, [selectedBrokerageId]);

  useEffect(() => {
    if (selectedBrokerageId) {
      dispatch(
        getWIPDataByApplicantId({
          applicantId: Number(selectedBrokerageId),
          showAll: showAllProject,
        })
      );
    }
  }, [showAllProject]);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData } = brokerageDashboard;
    if (status === 'succeed') {
      if (
        type === 'GET_APPLICANT_BY_BROKERAGE' &&
        !!applicantsByBrokrageData?.length
      ) {
        setApplicantOptions(applicantsByBrokrageData);
      }
    }
  }, [brokerageDashboard.status, selectedBrokerageId]);

  useEffect(() => {
    if (!brokerageDashboard.applicantsByBrokrageData && userType === 'broker') {
      dispatch(ApplicantBrokerage());
    } else if (
      brokerageDashboard.applicantsByBrokrageData &&
      userType === 'broker'
    ) {
      setApplicantOptions(brokerageDashboard.applicantsByBrokrageData);
    } else if (userType === 'applicant') {
      dispatch(fetchApplicantUserData());
    }
    return () => setProgressInfoLoading(false);
  }, []);

  useEffect(() => {
    setSelectedApplicantData(applicantProfile.user.data);
  }, [applicantProfile.user]);

  useEffect(() => {
    switch (progressReport.status) {
      case 'succeed': {
        if (progressReport.type === 'GET_WIP_DATA_BY_APPLICATIONID_API') {
          setProgressInfo(progressReport.allWIPdata.progressInfo);
          setProgressInfoLoading(false);
          if (!progressReport.allWIPdata.revenueBasis && selectedBrokerageId) {
            setShowPopup(true);
          }
        }
        if (progressReport.type === 'UPDATE_REVNUE_API') {
          dispatch(
            getWIPDataByApplicantId({
              applicantId: Number(selectedBrokerageId),
              showAll: showAllProject,
            })
          );
        }

        if (progressReport.type === 'REMOVE_WIP_API') {
          toast({
            title: progressReport.message,
            status: 'success',
          });
        }
        return;
      }
      case 'loading': {
        if (progressReport.type === 'GET_WIP_DATA_BY_APPLICATIONID_API') {
          setProgressInfoLoading(false);
        }
        return;
      }
      case 'failed': {
        selectedBrokerageId !== '' &&
          progressReport.error &&
          toast({
            title: progressReport.error,
            status: 'error',
          });
        setProgressInfo(null);
        if (progressReport.type === 'GET_WIP_DATA_BY_APPLICATIONID_API') {
          setProgressInfoLoading(false);
        }
        return;
      }
    }
  }, [progressReport.status]);

  const userType = localStorage.getItem('userType');

  // data from store
  const userData = useAppSelector(
    (state: RootState) => state.brokerageDashboard.user
  );

  let ApplicantbrokerageData = userData && userData.data;
  const handleCheckboxChange = (value: string) => {
    setReportBasisBy(value.toString());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherValue(event.target.value);
  };

  const handleBrokerageSelect = async (
    event: React.ChangeEvent<HTMLSelectElement> | string
  ) => {
    const selectedId = typeof event === 'string' ? event : event.target.value;
    setSelectedBrokerageId(selectedId);

    if (selectedId) {
      setIsloadingApp(true);

      dispatch(
        getWIPDataByApplicantId({
          applicantId: Number(selectedId),
          showAll: showAllProject,
        })
      );
      setIsloadingApp(false);
    }
  };

  const handlePopupSubmit = async () => {
    if (progressReport.allWIPdata.id && reportBasisBy) {
      setShowPopup(false);
      setReportBasisBy(reportBasisBy.toString());
      dispatch(
        updateRevenue({
          documentId: progressReport.allWIPdata.id,
          revenueBasis:
            reportBasisBy.toString() === 'other'
              ? otherValue
              : reportBasisBy.toString(),
        })
      );
      setReportBasisBy('');
      setOtherValue('');
    }
  };

  useEffect(() => {
    if (userType && userType === 'applicant') {
      const userID = localStorage.getItem('userID') || '';
      handleBrokerageSelect(userID);
    }
  }, []);

  const showTable =
    selectedBrokerageId !== '' && !progressInfoLoading && !showPopup;

  return (
    <Box>
      <Flex alignItems={'left'} flexDirection={'column'} columnGap={5} py={5}>
        <Flex
          alignItems={'center'}
          mb={5}
          gap={1}
          justifyContent={'space-between'}
        >
          {userType === 'broker' && (
            <Box
              maxW="max-content"
              display={'flex'}
              alignItems={'center'}
              gap={2}
            >
              <Select
                w="100%"
                defaultValue={selectedBrokerageId}
                onChange={handleBrokerageSelect}
                value={selectedBrokerageId}
                name="userData.brokerageId"
                borderRadius="5px"
                bg={'#114684'}
                color={'white'}
                textAlign={'center'}
              >
                <option
                  value={''}
                  style={{ color: 'black' }}
                  label="Select Applicant"
                />
                {applicantOptions?.map((user?: any, index?: number) => (
                  <option
                    style={{ color: 'black' }}
                    key={index}
                    value={user?.applicantId ?? ''}
                    label={user?.companyName ?? ''}
                  />
                ))}
              </Select>
              {!progressInfoLoading && !showTable && !showPopup && (
                <Text fontSize={'medium'} whiteSpace={'nowrap'}>
                  Please Select Applicant
                </Text>
              )}
              {selectedBrokerageId && (
                <Box ml={2}>
                  <Checkbox
                    isChecked={showAllProject}
                    onChange={(e) => setShowAllProject(e.target.checked)}
                    w={'max-content'}
                    isDisabled={
                      progressReport.type ===
                        'GET_WIP_DATA_BY_APPLICATIONID_API' &&
                      progressReport.status === 'loading'
                    }
                  >
                    Show All
                  </Checkbox>
                </Box>
              )}
            </Box>
          )}

          {progressReport.allWIPdata.revenueBasis && (
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={2}
              textAlign={'end'}
              ml={'auto'}
            >
              <Text fontWeight={'bold'}>
                On what basis is revenue recognized?
              </Text>
              <Text>{progressReport.allWIPdata.revenueBasis}</Text>
            </Box>
          )}
        </Flex>

        {progressInfoLoading && (
          <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
            <Spinner size="xl" />
          </Flex>
        )}

        {((showTable && selectedApplicantData) || userType === 'applicant') && (
          <MantineTable
            progressInfo={progressInfo}
            selectedBrokerageId={selectedBrokerageId}
            selectedId={2}
            selectedApplicantData={selectedApplicantData}
            showAllProject={showAllProject}
          />
        )}
      </Flex>
      {/* {showPopup && (

      )} */}
      <Modal
        isOpen={showPopup}
        onClose={() => {
          setShowPopup(false);
          setSelectedBrokerageId('');
          setReportBasisBy('');
          setOtherValue('');
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>On what basis is revenue recognized?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="center" mb={3} flexDirection="column">
              <RadioGroup
                display="flex"
                flexDirection="column"
                justifyContent="center"
                onChange={handleCheckboxChange}
              >
                <Radio value="Completed Contract">Completed Contract</Radio>
                <Radio value="Percentage of Completion">
                  Percentage of Completion
                </Radio>
                <Radio value="Cash">Cash</Radio>
                <Radio value="other">Other (please specify)</Radio>
              </RadioGroup>
              {reportBasisBy === 'other' && (
                <Input
                  mt="2"
                  placeholder="Specify other basis"
                  value={otherValue}
                  onChange={handleInputChange}
                />
              )}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              isDisabled={
                (reportBasisBy.toString() === 'other' &&
                  otherValue.trim() === '') ||
                !reportBasisBy.toString()
              }
              onClick={handlePopupSubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ReportWorkingProgress;
