import { Badge, Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { Modal } from '@mantine/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { HiArrowLongRight } from 'react-icons/hi2';
import { useDispatch } from 'react-redux';
import AvatarComponent from 'src/Component/Avatar';
import { getGlobalHistory } from 'src/Redux/Applications/slice';
import { ApplicationHistoryData } from 'src/Redux/Applications/state';
import { useAppSelector } from 'src/Redux/Store';
import { generateColorStyles } from 'src/utils/helpers';

const ApplicationStatus = ({ Status }: { Status: string }) => {
  const statusColors: Record<string, string> = {
    // Drafts: 'teal',
    // 'Awaiting Input': 'orange',
    // 'Application Submitted. Awaiting Underwriting': 'purple',
    // 'Approved by Underwriter': 'green',
    // 'Declined by Underwriter': 'red',
    // 'Application Created': 'blue',

    //New Status
    'Application Created': 'blue',
    Draft: 'purple',
    Completed: 'green',
  };

  const colorStyles = generateColorStyles(statusColors[Status]);
  return (
    <Badge variant="subtle" background={colorStyles?.bg} p={'2px 5px'}>
      {Status}
    </Badge>
  );
};

const Cards = ({
  cardDetails,
  index,
  nextCard,
  applicationDetails,
}: {
  cardDetails: Record<string, any>;
  index: number;
  nextCard: Record<string, any> | null;
  applicationDetails: Record<string, any> | null;
}) => {
  const appliactionsSlice = useAppSelector((state) => state.appliactionsSlice);
  const isBroker = cardDetails?.broker;
  const AvatarName = isBroker
    ? `${cardDetails?.broker?.firstName} ${cardDetails?.broker?.lastName}`
    : cardDetails?.applicant?.companyName;
  const isStatusMatched =
    nextCard?.details?.brokerDashboard ===
    cardDetails?.details?.brokerDashboard;

  return (
    <Box key={index.toString()}>
      <Flex gap={5} alignItems={'flex-start'} flexWrap={'nowrap'}>
        <AvatarComponent name={AvatarName} />
        <Box>
          <Flex
            gap={{ base: 1, md: 2 }}
            mb={1}
            direction={{ base: 'column', md: 'row' }}
          >
            <Text>{cardDetails?.details?.action}</Text>
            <Text color={'gray'} fontSize={'14px'}>
              {moment(cardDetails?.updatedAt).format('MMMM DD,YYYY hh:mm A')}
            </Text>
          </Flex>
          {isStatusMatched ? (
            <Flex mb={1}>
              <ApplicationStatus
                Status={cardDetails?.details?.brokerDashboard}
              />
            </Flex>
          ) : (
            <Flex flexWrap={'nowrap'} alignItems={'center'} gap={5} mb={1}>
              <ApplicationStatus
                Status={
                  index === appliactionsSlice?.applicationHistory?.length - 1
                    ? 'Application Created'
                    : nextCard?.details?.brokerDashboard
                }
              />
              <Box>
                <HiArrowLongRight size={20} />
              </Box>
              <ApplicationStatus
                Status={cardDetails?.details?.brokerDashboard}
              />
            </Flex>
          )}
          {/*<Flex flexWrap={'nowrap'} alignItems={'center'} gap={5}>
            <Flex gap={1}>
              <Text textAlign="start" color="black" fontWeight={'600'}>
                From {isBroker ? 'Broker' : 'Aplicant'}:
              </Text>
              <Text>{AvatarName}</Text>
            </Flex>
            <Box>
              <HiArrowLongRight size={20} />
            </Box>
            <Flex gap={1}>
              <Text textAlign="start" color="black" fontWeight={'600'}>
                To {isBroker ? 'Applicant' : 'Broker'}:
              </Text>
              <Text>
                {isBroker
                  ? applicationDetails?.applicantName
                  : applicationDetails?.brokerName}
              </Text>
            </Flex>
          </Flex>*/}
        </Box>
      </Flex>
    </Box>
  );
};

const GlobalHistoryModal = ({
  isOpen,
  onClose,
  applicationDetails,
}: {
  isOpen: boolean;
  onClose: () => void;
  applicationDetails: Record<string, any> | null;
}) => {
  const dispatch = useDispatch();
  const appliactionsSlice = useAppSelector((state) => state.appliactionsSlice);
  const [appHistoryData, setAppHistoryData] = useState<
    ApplicationHistoryData[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen && applicationDetails) {
      dispatch(
        getGlobalHistory({
          applicationId: applicationDetails?.applicationId,
        }) as any
      );
    }
  }, [isOpen, applicationDetails]);

  useEffect(() => {
    const { status, type, applicationHistory } = appliactionsSlice;
    switch (status) {
      case 'loading':
        if (type === 'GET_APPLICATION_HISTORY') {
          setIsLoading(true);
        }
        break;
      case 'succeed':
        if (type === 'GET_APPLICATION_HISTORY') {
          setAppHistoryData(applicationHistory);
          setIsLoading(false);
        }
        break;
      case 'failed':
        if (type === 'GET_APPLICATION_HISTORY') {
          setIsLoading(false);
        }
        break;

      default:
        break;
    }
  }, [appliactionsSlice.status]);

  return (
    <Modal
      opened={isOpen}
      onClose={() => onClose()}
      title="History of Application"
      centered
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      // size={isLoading || appHistoryData.length === 0 ? 'lg' : 'auto'}
      size={'725px'}
      className="global_history"
    >
      <Box margin={'10px 10px 0 10px'} maxW={'725px'} maxH={'600px'}>
        <Flex direction={'column'} gap={2} mb={'25px'}>
          <Flex gap={1}>
            <Text textAlign="start" color="black" fontWeight={'600'}>
              Application Code:
            </Text>
            <Text>{applicationDetails?.applicationCode}</Text>
          </Flex>
          <Flex gap={1}>
            <Text textAlign="start" color="black" fontWeight={'600'}>
              Applicant Name:
            </Text>
            <Text>{applicationDetails?.applicantName}</Text>
          </Flex>
          <Flex gap={1}>
            <Text textAlign="start" color="black" fontWeight={'600'}>
              Broker Name:
            </Text>
            <Text>{applicationDetails?.brokerName}</Text>
          </Flex>
        </Flex>
        {isLoading ? (
          <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
            <Spinner size={'md'} />
          </Flex>
        ) : appHistoryData?.length > 0 ? (
          <Flex gap={5} direction={'column'} paddingBottom={'10px'}>
            {appHistoryData?.map((item: any, index: number) => (
              <Cards
                cardDetails={item}
                index={index}
                nextCard={
                  index === appHistoryData?.length - 1
                    ? null
                    : appHistoryData[index + 1]
                }
                applicationDetails={applicationDetails}
              />
            ))}
          </Flex>
        ) : (
          <Box marginBottom={'10px'}>No Data Found</Box>
        )}
      </Box>
    </Modal>
  );
};

export default GlobalHistoryModal;
