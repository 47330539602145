import { APIStatus } from 'src/types/unions';

export enum BSReportCalculation {
  TotalCurrentAssets = 'Total Current Assets',
  TotalFixedAssets = 'Total Fixed Assets',
  TotalAssets = 'TOTAL ASSETS',
  TotalAccountsPayable = 'Total Accounts Payable',
  TotalCurrentLiabilities = 'Total Current Liabilities', //TotalCurrentLiabilities calculation of total start from TotalAccountsPayable
  TotalLongTermLiabilities = 'Total Long-Term Liabilities',
  TotalLiabilities = 'Total Liabilities',
  TotalEquity = 'Total Equity',
  TotalLiabilitiesAndEquity = 'TOTAL LIABILITIES AND EQUITY',
}
export interface IInitialState {
  error: any;
  status: APIStatus | null;
  type?:
    | 'GET_OCR_REPORT_LIST'
    | 'GET_OCR_REPORT_BY_ID'
    | 'UPDATE_OCR_REPORT'
    | 'ADD_OCR_REPORT'
    | 'DELETE_OCR_REPORT'
    | 'DELETE_OCR'
    | 'VALIDATE_OCR_REPORT'
    | null;
  ocrReportList: Record<string, any>[] | null;
  data: Record<string, any> | null;
  msg: string | null;
}

const initialState: IInitialState = {
  error: null,
  status: null,
  ocrReportList: null,
  data: null,
  msg: null,
};

export default initialState;
