import { useLocation, useNavigate } from 'react-router-dom';

export const useApplicationNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { status } = location.state || {};
  const ApplicationStatus = status ?? 'Awaiting Input';

  const handelView = ({
    applicationId,
    status,
  }: {
    applicationId: number;
    status?: string;
  }) => {
    navigate(
      `/application/${applicationId}?status=${status ?? ApplicationStatus}&mode=view`,
      {
        state: {
          showBox: true,
          ownerData: true,
          applicationStatus: status ?? ApplicationStatus,
        },
      }
    );
  };
  const handelEdit = ({
    applicationId,
    status,
  }: {
    applicationId: number;
    status?: string;
  }) => {
    navigate(
      `/application/${applicationId}?status=${status ?? ApplicationStatus}&mode=edit`,
      {
        state: {
          applicationStatus: status ?? ApplicationStatus,
        },
      }
    );
  };

  const handelHistory = ({
    applicationId,
    applicantId,
    status,
  }: {
    applicationId: number;
    applicantId: number;
    status?: string;
  }) => {
    navigate(
      `/application/${applicationId}?status=${status ?? ApplicationStatus}&mode=history`,
      {
        state: {
          showHistory: true,
          showBox: true,
          applicationStatus: status ?? ApplicationStatus,
          applicantId: applicantId,
        },
      }
    );
  };

  return { handelView, handelEdit, handelHistory };
};
