import { ArrowRightIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Divider,
  HStack,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { HiMiniDocumentText } from 'react-icons/hi2';
import { RiShareBoxLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import {
  AppOutlineButton,
  AppSolidButton,
} from 'src/Component/Common/Micro/AppButtons';
import { brokragePlans, invoicesData } from 'src/data/plans';
import { getPurchasedPlan } from 'src/Redux/Plans/slice';
import {
  PurchasedPlanInfo,
  SubscriptionPlanCycles,
} from 'src/Redux/Plans/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import {
  getCurrencyDigit,
  getPlanName,
  getPlanTypeFromPlanName,
  preventAlphabetsTyping,
} from 'src/utils/helpers';
import PlanBillingSkeleton from './components/PlanBillingSkeleton';
import PurchaseHistorySkeleton from './components/PurchaseHistorySkeleton';

const BiilingPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [numApplications, setNumApplications] = useState<number | undefined>(
    undefined
  );
  const [plantype, setPlantype] = useState<'yearly' | 'monthly'>('yearly');
  const plansStore = useAppSelector((state) => state.plans);
  const [purchasedPlanInfo, setPurchasedPlanInfo] =
    useState<PurchasedPlanInfo | null>(null);
  const [loader, setLoader] = useState<{
    planBilling: boolean;
    purchaseHistory: boolean;
  }>({
    planBilling: false,
    purchaseHistory: false,
  });

  const handleOpen = () => {
    // setNumApplications(0);
    onOpen();
  };
  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setNumApplications(value);
  };

  const currentPlan = brokragePlans[plantype].find(
    (plan) => plan.planName === 'Scale'
  );

  const applicationUsed = Math.floor(
    Math.random() * currentPlan?.totalApplications!
  );

  const averagePrice =
    currentPlan?.payby === SubscriptionPlanCycles.yearly ? 65 : 82;
  const totalPrice = numApplications ? numApplications * averagePrice : 0;

  useEffect(() => {
    dispatch(getPurchasedPlan());
  }, []);

  useEffect(() => {
    const { status, type, purchasedPlanDetails } = plansStore;

    switch (status) {
      case 'loading': {
        setLoader((prev) => ({ ...prev, planBilling: true }));
        break;
      }
      case 'succeed': {
        if (type === 'GET_PURCHASED_PLAN_DETAILS' && purchasedPlanDetails) {
          setPurchasedPlanInfo(purchasedPlanDetails);
          setLoader((prev) => ({ ...prev, planBilling: false }));
        }
        break;
      }

      case 'failed': {
        setLoader((prev) => ({ ...prev, planBilling: false }));
        break;
      }
    }
  }, [plansStore.status]);

  console.log('purchasedPlanInfo', purchasedPlanInfo);

  return (
    <>
      <HStack
        p={[2, 4, 6]}
        alignItems={'flex-start'}
        spacing={4}
        flexDirection={['column', 'column', 'column', 'column', 'row']}
        wrap={['wrap', 'wrap', 'wrap', 'nowrap']}
        w="100%"
        mx={'auto'}
        display={'flex'}
        justifyContent={'center'}
      >
        {loader?.planBilling ? (
          <PlanBillingSkeleton />
        ) : (
          <VStack
            shadow={'sm'}
            borderWidth="1px"
            borderColor={'gray.400'}
            borderRadius={'xl'}
            p={[4, 6, 6]}
            gap={4}
            flex={1}
            w={'100%'}
            maxW={['100%', '100%', '100%', '100%', '60rem']}
          >
            <VStack alignItems={'flex-start'} w={'100%'} gap={0}>
              <HStack
                justifyContent={'space-between'}
                w={'100%'}
                flexWrap="wrap"
              >
                <Text as={'h1'} fontWeight={600} fontSize={[16, 18, 20]}>
                  Plans & Billing
                </Text>
                <HStack>
                  <Text>M</Text>
                  <Switch
                    onChange={() =>
                      setPlantype((val) =>
                        val === 'yearly' ? 'monthly' : 'yearly'
                      )
                    }
                    isChecked={plantype === 'yearly'}
                  />
                  <Text>Y</Text>
                </HStack>
              </HStack>
              <HStack
                justifyContent={'space-between'}
                w={'100%'}
                flexWrap="wrap"
              >
                <Text fontSize={12}>Manage your plan and payment</Text>
                <HStack spacing={2}>
                  <AppSolidButton rightIcon={<RiShareBoxLine />}>
                    Manage payment
                  </AppSolidButton>
                </HStack>
              </HStack>
            </VStack>
            <Divider borderColor="gray.400" />
            <VStack alignItems="flex-start" w="100%" spacing={4}>
              <HStack justifyContent="space-between" w="100%" flexWrap="wrap">
                <Text as="h1" fontWeight={600} fontSize={[14, 16]}>
                  Current plan
                </Text>
                <HStack>
                  {currentPlan?.payby === SubscriptionPlanCycles.monthly && (
                    <AppOutlineButton
                      onClick={() => navigate('/brokerage/plans')}
                    >
                      Buy new plan
                    </AppOutlineButton>
                  )}
                  <AppOutlineButton onClick={handleOpen}>
                    Top Up plan
                  </AppOutlineButton>
                </HStack>
              </HStack>
              <VStack
                w="100%"
                // direction={['column', 'column', 'row']}
                alignItems="stretch"
                spacing={4}
              >
                <VStack
                  flex={1}
                  p={4}
                  border={1}
                  borderColor="gray.300"
                  borderStyle="solid"
                  rounded="lg"
                  alignItems="flex-start"
                  w="100%"
                >
                  <HStack
                    justifyContent="space-between"
                    w="100%"
                    alignItems={'flex-start'}
                  >
                    <VStack alignItems={'flex-start'}>
                      {purchasedPlanInfo?.name !==
                        'Single Application -- out of subscription' && (
                        <Text fontSize={12}>
                          {getPlanTypeFromPlanName(purchasedPlanInfo?.name!)}{' '}
                          plan
                        </Text>
                      )}
                      <VStack alignItems={'flex-start'} gap={0}>
                        <Text fontWeight={600} fontSize={20}>
                          {getPlanName(purchasedPlanInfo?.name!)}
                        </Text>
                        <HStack gap={0} alignItems="flex-end">
                          <Text fontWeight={600} fontSize={18}>
                            {getCurrencyDigit({
                              number: Math.trunc(
                                purchasedPlanInfo?.stripeSession?.amount_total /
                                  100
                              ),
                              options: {
                                currency:
                                  purchasedPlanInfo?.stripeSession?.currency ??
                                  'usd',
                              },
                            })}
                          </Text>
                          {getPlanTypeFromPlanName(purchasedPlanInfo?.name!) !==
                            'Yearly' &&
                            purchasedPlanInfo?.name !==
                              'Single Application -- out of subscription' && (
                              <>
                                <Text fontWeight={600} fontSize={18}>
                                  /
                                </Text>
                                <Text>month</Text>
                              </>
                            )}
                        </HStack>
                      </VStack>
                    </VStack>
                    <HStack>
                      <Badge
                        variant="subtle"
                        colorScheme={
                          purchasedPlanInfo?.planStatus === 'inactive'
                            ? 'red'
                            : 'green'
                        }
                        px={2}
                        // border={1}
                        // borderStyle="solid"
                        // borderColor="green.700"
                        py={1}
                        rounded="lg"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap={1}
                      >
                        <Text
                          bg={
                            purchasedPlanInfo?.planStatus === 'inactive'
                              ? 'red.700'
                              : 'green.700'
                          }
                          w={2}
                          h={2}
                          rounded="100%"
                          mb={0.5}
                        />
                        <Text fontSize={10} textTransform={'capitalize'}>
                          {purchasedPlanInfo?.planStatus}
                        </Text>
                      </Badge>
                    </HStack>
                  </HStack>
                </VStack>
                <HStack
                  spacing={4}
                  flexWrap="wrap"
                  justifyContent="space-between"
                  w="100%"
                >
                  <VStack
                    flex={1}
                    border={1}
                    borderColor="gray.300"
                    borderStyle="solid"
                    rounded="lg"
                    p={4}
                    alignItems="flex-start"
                    w="100%"
                    justifyContent={'space-between'}
                  >
                    <HStack w="100%">
                      <Text fontSize={12}>Starting Date</Text>
                    </HStack>
                    <Text fontWeight={600} fontSize={18}>
                      {moment(purchasedPlanInfo?.purchasedDate).format(
                        'D MMMM, YYYY'
                      )}
                    </Text>
                  </VStack>
                  <VStack
                    flex={1}
                    border={1}
                    borderColor="gray.300"
                    borderStyle="solid"
                    rounded="lg"
                    p={4}
                    alignItems="flex-start"
                    w="100%"
                    justifyContent={'space-between'}
                  >
                    <HStack w="100%">
                      <Text fontSize={12}>Expiry Date</Text>
                    </HStack>
                    <Text fontWeight={600} fontSize={18}>
                      {moment(purchasedPlanInfo?.planExpiryDate).format(
                        'D MMMM, YYYY'
                      )}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </VStack>
            <Divider borderColor="gray.400" />
            <VStack alignItems="flex-start" w="100%" spacing={4}>
              <VStack alignItems="flex-start" gap={0}>
                <Text as="h1" fontWeight={600} fontSize={[14, 16]}>
                  Usage
                </Text>
                <Text as="h6" fontSize={12}>
                  Your usage is expired on{' '}
                  {moment(purchasedPlanInfo?.planExpiryDate).format(
                    'D MMMM, YYYY'
                  )}
                  .
                </Text>
              </VStack>
              <VStack
                gap={4}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                border={1}
                borderStyle="solid"
                borderColor="gray.300"
                w={'100%'}
                rounded="lg"
                p={4}
              >
                <HStack alignItems={'center'}>
                  <Box
                    border={1}
                    borderStyle="solid"
                    borderColor="gray.300"
                    w="min-content"
                    p={2}
                    rounded="100%"
                  >
                    <HiMiniDocumentText size={22} />
                  </Box>
                  <Text>Applications</Text>
                </HStack>
                <HStack
                  alignItems={'stretch'}
                  width={'100%'}
                  flexWrap="wrap"
                  spacing={4}
                >
                  <VStack
                    flex={1}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                    border={1}
                    borderColor="gray.300"
                    borderStyle="solid"
                    rounded="lg"
                    p={4}
                  >
                    <Text mb={1}>Applications Purchased</Text>
                    <Text fontWeight={600} fontSize={18}>
                      {purchasedPlanInfo?.totalApplications}
                    </Text>
                  </VStack>
                  <VStack
                    flex={1}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                    border={1}
                    borderColor="gray.300"
                    borderStyle="solid"
                    rounded="lg"
                    p={4}
                  >
                    <Text mb={1}>Application Used</Text>
                    <Text fontWeight={600} fontSize={18}>
                      {purchasedPlanInfo?.usedApplications}
                    </Text>
                  </VStack>
                  <VStack
                    flex={1}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                    border={1}
                    borderColor="gray.300"
                    borderStyle="solid"
                    rounded="lg"
                    p={4}
                  >
                    <Text mb={1}>Applications Remaining</Text>
                    <Text fontWeight={600} fontSize={18}>
                      {purchasedPlanInfo?.remainingApplications}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </VStack>
          </VStack>
        )}
        {loader?.planBilling ? (
          <PurchaseHistorySkeleton />
        ) : (
          <VStack
            alignItems={'start'}
            shadow={'sm'}
            borderWidth="1px"
            borderColor={'gray.400'}
            borderRadius={'xl'}
            p={[4, 6, 6]}
            gap={4}
            flex={1}
            maxW={['100%', '100%', '100%', '100%', '30rem']}
            w={'100%'}
          >
            <VStack alignItems={'flex-start'} w={'100%'} gap={0}>
              <Text as={'h1'} fontWeight={600} fontSize={[16, 18, 20]}>
                Purchase History
              </Text>
              <HStack
                justifyContent={'space-between'}
                w={'100%'}
                flexWrap="wrap"
              >
                <Text lineHeight={2.8} fontSize={12}>
                  There are 7 total invoices
                </Text>
              </HStack>
            </VStack>
            <Divider borderColor="gray.400" />
            <List
              spacing={3}
              styleType={'none'}
              w={'100%'}
              border="1px"
              borderColor={'gray.300'}
              rounded={'lg'}
              p={4}
              maxH={'400px'}
              overflowY={'auto'}
            >
              {invoicesData.map((invoice) => {
                const { id, amount, currency, status, date } = invoice;
                return (
                  <ListItem
                    key={id}
                    borderBottom="1px"
                    borderColor={'gray.300'}
                    w={'100%'}
                    pb={4}
                    _last={{
                      border: 0,
                      pb: 0,
                    }}
                  >
                    <HStack
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <VStack gap={0} alignItems={'flex-start'}>
                        <Text fontWeight={600}>Jp Morgan</Text>
                        <Text color={'gray.500'} fontSize={12}>
                          #{id}
                        </Text>
                        <Text>{date}</Text>
                      </VStack>
                      <HStack gap={3}>
                        <VStack gap={0} alignItems={'flex-end'}>
                          <Text fontSize={18} fontWeight={600}>
                            {getCurrencyDigit({
                              number: amount!,
                              options: {
                                currency,
                              },
                            })}
                          </Text>
                          <Badge
                            variant="solid"
                            colorScheme={status === 'Success' ? 'green' : 'red'}
                          >
                            {status}
                          </Badge>
                        </VStack>
                        <ArrowRightIcon cursor={'pointer'} />
                      </HStack>
                    </HStack>
                  </ListItem>
                );
              })}
            </List>
          </VStack>
        )}
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="md" boxShadow="lg" overflow="hidden">
          <ModalHeader bg="#114684" color="white" py={4} fontSize="lg">
            Top Up Plan
          </ModalHeader>
          <ModalCloseButton color="white" top="10px" right="10px" />
          <ModalBody py={6} px={8}>
            <VStack spacing={4} align="stretch">
              <Box>
                <Text fontSize="md" mb={2}>
                  No. of Applications
                </Text>
                <Input
                  type="number"
                  value={numApplications}
                  onChange={handleInputChange}
                  onKeyDown={preventAlphabetsTyping}
                  placeholder="Enter number of applications"
                  size="lg"
                  min={1}
                />
              </Box>
              <Divider />
              <Box>
                <Text fontSize={12}>
                  Price of one application (As per{' '}
                  <Text display={'inline-block'} fontWeight={600}>
                    Growth Plan
                  </Text>
                  )
                </Text>
                <Text fontSize="lg" mt={2}>
                  ${averagePrice}
                </Text>
              </Box>
              <Divider />
              <Box>
                <Text fontSize={12}>Total</Text>
                <Text fontSize="lg" fontWeight="bold" mt={2}>
                  ${totalPrice}
                </Text>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter bg="gray.100">
            {/* <AppOutlineButton mr={3} onClick={onClose}>
              Close
            </AppOutlineButton> */}
            <AppSolidButton>Pay</AppSolidButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BiilingPage;
