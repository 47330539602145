import { REPORT_NAME } from 'src/types/enums';
import { APIStatus, REPORT_SHORTFORM, REPORT_TYPE } from 'src/types/unions';
import { IWIPData } from '../WorkInProgress/state';
import { TWICData } from '../WorkingCapital/state';

type resData = {
  reportInfo: Record<string, any>;
  reportData: Record<string, any>[];
  id?: number;
  adaData: Record<string, any>;
  adjustedTotal: Record<string, any>;
  lastUpdateAt: string;
  updatedAt?: string;
};

export interface IReportStatus {
  shortForm: REPORT_SHORTFORM;
  reportName: REPORT_NAME;
  status: boolean;
}

interface APAgingSummary {
  status: APIStatus | null;
  error: any;
  data: resData | null;
  type?:
    | 'GET_ACCOUNT_PAYABLE_DATA'
    | 'GET_ACCOUNT_RECEIVABLE_DATA'
    | 'GET_PROFIT_LOSS_DATA'
    | 'GET_BALANCE_SHEET_DATA'
    | 'UPDATE_ACCOUNT_RECEIVABLE_RECORD'
    | 'REFRESH_AR_REPORT'
    | 'REFRESH_AP_REPORT'
    | 'REFRESH_BS_REPORT'
    | 'REFRESH_PL_REPORT'
    | 'GET_ALL_REPORTS'
    | 'GET_REPORTS_STATUS';
  allReportsData?: IReport[];
  reportsStatus?: IReportStatus[];
}

export type APReportDataType = {
  Vendor?: string;
  Customer?: string;
  Current: string;
  '1 - 30': string;
  '31 - 60': string;
  '61 - 90': string;
  '91 and over': string;
  Total: string;
};

export type TFilterOCRReport =
  | 'Most Recent Interim Financial Statement'
  | 'Most Recent Fiscal Year End'
  | '1-Year Previous Fiscal Year End'
  | '2-Year Previous Fiscal Year End';

export type TFilterAccountPayable =
  | 'Last Fiscal Year'
  | 'This Fiscal Year-to-date';

type updatedARData = {
  question: string;
  holdbackRetention: number | null;
  retentionValue: number | null;
  id: number;
};

export interface IReportInfo {
  filter: TFilterAccountPayable;
  reportName: REPORT_TYPE;
  qboCompanyName: string;
  reportGeneratedDate: string;
  startPeriod?: string;
  endPeriod?: string;
}

export interface IBaseData {
  id: number;
  applicantId: number;
  reportName: REPORT_TYPE;
  reportInfo: IReportInfo;
  reportData: Record<string, any>[];
  lastUpdateAt: string;
  createdAt: string;
  updatedAt: string;
  adaData?: Record<string, any>;
  adjustedTotal?: Record<string, any>;
}
export interface IReport {
  reportName: REPORT_TYPE;
  TFYD?: IBaseData | TWICData;
  LFY?: IBaseData | TWICData;
  data?: IWIPData;
}

export type updateARRecordType = {
  id: number | null;
  updatedData?: updatedARData[];
  adaData?: Record<string, any>;
  adjustedTotal?: Record<string, any>;
};

const initialState: APAgingSummary = {
  error: null,
  status: null,
  data: null,
};

export default initialState;
