import {
  Box,
  Button as ChakraButton,
  Flex,
  Heading,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Button, FileInput, List, Loader, Tabs, Textarea } from '@mantine/core';
import { IconFileCv } from '@tabler/icons-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { CgComment, CgTrash } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch, useAppSelector } from 'src/Redux/Store';
// import { contentType } from 'mime-types';
import { modals } from '@mantine/modals';
import { useLocation, useNavigate } from 'react-router-dom';
import { getApplicationById } from 'src/Redux/Applications/slice';
import { getApplicationsByApplicant } from 'src/Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { ApplicantBrokerage } from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { getLegalFormDocuments } from 'src/Redux/Broker/Documents/slice';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import {
  listAllReports,
  OcrReportScan,
  removeUploadReportsSliceType,
  uploadReports,
} from 'src/Redux/UploadReports/slice';
import {
  IUploadReportsData,
  ocrCombinedReportList,
} from 'src/Redux/UploadReports/state';
import {
  MAX_UPLOAD_SIZE,
  PDF_CSV_EXECLE_TYPES,
} from 'src/constants/UploadDocuments';
import { useAppToast, useS3FileUpload } from 'src/hooks';
import { isFileTypeAllowed } from 'src/utils/helpers';
import { UploadSchema } from '../../ValidationSchema/Pages/Index';
import LegalFormUploadFilesTable from '../Reports/components/uploadFiles/LegalFormUploadFilesTable';
import UploadFilesTable from '../Reports/components/uploadFiles/UploadFilesTable';
import {
  cleanObject,
  renameKeys,
} from '../Reports/components/uploadFiles/utils';
import {
  exportDocumentDownloadOptions,
  financialOption,
  financialStatementOption,
  OtherOptionWithtaxingEntity,
} from './data';
import { TZipName } from './types';
import { getSecondDropDownOption } from './utils';

export interface Props {
  callesFrom: string;
}

function UploadDropdowns({ callesFrom }: Props) {
  // states
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isSubmitDoc, setIsSubmitDoc] = useState(false);
  const [zipName, setZipName] = useState<TZipName | null>(null);
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [downloadDocOption, setDownloadDocOption] =
    useState<string>('All Documents');
  const [legalFormDocuments, setLegalFormDocuments] = useState<
    Record<string, any>
  >({});
  const [ocrReportData, setOcrReportData] = useState<Record<string, any>[]>([]);
  const [reportList, setReportList] = useState<IUploadReportsData[]>();

  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { showBox } = location.state || {};
  const { S3UploadError, S3UploadLoading, S3UploadResponse, uploadFilesInS3 } =
    useS3FileUpload();
  const userType = localStorage.getItem('userType');
  const createApplicationForm = useSelector(
    (state: RootState) => state.createApplicationFormSlice
  );
  const keydata =
    createApplicationForm?.applications.length > 0
      ? createApplicationForm?.applications[0]?.cms?.keyPersonnel
      : [];
  const paymentPlan =
    createApplicationForm?.applications[0]?.creditBureau?.paymentPlan;
  const uploadReportsStore = useSelector(
    (state: RootState) => state.uploadReportsSlice
  );
  // const userData = useSelector(
  //   (state: RootState) => state.brokerageDashboard.user
  // );
  const appliactionsSlice = useAppSelector((state) => state.appliactionsSlice);
  const brokerageDashboard = useSelector(
    (state: RootState) => state.brokerageDashboard
  );
  const documents = useAppSelector((state: RootState) => state.documentSlice);

  const keypersonneldata =
    appliactionsSlice?.currentApplication?.cms?.keyPersonnel;
  // let AccoutingFormData = userData && userData?.data?.accountingSystem;
  let CreditBureauFormData =
    appliactionsSlice && appliactionsSlice?.currentApplication?.creditBureau;

  // const paramsData = useParams<{ id: string }>();

  // const { showHistory, applicantId } = location.state || {};
  // const ongoingID = paramsData.id ? parseInt(paramsData.id, 10) : null;
  let Applicantbrokerage =
    appliactionsSlice && appliactionsSlice?.currentApplication;
  let currentApplicationId = Applicantbrokerage?.applicationId;
  let currentApplicantId = Applicantbrokerage?.applicantId;
  let ApplicationData =
    createApplicationForm && createApplicationForm?.applications;

  interface FormValues {
    applicationId: string;
    applicantId: string;
    financial: string;
    financialYear: string;
    financialStatement: string;
    financialFileWithComment: { file: File; comment?: string | null }[] | null;
  }
  const initialValues: FormValues = {
    applicationId: '',
    applicantId: '',
    financial: '',
    financialYear: '',
    financialStatement: '',
    financialFileWithComment: null,
  };

  const toast = useAppToast();

  useEffect(() => {
    return () => {
      dispatch(removeUploadReportsSliceType({}));
    };
  }, []);

  useEffect(() => {
    const { status, type, legalFormDocuments, error } = documents;
    switch (status) {
      case 'loading': {
        if (type === 'GET_LEGAL_FORM_DOCUMENTS') {
          setIsTableLoading(true);
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_LEGAL_FORM_DOCUMENTS') {
          // const data = {
          //   hasGuarantees: {
          //     guaranteeDetails: {
          //       financialDetails: {
          //         termsOfIndebtedness: [
          //           {
          //             link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
          //             linkId: '7bit',
          //             urlKey: 'applicant/legal/7mb.pdf-1728044954278',
          //           },
          //           {
          //             link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
          //             linkId: '7bit',
          //             urlKey: 'applicant/legal/7mb1.pdf-1728044954278',
          //           },
          //         ],
          //       },
          //     },
          //     legalAndRegulatoryCompliance: {
          //       guaranteeAgreementDocumentation: {
          //         legalDocuments: [
          //           {
          //             link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
          //             linkId: '7bit',
          //             urlKey: 'applicant/legal/7mb.pdf-1728044954278',
          //           },
          //           {
          //             link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
          //             linkId: '7bit',
          //             urlKey: 'applicant/legal/7mb1.pdf-1728044954278',
          //           },
          //         ],
          //       },
          //     },
          //   },
          //   hasChangesInManagement: {
          //     documentation: {
          //       regulatoryFilings: [
          //         {
          //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
          //           linkId: '7bit',
          //           urlKey: 'applicant/legal/7mb.pdf-1728044954278',
          //         },
          //         {
          //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
          //           linkId: '7bit',
          //           urlKey: 'applicant/legal/7mb1.pdf-1728044954278',
          //         },
          //       ],
          //       legalDocumentation: [
          //         {
          //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
          //           linkId: '7bit',
          //           urlKey: 'applicant/legal/7mb.pdf-1728044954278',
          //         },
          //         {
          //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
          //           linkId: '7bit',
          //           urlKey: 'applicant/legal/7mb1.pdf-1728044954278',
          //         },
          //       ],
          //       shareholderAgreements: [
          //         {
          //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
          //           linkId: '7bit',
          //           urlKey: 'applicant/legal/7mb.pdf-1728044954278',
          //         },
          //         {
          //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
          //           linkId: '7bit',
          //           urlKey: 'applicant/legal/7mb1.pdf-1728044954278',
          //         },
          //       ],
          //     },
          //   },
          //   hasStateFederalTax: [
          //     {
          //       caseNumber: '23132084',
          //       courtFilings: [
          //         {
          //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
          //           linkId: '7bit',
          //           urlKey: 'applicant/legal/7mb.pdf-1728044954278',
          //         },
          //       ],
          //       paymentPlanUpload: [],
          //     },
          //     {
          //       caseNumber: '23-058076',
          //       courtFilings: [],
          //       paymentPlanUpload: [],
          //     },
          //     {
          //       caseNumber: '202303170256',
          //       courtFilings: [],
          //       paymentPlanUpload: [
          //         {
          //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/TOC.pdf-1728044984926?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=40f3382c6b2590d960cebc95ca2d92fd0bc12d73b375b2b7ad4b8c145031699e&X-Amz-SignedHeaders=host&x-id=GetObject',
          //           linkId: '7bit',
          //           urlKey: 'applicant/legal/TOC.pdf-1728044984926',
          //         },
          //       ],
          //     },
          //     {
          //       caseNumber: '22-080531',
          //       courtFilings: [],
          //       paymentPlanUpload: [],
          //     },
          //     {
          //       caseNumber: '202008310444',
          //       courtFilings: [],
          //       paymentPlanUpload: [],
          //     },
          //     {
          //       caseNumber: '202004230228',
          //       courtFilings: [],
          //       paymentPlanUpload: [],
          //     },
          //     {
          //       caseNumber: '2451@3808',
          //       courtFilings: [],
          //       paymentPlanUpload: [],
          //     },
          //     {
          //       caseNumber: '20190814-0081361',
          //       courtFilings: [],
          //       paymentPlanUpload: [],
          //     },
          //     {
          //       caseNumber: '201700000175',
          //       courtFilings: [],
          //       paymentPlanUpload: [],
          //     },
          //     {
          //       caseNumber: '9850645',
          //       courtFilings: [],
          //       paymentPlanUpload: [],
          //     },
          //     {
          //       caseNumber: 'DJ  267011  12',
          //       courtFilings: [],
          //       paymentPlanUpload: [],
          //     },
          //     {
          //       caseNumber: '201200015933',
          //       courtFilings: [],
          //       paymentPlanUpload: [],
          //     },
          //     {
          //       caseNumber: '201202140071',
          //       courtFilings: [],
          //       paymentPlanUpload: [],
          //     },
          //   ],
          //   hasLiensFiledAgainstCompany: [],
          //   hasOutstandingJudgments: [],
          //   hasOutstandingSuits: [
          //     {
          //       caseNumber: 'A2401607',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '23M1 103640',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '23M1 103594',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV22967905',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '22CV002882',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV2022041258',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CGCU22 599018',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CGCU22 598282',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '21CV008026',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '2021-018751-CA-01',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV2021051665',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'A2100817',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'A2004209',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CGCU20 587345',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '2020-018014-CA-01',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '202000800645',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '202000701912',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV20935136',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '202000602135',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CGCU20 584839',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '05771  LACL147631',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV2019124636',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201901101050',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CGCU19 580314',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '2019-029676-CA-01',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201900700222',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201901074425CJC',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'A1901065',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '47-CV-2019-900280.00',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201900100226',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201800603736',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201800302012',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201800300761',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV18893923',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '2018967204CJC',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201701201121',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201700013424',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201700702567',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CSM17 855724',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201700603526',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201700501514',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '17CV192289',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV17879402',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV17878862',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'SCC  651630',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201700429481V',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV17873899',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV16872996',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '05771  LACL136258',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV16868546',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'A1604181',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'A1603491',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '16CV005373',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '16CV003627',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '16CVU290128',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201501101767',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '115CVU285740',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201500900818',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV@15-851042',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'A1504772',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '05771  LACL133624',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV@15-846939',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV@15-846671',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201500403204',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201500400368',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201500202961',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '2015772664NJC',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV@15-839168',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201500000817',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201400031578',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201400904769',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV2014094069',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201400702770',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV@14-822127',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201400201081',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201301101992',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV@13-815018',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201300380566V',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV@13-811103',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CGCU13531945',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201300500937',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201300002519-001',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV@13-803167',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201300301174',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201300200837',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '2012CA009512',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '12CV015628',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV@12-797741',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '12CV015419',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201200006683SCSCCTL',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CV@12-795732',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '12GC20828',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201201001699',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: 'CGCU12523619',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201200365276V',
          //       courtFilings: [],
          //     },
          //     {
          //       caseNumber: '201200402992',
          //       courtFilings: [],
          //     },
          //   ],
          //   hasOutstandingClaims: [],
          //   hasLiensFiledByCompany: [],
          //   hasBuySellAgreement: {
          //     agreementCopy: null,
          //   },
          // };
          const renamedLegalData = renameKeys(legalFormDocuments);

          // remove null key-value and blank object/array value contain keys
          const removeNullKeyValue = cleanObject(renamedLegalData);
          console.log('removeNullKeyValue', removeNullKeyValue);

          //below two action perform before Export Legal All Data - legal document data table added
          // //arrange zip folderwise data
          // //if object key has multiple object then add folder,if single object then don't add folder,and add nested object key is array then add that upload field folder.
          // const folderStructureFormat = restructureObject(removeNullKeyValue);
          // console.log('folderStructureFormat', folderStructureFormat);

          // //clean caseNumberKey and then cleanObject to remove blank array
          // const cleanCaseNumber = cleanObject(
          //   cleanCaseNumberObjects(folderStructureFormat)
          // );
          // console.log('cleanCaseNumber', cleanCaseNumber);

          setLegalFormDocuments(removeNullKeyValue);

          setIsTableLoading(false);
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_LEGAL_FORM_DOCUMENTS') {
          setIsTableLoading(false);
          toast({
            title: error,
            status: 'error',
          });
        }
        break;
      }

      default:
        break;
    }
    return () => {};
  }, [documents.status]);

  // useEffect(() => {
  //   if (
  //     downloadDocOption === 'Legal Form Documents' &&
  //     Object.keys(legalFormDocuments)?.length === 0 &&
  //     values?.applicationId
  //   ) {
  //     dispatch(
  //       getLegalFormDocuments({
  //         applicationId: Number(values?.applicationId),
  //       })
  //     );
  //   }
  // }, [downloadDocOption]);

  useEffect(() => {
    const { status, type, error } = createApplicationForm;

    switch (status) {
      case 'loading': {
        break;
      }
      case 'succeed': {
        break;
      }
      case 'failed': {
        if (type === 'GET_APPLICATIONS_BY_APPLICANT_ID') {
          toast({
            status: 'error',
            title: error,
          });
          resetForm();
          // setFieldValue('applicantId', '');
        }
        break;
      }

      default:
        break;
    }
  }, [createApplicationForm.status]);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData } = brokerageDashboard;
    if (status === 'succeed') {
      if (
        type === 'GET_APPLICANT_BY_BROKERAGE' &&
        !!applicantsByBrokrageData?.length
      ) {
        setApplicantOptions(applicantsByBrokrageData);
      }
    }
  }, [brokerageDashboard.status]);

  useEffect(() => {
    if (S3UploadResponse) {
      let payload = {
        applicationId: Number(values.applicationId),
        reportInfo: {
          reportName:
            values.financial === 'Financial Statement'
              ? values.financialStatement
              : values.financialYear,
          timeFrame:
            values.financial === 'Financial Statement'
              ? values.financialYear
              : null,
          type: values.financial,
        },
        reportLinks:
          (S3UploadResponse as any)?.data?.map((value: any, index: number) => {
            return {
              link: value?.location,
              urlKey: value?.key,
              comment:
                values?.financialFileWithComment?.[index].comment ?? null,
            };
          }) ?? [],
      };
      if (values.financial === 'Financial Statement') {
        payload = Object.assign(payload, { ocrReportData });
      }
      if (
        Object.values(ocrCombinedReportList)
          ?.map((el) => el.toString())
          .includes(values.financialStatement)
      ) {
        payload = Object.assign(payload, { isCombinedReport: true });
      }
      dispatch(uploadReports(payload));
    }
    return () => {};
  }, [S3UploadResponse]);

  const uploadDocsS3 = async (values: FormValues) => {
    try {
      const localFiles = values.financialFileWithComment?.map(
        (data) => data.file
      );
      if (localFiles?.length) {
        const formData = new FormData();
        localFiles.forEach((file, index) => {
          formData.append(`files`, file);
        });

        await uploadFilesInS3(formData);

        if (S3UploadError) {
          toast({
            title: 'Erro in file uploading!!',
            status: 'error',
          });
          return;
        }
      }
    } catch (err) {
      console.log('submitReportsHandler', err);
    }
  };

  const submitReportsHandler = async (values: FormValues) => {
    try {
      if (values?.financial === 'Financial Statement') {
        const localFiles = values.financialFileWithComment?.map(
          (data) => data.file
        );
        if (localFiles?.length) {
          const formData = new FormData();
          localFiles.forEach((file, index) => {
            formData.append(`file`, file);
          });

          if (
            Object.values(ocrCombinedReportList)
              ?.map((el) => el.toString())
              .includes(values.financialStatement)
          ) {
            formData.append(`reportName`, values.financialStatement);
          }
          await dispatch(OcrReportScan(formData));
        }
      } else {
        uploadDocsS3(values);
      }
    } catch (error) {
      console.log('submitReportsHandler', error);
    }
  };

  const checkReportTypeValidation = (values: FormValues) => {
    console.log('values', values);
    if (reportList && !!reportList?.length) {
      const findUploadReport = reportList?.filter((item) => {
        const { reportInfo } = item;
        if (values?.financial === 'Financial Statement') {
          return (
            reportInfo?.type === values?.financial &&
            reportInfo?.reportName === values?.financialStatement &&
            reportInfo?.timeFrame === values?.financialYear
          );
        } else {
          return (
            reportInfo?.type === values?.financial &&
            reportInfo?.reportName === values?.financialYear
          );
        }
      });
      console.log('findUploadReport', findUploadReport);
      if (!!findUploadReport?.length) {
        // alert('already uploaded!!!');
        modals.openConfirmModal({
          title: ``,
          withCloseButton: false,
          children: (
            <Box display={'flex'} flexDirection={'column'} gap={3}>
              <Text color="black" fontSize={'16px'}>
                Do you want to replace?
              </Text>
              <Text color="black" fontSize={'14px'}>
                You have already uploaded document for{' '}
                {values?.financial +
                  ' - ' +
                  (values?.financial === 'Financial Statement'
                    ? values?.financialStatement + ' - '
                    : '') +
                  values?.financialYear}
                .
              </Text>
            </Box>
          ),
          labels: { confirm: 'Yes', cancel: 'No' },
          confirmProps: { color: 'blue' },
          cancelProps: { color: 'red' },
          onConfirm: () => {
            submitReportsHandler(values);
          },
          onCancel: () => {
            resetData();
          },
        });
      } else {
        submitReportsHandler(values);
      }
    } else {
      submitReportsHandler(values);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: UploadSchema,
    onSubmit: checkReportTypeValidation,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    resetForm,
    setTouched,
  } = formik;

  const resetData = () => {
    setFieldValue('financial', initialValues.financial);
    setFieldValue('financialYear', initialValues.financialYear);
    setFieldValue('financialStatement', initialValues.financialStatement);
    setFieldValue(
      'financialFileWithComment',
      initialValues.financialFileWithComment
    );
    setTouched({ ...touched, financialFileWithComment: false });
    setOcrReportData([]);
  };

  useEffect(() => {
    const { status, type, ocrReportData, error, applicantReportsData } =
      uploadReportsStore;
    console.log('ocrReportData', ocrReportData);

    switch (status) {
      case 'loading': {
        if (type === 'GET_APPLICANT_REPORTS') {
          setIsTableLoading(true);
        }
        if (type === 'OCR_REPORT_SCAN' || type === 'POST_UPLOAD_REPORTS') {
          setIsSubmitDoc(true);
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_APPLICANT_REPORTS' && applicantReportsData) {
          setIsTableLoading(false);
          setReportList(applicantReportsData);
        }
        if (type === 'POST_UPLOAD_REPORTS') {
          setIsSubmitDoc(false);
          toast({
            title: 'Report uploaded successfully!!',
            status: 'success',
          });
          resetData();
          values?.applicationId &&
            dispatch(
              listAllReports({
                applicationId: Number(values.applicationId),
              })
            );
        }
        if (type === 'DELETE_REPORT') {
          toast({
            status: 'success',
            title: 'Report deleted successfully',
          });
          dispatch(
            listAllReports({
              applicationId: Number(values.applicationId),
            })
          );
        }
        if (type === 'UPDATE_REPORT') {
          toast({
            status: 'success',
            title: 'Report updated successfully',
          });
          dispatch(
            listAllReports({
              applicationId: Number(values.applicationId),
            })
          );
        }
        if (type === 'OCR_REPORT_SCAN') {
          if (ocrReportData && !!ocrReportData?.length) {
            setOcrReportData(ocrReportData);
            uploadDocsS3(values);
            // setIsSubmitDoc(false);
          }
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_APPLICANT_REPORTS') {
          setIsTableLoading(false);
        }
        if (type === 'OCR_REPORT_SCAN' || type === 'POST_UPLOAD_REPORTS') {
          setIsSubmitDoc(false);
          toast({
            status: 'error',
            title: error,
          });
        }
        break;
      }
    }
  }, [uploadReportsStore.status]);

  useEffect(() => {
    if (userType === 'applicant' && callesFrom !== 'Documents') {
      const applicantId = localStorage.getItem('userID');
      formik.setFieldValue('applicantId', applicantId);
    }
  }, [userType]);

  useEffect(() => {
    if (
      callesFrom === 'Documents' &&
      currentApplicationId &&
      Applicantbrokerage
    ) {
      formik.setFieldValue('applicationId', currentApplicationId);
      setZipName({
        applicantName: Applicantbrokerage?.applicant?.companyName,
        applicationName: Applicantbrokerage?.applicationName,
      });
      // fetchOngoingApplication();
    }
  }, [currentApplicationId]);

  useEffect(() => {
    if (callesFrom === 'Documents' && currentApplicantId) {
      // fetchOngoingApplicant();
      formik.setFieldValue('applicantId', currentApplicantId);
    }
  }, [currentApplicantId, dispatch, callesFrom]);

  useEffect(() => {
    if (callesFrom !== 'Documents' && userType !== 'applicant') {
      dispatch(ApplicantBrokerage());
    }
    // if (callesFrom !== 'Documents') {
    //   setFieldValue('applicationId', currentApplicationId);
    // }
  }, []);
  useEffect(() => {
    if (values.applicantId !== '') {
      if (callesFrom !== 'Documents')
        dispatch(
          getApplicationsByApplicant({
            applicantId: Number(values.applicantId),
          })
        );
    }
  }, [values.applicantId]);
  useEffect(() => {
    if (values.applicationId && downloadDocOption === 'All Documents') {
      dispatch(
        listAllReports({
          applicationId: Number(values.applicationId),
        })
      );
    }
    if (values.applicationId && downloadDocOption === 'Legal Form Documents') {
      dispatch(
        getLegalFormDocuments({
          applicationId: Number(values?.applicationId),
        })
      );
    }
  }, [values.applicationId, downloadDocOption]);

  // const fetchOngoingApplicant = async () => {
  //   let id = !showHistory && ongoingID ? ongoingID : 0;
  //   id = showHistory && applicantId ? applicantId : id;
  //   formik.setFieldValue('applicantId', id);
  //   let actionResult = await dispatch(OngoingApplicant({ id: id }) as any);
  //   if (actionResult?.payload?.status === 401) {
  //     navigate('/login');
  //   }
  // };

  const fetchOngoingApplication = async () => {
    dispatch(
      getApplicationById({
        applicationId: currentApplicationId ?? Number(values?.applicationId),
      }) as any
    );
  };

  const toggleCommentBox = (index: number, val: string | null) => {
    if (val !== null)
      setFieldValue(`financialFileWithComment[${index}].comment`, null);
    else setFieldValue(`financialFileWithComment[${index}].comment`, '');
  };

  const handleFileInputUpload = (payload: File | File[]) => {
    const files = Array.isArray(payload) ? payload : [payload];

    const isValid = files?.map((file) => ({
      typeError: !isFileTypeAllowed(
        file?.name,
        formik?.values?.financial === 'Financial Statement'
          ? PDF_CSV_EXECLE_TYPES?.filter(
              (el) => !['.doc', '.docx'].includes(el)
            )
          : PDF_CSV_EXECLE_TYPES
      ),
      sizeError: file?.size > MAX_UPLOAD_SIZE,
    }));

    if (isValid.some((error) => error.typeError)) {
      toast({
        title: 'Invalid file type!',
        status: 'error',
      });
      return;
    }

    if (isValid.some((error) => error.sizeError)) {
      toast({
        title: `Max file size allowed is ${MAX_UPLOAD_SIZE / 1024 ** 2} MB!`,
        status: 'error',
      });
      return;
    }

    const newFiles = files.map((file) => ({
      file,
      comment: null,
    }));

    setFieldValue('financialFileWithComment', newFiles);
  };

  const getDisabledOptions = (data: IUploadReportsData[]) => {
    const disabledOptions = new Set();

    data?.forEach((item) => {
      const reportName = item.reportInfo.reportName;
      if (reportName === 'Balance sheet / Profit and Loss') {
        disabledOptions.add('Balance Sheet');
        disabledOptions.add('Profit and Loss');
      } else if (reportName === 'Balance sheet / Profit and Loss / Cash flow') {
        disabledOptions.add('Balance Sheet');
        disabledOptions.add('Profit and Loss');
        disabledOptions.add('Cashflow');
      }
    });

    return Array.from(disabledOptions);
  };

  // const handleChangeDownloadOption = (
  //   e: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   // console.log('value', e.target.value);
  //   setDownloadDocOption(e.target.value);
  // };

  return (
    <>
      <Box py={5}>
        {!showBox && (
          <>
            <Box
              display={{ base: 'block', md: 'flex' }}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
              borderBottom={'1px solid #E2E8F0'}
            >
              <Heading
                m="4"
                fontSize={'24px'}
                fontWeight={'600'}
                // borderBottomWidth="1px"
              >
                Upload Documents
              </Heading>
              {/* <PdfContent /> */}
            </Box>
            <Tabs
              value={downloadDocOption}
              onTabChange={(val) => {
                if (val) {
                  setDownloadDocOption(val);
                  resetData();
                }
              }}
              my={'20px'}
            >
              <Tabs.List
                style={{
                  border: 'unset',
                }}
              >
                {exportDocumentDownloadOptions?.map((item) => {
                  return <Tabs.Tab value={item?.value}>{item?.label}</Tabs.Tab>;
                })}
              </Tabs.List>
            </Tabs>
            {((downloadDocOption === 'Legal Form Documents' &&
              callesFrom !== 'Documents') ||
              downloadDocOption === 'All Documents') && (
              <Box p={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Flex flexDirection={'column'} columnGap={5}>
                    <Flex mb={5} gap={1} flexWrap={'wrap'}>
                      {userType === 'broker' && callesFrom !== 'Documents' && (
                        <Box>
                          <Box maxW="max-content">
                            <Select
                              w="100%"
                              onChange={(event) => {
                                const selectedOption =
                                  event.target.options[
                                    event.target.selectedIndex
                                  ];
                                const label =
                                  selectedOption.getAttribute('label') ?? '';
                                setZipName({
                                  applicationName:
                                    zipName?.applicationName ?? '',
                                  applicantName: label,
                                });

                                setFieldValue(
                                  'applicantId',
                                  event.target.value
                                );
                              }}
                              value={formik?.values?.applicantId ?? ''}
                              name="applicantId"
                              borderRadius="5px"
                              bg={'#114684'}
                              color={'white'}
                              textAlign={'center'}
                            >
                              <option
                                value={''}
                                style={{ color: 'black' }}
                                label="Select Applicant"
                              />
                              {applicantOptions.map((applicant) => (
                                <option
                                  style={{ color: 'black' }}
                                  key={applicant?.applicantId}
                                  value={applicant?.applicantId ?? ''}
                                  label={applicant?.companyName ?? ''}
                                  aria-label={applicant?.companyName ?? ''}
                                />
                              ))}
                            </Select>
                          </Box>
                          {touched.applicantId && errors.applicantId && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.applicantId}
                            </div>
                          )}
                        </Box>
                      )}
                      {values?.applicantId &&
                        (createApplicationForm.status === 'succeed' ||
                          callesFrom !== 'Documents') &&
                        callesFrom !== 'Documents' && (
                          <Box>
                            <Box maxW="max-content">
                              <Select
                                w="100%"
                                onChange={(event) => {
                                  const selectedOption =
                                    event.target.options[
                                      event.target.selectedIndex
                                    ];
                                  const label =
                                    selectedOption.getAttribute('label') ?? '';
                                  setZipName({
                                    applicantName: zipName?.applicantName ?? '',
                                    applicationName: label,
                                  });

                                  setFieldValue(
                                    'applicationId',
                                    event.target.value
                                  );
                                }}
                                value={formik?.values?.applicationId ?? ''}
                                name="applicationId"
                                borderRadius="5px"
                                bg={'#114684'}
                                color={'white'}
                                textAlign={'center'}
                              >
                                <option
                                  value={''}
                                  style={{ color: 'black' }}
                                  label="Select Application"
                                />
                                {Array.isArray(ApplicationData) &&
                                  ApplicationData?.map(
                                    (user?: any, index?: number) => (
                                      <option
                                        style={{ color: 'black' }}
                                        key={index}
                                        value={user?.applicationId ?? ''}
                                        label={user?.applicationName ?? ''}
                                      />
                                    )
                                  )}
                              </Select>
                            </Box>
                            {touched.applicationId && errors.applicationId && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {errors.applicationId}
                              </div>
                            )}
                          </Box>
                        )}
                      {values?.applicantId &&
                        values?.applicationId &&
                        downloadDocOption === 'All Documents' && (
                          // createApplicationForm.status === 'succeed' &&
                          <Box>
                            <Box maxW="max-content">
                              <Select
                                name="financial"
                                id="financial"
                                w="100%"
                                borderRadius="5px"
                                bg={'#114684'}
                                color={'white'}
                                textAlign={'center'}
                                value={formik?.values?.financial ?? ''}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'financial',
                                    e.target.value
                                  );
                                  // formik.setFieldValue('financialYear', null);
                                  // formik.setFieldValue('financialStatement', null);
                                  if (
                                    values?.financialFileWithComment &&
                                    values?.financialFileWithComment?.length >
                                      1 &&
                                    e.target.value === 'Financial Statement'
                                  ) {
                                    formik.setFieldValue(
                                      'financialFileWithComment',
                                      values.financialFileWithComment?.splice(
                                        0,
                                        1
                                      )
                                    );
                                  }
                                }}
                              >
                                <option
                                  value={''}
                                  style={{ color: 'black' }}
                                  label="Select Option"
                                />
                                {Array.isArray(financialOption) &&
                                  financialOption.map(
                                    (item: any, index: number) => (
                                      <option
                                        style={{ color: 'black' }}
                                        key={index}
                                        value={item.value}
                                        label={item.label}
                                      />
                                    )
                                  )}
                              </Select>
                            </Box>
                            {touched.financial &&
                              errors.financial &&
                              values.financial === '' && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                  }}
                                >
                                  {errors.financial}
                                </div>
                              )}
                          </Box>
                        )}
                      {createApplicationForm.status === 'loading' && (
                        <Flex alignItems={'center'}>
                          <Loader size={'sm'} h={'100%'} />
                        </Flex>
                      )}

                      {values?.applicantId &&
                        (createApplicationForm.status === 'succeed' ||
                          callesFrom === 'Documents') &&
                        values?.applicationId &&
                        formik?.values?.financial &&
                        formik?.values?.financial === 'Financial Statement' &&
                        downloadDocOption === 'All Documents' && (
                          <Box>
                            <Box
                              maxW="max-content"
                              className="financial_statement_dropdown"
                            >
                              <Select
                                w="100%"
                                borderRadius="5px"
                                bg={'#114684'}
                                color={'white'}
                                textAlign={'center'}
                                onChange={handleChange}
                                value={formik?.values?.financialStatement ?? ''}
                                name="financialStatement"
                              >
                                <option
                                  value={''}
                                  style={{ color: 'black' }}
                                  label="Select Option"
                                />
                                {Array.isArray(financialStatementOption) &&
                                  financialStatementOption.map(
                                    (item: any, index: number) => {
                                      const disabledOptions =
                                        getDisabledOptions(reportList!);

                                      return (
                                        <option
                                          style={{
                                            color: 'black',
                                            cursor: disabledOptions.includes(
                                              item.value
                                            )
                                              ? 'not-allowed !important'
                                              : 'default',
                                            background:
                                              disabledOptions.includes(
                                                item.value
                                              )
                                                ? 'light-grey'
                                                : 'white',
                                          }}
                                          key={index}
                                          value={item.value}
                                          label={item.label}
                                          disabled={disabledOptions.includes(
                                            item.value
                                          )}
                                        />
                                      );
                                    }
                                  )}
                              </Select>
                            </Box>
                            {touched.financialStatement &&
                              errors.financialStatement && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                  }}
                                >
                                  {errors.financialStatement}
                                </div>
                              )}
                          </Box>
                        )}

                      {values.applicantId !== '' &&
                        (createApplicationForm.status === 'succeed' ||
                          callesFrom === 'Documents') &&
                        values?.applicationId &&
                        values?.financial &&
                        downloadDocOption === 'All Documents' && (
                          <Box>
                            <Box maxW="max-content">
                              <Select
                                w="100%"
                                borderRadius="5px"
                                bg={'#114684'}
                                color={'white'}
                                textAlign={'center'}
                                onChange={handleChange}
                                value={formik?.values?.financialYear ?? ''}
                                name="financialYear"
                              >
                                <option
                                  value={''}
                                  style={{ color: 'black' }}
                                  label="Select Option"
                                />
                                {formik.values.financial ===
                                  'Key Personnel Resume' &&
                                callesFrom === 'Documents'
                                  ? keypersonneldata?.map(
                                      (item: any, index: number) => (
                                        <option
                                          style={{ color: 'black' }}
                                          key={index}
                                          value={item.name}
                                          label={item.name}
                                        />
                                      )
                                    )
                                  : formik?.values?.financial ===
                                        'Key Personnel Resume' &&
                                      callesFrom !== 'Documents'
                                    ? keydata?.map(
                                        (item: any, index: number) => (
                                          <option
                                            style={{ color: 'black' }}
                                            key={index}
                                            value={item.name}
                                            label={item.name}
                                          />
                                        )
                                      )
                                    : formik?.values?.financial === 'Other' &&
                                        ((callesFrom === 'Documents' &&
                                          CreditBureauFormData?.paymentPlan) ||
                                          (callesFrom !== 'Documents' &&
                                            paymentPlan))
                                      ? OtherOptionWithtaxingEntity?.map(
                                          (item: any, index: number) => (
                                            <option
                                              style={{ color: 'black' }}
                                              key={index}
                                              value={item.value}
                                              label={item.label}
                                            />
                                          )
                                        )
                                      : getSecondDropDownOption(
                                          formik?.values?.financial
                                        )?.map((item: any, index: number) => (
                                          <option
                                            style={{ color: 'black' }}
                                            key={index}
                                            value={item.value}
                                            label={item.label}
                                          />
                                        ))}
                              </Select>
                            </Box>
                            {touched.financialYear && errors.financialYear && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {errors.financialYear}
                              </div>
                            )}
                          </Box>
                        )}
                    </Flex>
                    {downloadDocOption === 'All Documents' && (
                      <>
                        <Box>
                          <Box mb={5}>
                            <Flex
                              flexWrap={'wrap'}
                              gap={3}
                              alignItems={'flex-end'}
                            >
                              <FileInput
                                icon={
                                  <IconFileCv
                                    style={{ width: 18, height: 18 }}
                                    stroke={1.5}
                                  />
                                }
                                styles={{
                                  input: {
                                    maxWidth: '350px',
                                    border: '1px solid #114684',
                                  },
                                }}
                                // @ts-ignore
                                placeholder="Upload files"
                                label="Upload files"
                                multiple={
                                  formik.values.financial !==
                                    'Financial Statement' &&
                                  formik.values.financial !==
                                    'Key Personnel Resume'
                                }
                                onChange={(payload: File[]) =>
                                  handleFileInputUpload(payload)
                                }
                                value={
                                  values.financialFileWithComment?.map(
                                    (file) => file.file
                                  ) ?? []
                                }
                                description={`The file format should be - ${formik?.values?.financial === 'Financial Statement' ? 'xlsx, xls, csv, pdf' : 'xlsx, xls, csv, pdf, doc, docx'}`}
                                withAsterisk
                                variant="filled"
                                accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              />
                              <Button
                                disabled={
                                  !values.financialFileWithComment?.length
                                }
                                color="red"
                                onClick={(e) =>
                                  setFieldValue('financialFileWithComment', [])
                                }
                                type="button"
                              >
                                Reset
                              </Button>
                            </Flex>
                            {touched.financialFileWithComment &&
                              errors.financialFileWithComment && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                  }}
                                >
                                  {errors.financialFileWithComment}
                                </div>
                              )}
                          </Box>
                          {values.financialFileWithComment &&
                            values.financialFileWithComment.length > 0 && (
                              <List
                                spacing="xs"
                                size="sm"
                                center
                                type="ordered"
                                mb={16}
                              >
                                {values.financialFileWithComment?.map(
                                  (data, index) => (
                                    <List.Item className="file-item">
                                      <Box w={500}>
                                        <Flex gap={2} mb={1}>
                                          <Text>{data.file.name}</Text>
                                          <Tooltip
                                            label="Delete"
                                            placement={'top'}
                                            hasArrow
                                          >
                                            <Box>
                                              <CgTrash
                                                style={{
                                                  cursor: 'pointer',
                                                }}
                                                className="file-item-trash"
                                                onClick={() => {
                                                  const newData =
                                                    values.financialFileWithComment?.filter(
                                                      (_, i) => i !== index
                                                    );

                                                  setFieldValue(
                                                    `financialFileWithComment`,
                                                    newData
                                                  );
                                                }}
                                              />
                                            </Box>
                                          </Tooltip>
                                          <Tooltip
                                            label="Additional Info"
                                            placement={'top'}
                                            hasArrow
                                          >
                                            <Box>
                                              <CgComment
                                                style={{
                                                  cursor: 'pointer',
                                                }}
                                                className="file-item-trash"
                                                onClick={() =>
                                                  toggleCommentBox(
                                                    index,
                                                    data.comment!
                                                  )
                                                }
                                              />
                                            </Box>
                                          </Tooltip>
                                        </Flex>
                                        {data.comment !== null && (
                                          <Textarea
                                            placeholder="comment"
                                            label="Comment"
                                            autosize
                                            minRows={2}
                                            maxRows={4}
                                            value={data.comment ?? ''}
                                            onChange={(event) =>
                                              setFieldValue(
                                                `financialFileWithComment[${index}].comment`,
                                                event.currentTarget.value
                                              )
                                            }
                                            styles={{
                                              wrapper: {
                                                marginBottom: 16,
                                              },
                                            }}
                                          />
                                        )}
                                      </Box>
                                    </List.Item>
                                  )
                                )}
                              </List>
                            )}
                        </Box>
                        <Box>
                          <ChakraButton
                            isLoading={S3UploadLoading || isSubmitDoc}
                            type="submit"
                            bg="#114684"
                            color="white"
                            _hover={{ bg: '#114684', color: 'white' }}
                            loadingText={'Submit Documents'}
                            // disabled={!(formik.isValid && formik.dirty)}
                          >
                            Submit Documents
                          </ChakraButton>
                        </Box>
                      </>
                    )}
                  </Flex>
                </form>
              </Box>
            )}
          </>
        )}
        {/* <Box p={5}>
          <Select
            // placeholder="Select option"
            maxW={'max-content'}
            onChange={handleChangeDownloadOption}
          >
            {exportDocumentDownloadOptions?.map((item: any) => (
              <option value={item?.value}>{item?.label}</option>
            ))}
          </Select>
        </Box> */}
        <Box px={5}>
          {zipName &&
            values.applicantId !== '' &&
            values.applicationId !== '' &&
            (createApplicationForm.status === 'succeed' ||
              callesFrom === 'Documents') &&
            downloadDocOption === 'All Documents' && (
              <UploadFilesTable
                formState={formik?.values}
                isTableLoading={isTableLoading}
                zipName={zipName}
              />
            )}
          {zipName &&
            values.applicantId !== '' &&
            values.applicationId !== '' &&
            (createApplicationForm.status === 'succeed' ||
              callesFrom === 'Documents') &&
            downloadDocOption === 'Legal Form Documents' && (
              <LegalFormUploadFilesTable
                isTableLoading={isTableLoading}
                zipName={zipName}
                legalFormDocuments={legalFormDocuments}
              />
            )}
        </Box>
      </Box>
    </>
  );
}

export default UploadDropdowns;
