import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { APIStatus } from 'src/types/unions';
import callAPI from 'src/utils/callAPI';
import { BASEURL } from '../../../App';
import { tokenExipry } from '../../tokenExpiry';

interface DateRange {
  start: string;
  end: string;
}
export interface FinancialYearDateRange {
  mostRecentInterim: DateRange;
  mostRecentFiscalYear: DateRange;
  oneYearPrevious: DateRange;
  twoYearsPrevious: DateRange;
}

interface ApplicantProfileState {
  user: any;
  loading: boolean;
  error: null;
  type?: 'GET_APPLICANT_APP_LIST';
  // applicantAppList: any | null;
  status: APIStatus | null;
}

const initialState: ApplicantProfileState = {
  user: [],
  loading: false,
  error: null,
  // applicantAppList: null,
  status: null,
};

export const Applicant = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

interface RequestData {
  companyName: string;
  // officeAddress: string;
  email: string;
  companyLegalName: string | null;
  postalCode: string | null;
  street: string | null;
  city: string | null;
  country: string | null;
  province: string | null;
  businessPhone: string | null;
  companyDomain: string | null;
}

// Broker get api

export const ApplicantProfileUpdate = createAsyncThunk(
  'applicant/ApplicantProfileUpdate',
  async (
    {
      requestData,
      applicantId,
    }: {
      requestData: RequestData;
      applicantId: number | string;
    },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/applicant/update/${applicantId}`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Applicant get api

export const fetchApplicantUserData = createAsyncThunk(
  'applicant/fetchApplicantUserData',
  async () => {
    try {
      const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/user/applicant/get/${userId}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.request(config);

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

// get Applicant all ongoing applications
export const getApplicantAppList = createAsyncThunk(
  'applicant/getApplicantAppList',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const res = await callAPI(
        `application/applicant-all-app?applicantId=${id}`,
        'GET'
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

const ApplicantProfileSlice = createSlice({
  name: 'ApplicantProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // brokerage update
      .addCase(ApplicantProfileUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        ApplicantProfileUpdate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(ApplicantProfileUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Applicant get api

      .addCase(fetchApplicantUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchApplicantUserData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(fetchApplicantUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      //get applicant app list
      .addCase(getApplicantAppList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_APPLICANT_APP_LIST';
      })
      .addCase(
        getApplicantAppList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          // state.applicantAppList = action.payload?.data;
          state.status = 'succeed';
          state.type = 'GET_APPLICANT_APP_LIST';
        }
      )
      .addCase(getApplicantAppList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
        state.status = 'failed';
        state.type = 'GET_APPLICANT_APP_LIST';
      });
  },
});

export default ApplicantProfileSlice.reducer;
