import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Modal } from '@mantine/core';

const DeleteBSReportRecordModal = ({
  isOpen,
  onClose,
  onConfirm,
  ocrUpdateLoader,
  selectedRow,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  ocrUpdateLoader: boolean;
  selectedRow: {
    rowIndex: number;
    rowData: Record<string, any>;
  } | null;
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={() => onClose()}
      title="Delete"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'md'}
    >
      <Box margin={'10px 10px 0 10px'}>
        <Text>
          Are you sure you want to delete {selectedRow?.rowData?.Label}? This
          action cannot be undone.
        </Text>
        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button type="button" onClick={onClose} isDisabled={ocrUpdateLoader}>
            Cancel
          </Button>
          <Button
            type="button"
            colorScheme="red"
            onClick={onConfirm}
            loadingText="Delete"
            isLoading={ocrUpdateLoader}
            isDisabled={ocrUpdateLoader}
          >
            Delete
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default DeleteBSReportRecordModal;
