import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASEURL } from '../../../App';
import { tokenExipry } from '../../tokenExpiry';

interface QboState {
  data: any;
  loading: boolean;
  error: null;
  applicationId: null | number;
  escAccess: boolean;
  escReason: string;
  qboAccess: boolean;
  qboReason: string;
  qboAccountLinked: boolean;
  qboCompanyName: string;
  creditDataInfo: Record<string, any>[];
}

const initialState: QboState = {
  data: null,
  loading: true,
  error: null,
  applicationId: null,
  escAccess: false,
  escReason: '',
  qboAccess: false,
  qboReason: '',
  qboAccountLinked: false,
  qboCompanyName: '',
  creditDataInfo: [],
};

export const Qbo = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

// Qboauth
export const Qboauth = createAsyncThunk('qbo/Qboauth', async () => {
  try {
    const Token = localStorage.getItem('Token');

    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BASEURL}/qbo/auth-token`,
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.request(config);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  } catch (error) {
    throw error;
  }
});

//company info

export const Companyinfo = createAsyncThunk('qbo/Companyinfo', async () => {
  try {
    const Token = localStorage.getItem('Token');
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BASEURL}/quickbook/get-comapny-info`,
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await axios.request(config);

    return response.data;
  } catch (error) {
    throw error;
  }
});
//qbo all data

export const QboAlldata = createAsyncThunk('qbo/QboAlldata', async () => {
  try {
    const Token = localStorage.getItem('Token');

    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BASEURL}/quickbook/get-all`,
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.request(config);

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  } catch (error) {
    throw error;
  }
});

// Check QBO Status
export const CheckQboStatus = createAsyncThunk(
  'qbo/checkQboStatus',
  async (id: string | number) => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/permission/check-status/${id}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.request(config);
        return { ...response.data, applicationId: id };
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

// Permission Check QBO
export const PermissionCheckQbo = createAsyncThunk(
  'qbo/permissionCheckQbo',
  async () => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'GET',
        url: `${BASEURL}/permission/check-qbo/`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const qboSlice = createSlice({
  name: 'qbo',
  initialState,
  reducers: {
    ClearQboStatus: (state) => {
      state.applicationId = null;
      state.escAccess = false;
      state.escReason = '';
      state.qboAccess = false;
      state.qboReason = '';
    },
  },
  extraReducers: (builder) => {
    builder

      // Qbo Account
      .addCase(Qboauth.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Qboauth.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(Qboauth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // company info
      .addCase(Companyinfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Companyinfo.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(Companyinfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      //qbo all data
      .addCase(QboAlldata.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(QboAlldata.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(QboAlldata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // qbo status check
      .addCase(CheckQboStatus.pending, (state) => {
        state.loading = true;
        state.applicationId = null;
        state.escAccess = false;
        state.escReason = '';
        state.qboAccess = false;
        state.qboReason = '';
        state.qboCompanyName = '';
        state.qboAccountLinked = false;
      })
      .addCase(
        CheckQboStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { data, applicationId } = action.payload;
          state.loading = false;
          state.applicationId = applicationId;
          state.escAccess = data?.escAccess;
          state.escReason = data?.escReason;
          state.qboAccess = data?.qboAccess;
          state.qboReason = data?.qboReason;
          state.qboCompanyName = data?.qboCompanyName;
          state.creditDataInfo = data?.creditDataInfo;
          state.qboAccountLinked = data?.qboAccountLinked;
        }
      )
      .addCase(CheckQboStatus.rejected, (state) => {
        state.loading = false;
        state.applicationId = null;
        state.escAccess = false;
        state.escReason = '';
        state.qboAccess = false;
        state.qboReason = '';
        state.qboCompanyName = '';
        state.qboAccountLinked = false;
      })

      // permission check qbo
      .addCase(PermissionCheckQbo.pending, (state) => {
        state.loading = true;
        state.qboAccountLinked = false;
        state.qboCompanyName = '';
      })
      .addCase(
        PermissionCheckQbo.fulfilled,
        (state, action: PayloadAction<any>) => {
          const data = action.payload?.data;
          state.loading = false;
          state.qboAccountLinked = !!data?.qboAccountLinked;
          state.qboCompanyName = data?.companyName || '';
        }
      )
      .addCase(PermissionCheckQbo.rejected, (state) => {
        state.loading = false;
        state.qboAccountLinked = false;
        state.qboCompanyName = '';
      });
  },
});
export const { ClearQboStatus } = qboSlice.actions;
export default qboSlice.reducer;
