import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { FaHistory, FaUserEdit } from 'react-icons/fa';
import { IoEyeSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import ApplicationTableAction from 'src/Component/Common/TableActions/Header';
import { useAppToast } from 'src/hooks';
import { useApplicationNavigation } from 'src/hooks/useApplicationNavigation';
import { getApplicationsList } from 'src/Redux/Applicant/ApplicantDashboard/slice';
import { RootState, useAppSelector } from 'src/Redux/Store';

const ApplicantDashboardTable = () => {
  const { handelEdit, handelHistory, handelView } = useApplicationNavigation();
  const applicantDashboard = useAppSelector(
    (state: RootState) => state.applicantDashboardSlice
  );
  const data = applicantDashboard && applicantDashboard?.applications;

  const toast = useAppToast();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const userType = localStorage.getItem('userType');

  useEffect(() => {
    dispatch(getApplicationsList() as any);
  }, []);

  useEffect(() => {
    const { status, error, type } = applicantDashboard;
    switch (status) {
      case 'loading':
        if (type === 'GET_APPLICANT_APPLICATIONS_LIST') {
          setIsLoading(true);
        }
        break;
      case 'succeed':
        if (type === 'GET_APPLICANT_APPLICATIONS_LIST') {
          setIsLoading(false);
        }
        break;
      case 'failed':
        if (type === 'GET_APPLICANT_APPLICATIONS_LIST') {
          toast({
            title: error,
            status: 'error',
          });
          setIsLoading(false);
        }
        break;
    }
  }, [applicantDashboard?.status]);

  return (
    <Box>
      <Heading
        p="4"
        fontSize={'24px'}
        fontWeight={'600'}
        borderBottomWidth="1px"
      >
        Dashboard Details
      </Heading>
      {isLoading ? (
        <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <Box display={'flex'} flexDirection={'column'} gap={2} mt={2}>
            <Box pl={[2, 2, 4, 4, 4]} pr={[2, 2, 4, 4, 4]}>
              <Grid
                bg="#114684"
                display={'grid'}
                // justifyContent={'space-between'}
                templateColumns="repeat(12, 1fr)"
                h={'auto'}
                borderRadius={3}
                alignItems={'center'}
                p={2}
                gap={[1, 1, 2, 2, 2]}
                w={'100%'}
                color={'white'}
                fontWeight={500}
                fontSize={[10, 13, 13, 13, 13]}
              >
                <GridItem colSpan={2}>
                  <Text textAlign={'start'}>Application Code</Text>
                </GridItem>
                <GridItem colSpan={2}>
                  <Text textAlign={'start'}>Applicant Name</Text>
                </GridItem>
                <GridItem colSpan={2}>
                  <Text textAlign={'start'}>Awaiting Input</Text>
                </GridItem>
                <GridItem colSpan={2}>
                  <Text textAlign={'start'}>Broker Name</Text>
                </GridItem>
                <GridItem
                  colSpan={3}
                  display={'flex'}
                  justifyContent={'end'}
                  gap={[2, 2, 3, 3, 3]}
                  color={'white'}
                  fontWeight={500}
                  fontSize={[10, 13, 13, 13, 13]}
                >
                  <ApplicationTableAction />
                </GridItem>
              </Grid>
            </Box>
            {Array.isArray(data) && data?.length > 0 ? (
              data?.map((user: any) => (
                <>
                  <Box key={user?.id} pl={[2, 2, 4, 4, 4]} pr={[2, 2, 4, 4, 4]}>
                    <Box>
                      <Grid
                        bg="#ffa011"
                        gap={2}
                        h={'auto'}
                        borderRadius={3}
                        p={2}
                        color={'black'}
                        fontWeight={500}
                        fontSize={13}
                        templateColumns={'repeat(12, 1fr)'}
                      >
                        <GridItem colSpan={2}>
                          <Text textAlign={'start'}>
                            {user.applicationName}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Text textAlign={'start'}>
                            {user?.applicant?.companyName}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Text textAlign={'start'}>
                            {user?.isAwaitingInput ? 'Yes' : 'No'}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Text textAlign={'start'}>
                            {user?.broker?.firstName} {user?.broker?.lastName}
                          </Text>
                        </GridItem>

                        <GridItem
                          colSpan={3}
                          display={'flex'}
                          justifyContent={'end'}
                          gap={[3, 4]}
                          w={'100%'}
                          color={'black'}
                          fontSize={13}
                        >
                          <Box
                            minW={'25px'}
                            cursor={'pointer'}
                            _hover={{ color: '#114684' }}
                            onClick={() =>
                              handelView({
                                applicationId: user.applicationId,
                              })
                            }
                          >
                            <IoEyeSharp size={20} />
                          </Box>
                          <Box
                            minW={'25px'}
                            cursor={
                              user?.isAwaitingInput ? 'pointer' : 'default'
                            }
                            _hover={{ color: '#114684' }}
                            onClick={
                              user?.isAwaitingInput
                                ? () => {
                                    handelEdit({
                                      applicationId: user.applicationId,
                                    });
                                  }
                                : () => {}
                            }
                          >
                            <FaUserEdit
                              size={20}
                              visibility={
                                user?.isAwaitingInput ? 'visible' : 'hidden'
                              }
                            />
                          </Box>
                          <Box
                            minW={'25px'}
                            cursor={'pointer'}
                            _hover={{ color: '#114684' }}
                            onClick={() =>
                              handelHistory({
                                applicationId: user.applicationId,
                                applicantId: user.applicantId,
                              })
                            }
                          >
                            <FaHistory size={18} />
                          </Box>
                        </GridItem>
                      </Grid>
                    </Box>
                  </Box>
                </>
              ))
            ) : (
              <>
                <Box pl={4} pr={4}>
                  <Box
                    bg="#ffa011"
                    display={'flex'}
                    justifyContent={'space-between'}
                    gap={2}
                    h={10}
                    borderRadius={3}
                    alignItems={'center'}
                    p={2}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      w={'100%'}
                      color={'black'}
                      fontWeight={500}
                      fontSize={15}
                    >
                      <Box width={'100%'}>
                        <Text textAlign={'center'}>Not found</Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ApplicantDashboardTable;
