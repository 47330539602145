export const guaranteesForOtherPartiesAcc = [
  {
    name: 'guaranteeDetails',
    subSection: [
      {
        name: 'guarantorInformation',
        fields: [
          {
            label: 'Name of the Guarantor(s)',
            name: 'hasGuarantees.guaranteeDetails.guarantorInformation.nameOfGuarantors',
          },
          {
            label: 'Position(s)',
            name: 'hasGuarantees.guaranteeDetails.guarantorInformation.position',
          },
        ],
        sectionNo: 1,
      },
      {
        name: 'beneficiaryInformation',
        fields: [
          {
            label: 'Name of the Beneficiary',
            name: 'hasGuarantees.guaranteeDetails.beneficiaryInformation.nameOfBeneficiary',
          },
          {
            label: 'Relationship to Guarantor',
            name: 'hasGuarantees.guaranteeDetails.beneficiaryInformation.relationshipToGuarantor',
          },
        ],
        sectionNo: 2,
      },
      {
        name: 'guaranteeAgreementDetails',
        fields: [
          {
            label: 'Type of Guarantee',
            name: 'hasGuarantees.guaranteeDetails.guaranteeAgreementDetails.typeOfGuarantee',
          },
          {
            label: 'Date of Agreement',
            name: 'hasGuarantees.guaranteeDetails.guaranteeAgreementDetails.dateOfAgreement',
            type: 'date',
            //   refState: guaranteesForOtherPartiesRef,
          },
          {
            label: 'Duration of Guarantee',
            name: 'hasGuarantees.guaranteeDetails.guaranteeAgreementDetails.durationOfGuarantee',
          },
        ],
        sectionNo: 3,
      },
      {
        name: 'financialDetails',
        fields: [
          {
            label: 'Amount Guaranteed',
            name: 'hasGuarantees.guaranteeDetails.financialDetails.amountGuaranteed',
          },
          {
            label: 'Currency',
            name: 'hasGuarantees.guaranteeDetails.financialDetails.currency',
          },
          // {
          //   label: 'Terms of Indebtedness',
          //   name: 'hasGuarantees.guaranteeDetails.financialDetails.termsOfIndebtedness',
          //   type: 'file',
          //   uploadState: 'termsOfIndebtedness',
          // },
        ],
        sectionNo: 4,
      },
      {
        name: 'collateralAndSecurity',
        fields: [
          {
            label: 'Collateral Provided',
            name: 'hasGuarantees.guaranteeDetails.collateralAndSecurity.collateralProvided',
          },
          {
            label: 'Valuation of Collateral',
            name: 'hasGuarantees.guaranteeDetails.collateralAndSecurity.valuationOfCollateral',
          },
        ],
        sectionNo: 5,
      },
    ],
  },
  {
    name: 'impactOnFinancialHealth',
    subSection: [
      {
        name: 'currentStatusOfIndebtedness',
        fields: [
          {
            label: 'Outstanding Balance',
            name: 'hasGuarantees.impactOnFinancialHealth.currentStatusOfIndebtedness.outstandingBalance',
          },
          {
            label: 'Payment Status',
            name: 'hasGuarantees.impactOnFinancialHealth.currentStatusOfIndebtedness.paymentStatus',
          },
        ],
        sectionNo: 6,
      },
    ],
  },
  {
    name: 'legalAndRegulatoryCompliance',
    subSection: [
      {
        name: 'guaranteeAgreementDocumentation',
        fields: [
          // {
          //   label: 'Legal Documents',
          //   name: 'hasGuarantees.legalAndRegulatoryCompliance.guaranteeAgreementDocumentation.legalDocuments',
          //   type: 'file',
          //   uploadState: 'uploadLegalDoc',
          // },
          {
            label: 'Court Filings',
            name: 'hasGuarantees.legalAndRegulatoryCompliance.guaranteeAgreementDocumentation.courtFilings',
          },
        ],
        sectionNo: 7,
      },
    ],
  },
];

export const changesInManagementAcc = [
  {
    name: 'changeInControl',
    fields: [
      {
        label: 'Nature of Change in Control',
        name: 'hasChangesInManagement.changeInControl.natureOfChangeInControl',
      },
      {
        label: 'Date of Change',
        name: 'hasChangesInManagement.changeInControl.dateOfChange',
        type: 'date',
        //   refState: changeInControlRef,
      },
      {
        label: 'Entities Involved',
        name: 'hasChangesInManagement.changeInControl.entitiesInvolved',
      },
      {
        label: 'Details of Control Transfer',
        name: 'hasChangesInManagement.changeInControl.detailsOfControlTransfer',
      },
    ],
  },
  {
    name: 'changeInOwnership',
    subSection: [
      {
        name: 'changeinShareholder',
        fields: [
          {
            label: 'Nature of Ownership Change',
            name: 'hasChangesInManagement.changeInOwnership.changeinShareholder.natureOfOwnershipChange',
          },
          {
            label: 'Date of Change',
            name: 'hasChangesInManagement.changeInOwnership.changeinShareholder.dateOfChange',
            type: 'date',
            //   refState: changeinShareholderRef,
          },
          {
            label: 'New Owners',
            name: 'hasChangesInManagement.changeInOwnership.changeinShareholder.newOwners',
          },
          {
            label: 'Reason for Change',
            name: 'hasChangesInManagement.changeInOwnership.changeinShareholder.reasonForChange',
          },
        ],
        sectionNo: 1,
      },
      {
        name: 'minorityshareholder',
        fields: [
          {
            label: 'Nature of Ownership Change',
            name: 'hasChangesInManagement.changeInOwnership.minorityshareholder.natureOfOwnershipChange',
          },
          {
            label: 'Date of Change',
            name: 'hasChangesInManagement.changeInOwnership.minorityshareholder.dateOfChange',
            type: 'date',
            //   refState: minorityshareholderRef,
          },
          {
            label: 'New Owners',
            name: 'hasChangesInManagement.changeInOwnership.minorityshareholder.newOwners',
          },
          {
            label: 'Reason for Change',
            name: 'hasChangesInManagement.changeInOwnership.minorityshareholder.reasonForChange',
          },
        ],
        sectionNo: 2,
      },
      {
        name: 'majorityshareholder',
        fields: [
          {
            label: 'Nature of Ownership Change',
            name: 'hasChangesInManagement.changeInOwnership.majorityshareholder.natureOfOwnershipChange',
          },
          {
            label: 'Date of Change',
            name: 'hasChangesInManagement.changeInOwnership.majorityshareholder.dateOfChange',
            type: 'date',
            //   refState: majorityshareholderRef,
          },
          {
            label: 'New Owners',
            name: 'hasChangesInManagement.changeInOwnership.majorityshareholder.newOwners',
          },
          {
            label: 'Reason for Change',
            name: 'hasChangesInManagement.changeInOwnership.majorityshareholder.reasonForChange',
          },
        ],
        sectionNo: 3,
      },
    ],
  },
  {
    name: 'changeInManagement',
    fields: [
      {
        label: 'Nature of Management Change',
        name: 'hasChangesInManagement.changeInManagement.natureOfManagementChange',
      },
      {
        label: 'Date of Change',
        name: 'hasChangesInManagement.changeInManagement.dateOfChange',
        type: 'date',
        //   refState: changeInManagementRef,
      },
      {
        label: 'Previous Management',
        name: 'hasChangesInManagement.changeInManagement.previousManagement',
      },
      {
        label: 'New Management',
        name: 'hasChangesInManagement.changeInManagement.newManagement',
      },
      {
        label: 'Reason for Change',
        name: 'hasChangesInManagement.changeInManagement.reasonForChange',
      },
    ],
  },
  // {
  //   name: 'documentation',
  //   fields: [
  //     {
  //       label: 'Legal Documentation',
  //       name: 'hasChangesInManagement.documentation.legalDocumentation',
  //       type: 'file',
  //       uploadState: 'legalDocument',
  //     },
  //     {
  //       label: 'Shareholder Agreements',
  //       name: 'hasChangesInManagement.documentation.shareholderAgreements',
  //       type: 'file',
  //       uploadState: 'shareholderAgreements',
  //     },
  //     {
  //       label: 'Regulatory Filings',
  //       name: 'hasChangesInManagement.documentation.regulatoryFilings',
  //       type: 'file',
  //       uploadState: 'regulatoryFilings',
  //     },
  //   ],
  // },
  {
    name: 'futurePlans',
    fields: [
      {
        label: 'Impending Changes',
        name: 'hasChangesInManagement.futurePlans.impendingChanges',
      },
    ],
  },
];

export const relatedCompaniesAcc = [
  {
    name: 'globalUltimate',
    subSection: [
      {
        name: 'generalInfoRelatedCompanies',
        fields: [
          {
            label: 'Names of Related Companies',
            name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.namesOfRelatedCompanies',
          },
          {
            label: 'Type of Relationship',
            name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.typeOfRelationship',
          },
          {
            label: 'Ownership Structure',
            name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.ownershipStructure',
          },
        ],
        sectionNo: 1,
      },
      {
        name: 'companyDetails',
        fields: [
          {
            label: 'Business Activities',
            name: 'hasRelatedCompanies.globalUltimate.companyDetails.businessActivities',
          },
          {
            label: 'Locations',
            name: 'hasRelatedCompanies.globalUltimate.companyDetails.locations',
          },
        ],
        sectionNo: 2,
      },
      {
        name: 'operationalAndManagementDetails',
        fields: [
          // {
          //   label: 'Management Structure',
          //   name: 'hasRelatedCompanies.operationalAndManagementDetails.managementStructure',
          // },
          {
            label: 'Operational Dependencies',
            name: 'hasRelatedCompanies.globalUltimate.operationalAndManagementDetails.operationalDependencies',
          },
        ],
        sectionNo: 3,
      },
    ],
  },
  {
    name: 'domesticUltimate',
    subSection: [
      {
        name: 'generalInfoRelatedCompanies',
        fields: [
          {
            label: 'Names of Related Companies',
            name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.namesOfRelatedCompanies',
          },
          {
            label: 'Type of Relationship',
            name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.typeOfRelationship',
          },
          {
            label: 'Ownership Structure',
            name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.ownershipStructure',
          },
        ],
        sectionNo: 1,
      },
      {
        name: 'companyDetails',
        fields: [
          {
            label: 'Business Activities',
            name: 'hasRelatedCompanies.domesticUltimate.companyDetails.businessActivities',
          },
          {
            label: 'Locations',
            name: 'hasRelatedCompanies.domesticUltimate.companyDetails.locations',
          },
        ],
        sectionNo: 2,
      },
      {
        name: 'operationalAndManagementDetails',
        fields: [
          {
            label: 'Operational Dependencies',
            name: 'hasRelatedCompanies.domesticUltimate.operationalAndManagementDetails.operationalDependencies',
          },
        ],
        sectionNo: 3,
      },
    ],
  },
  {
    name: 'parent',
    subSection: [
      {
        name: 'generalInfoRelatedCompanies',
        fields: [
          {
            label: 'Names of Related Companies',
            name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.namesOfRelatedCompanies',
          },
          {
            label: 'Type of Relationship',
            name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.typeOfRelationship',
          },
          {
            label: 'Ownership Structure',
            name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.ownershipStructure',
          },
        ],
        sectionNo: 1,
      },
      {
        name: 'companyDetails',
        fields: [
          {
            label: 'Business Activities',
            name: 'hasRelatedCompanies.parent.companyDetails.businessActivities',
          },
          {
            label: 'Locations',
            name: 'hasRelatedCompanies.parent.companyDetails.locations',
          },
        ],
        sectionNo: 2,
      },
      {
        name: 'operationalAndManagementDetails',
        fields: [
          {
            label: 'Operational Dependencies',
            name: 'hasRelatedCompanies.parent.operationalAndManagementDetails.operationalDependencies',
          },
        ],
        sectionNo: 3,
      },
    ],
  },
];

export const bondClaimsAcc = [
  {
    name: 'generalInformation',
    fields: [
      {
        label: 'Type of Surety Bond',
        name: 'hasClaimsSuretybond.generalInformation.typeofSuretyBond',
      },
      {
        label: 'Bond Amount',
        name: 'hasClaimsSuretybond.generalInformation.bondAmount',
      },
      {
        label: 'Bond Number',
        name: 'hasClaimsSuretybond.generalInformation.bondNumber',
      },
      {
        label: 'Issuing Surety Company',
        name: 'hasClaimsSuretybond.generalInformation.issuingSuretyCompany',
      },
    ],
  },
  {
    name: 'claimDetails',
    fields: [
      {
        label: 'Nature of the Claim',
        name: 'hasClaimsSuretybond.claimDetails.natureOftheClaim',
      },
      {
        label: 'Date of Claim',
        name: 'hasClaimsSuretybond.claimDetails.dateOfClaim',
        type: 'date',
        //   refState: bondClaimsRef,
      },
      {
        label: 'Claimant Information',
        name: 'hasClaimsSuretybond.claimDetails.claimantInformation',
      },
    ],
  },
  {
    name: 'projectDetails',
    fields: [
      {
        label: 'Project Involved',
        name: 'hasClaimsSuretybond.projectDetails.projectInvolved',
      },
      {
        label: 'Scope of Work',
        name: 'hasClaimsSuretybond.projectDetails.scopeOfWork',
      },
      {
        label: 'Contract Amount',
        name: 'hasClaimsSuretybond.projectDetails.contractAmount',
      },
    ],
  },
  {
    name: 'financialDetails',
    fields: [
      {
        label: 'Claim Amount',
        name: 'hasClaimsSuretybond.financialDetails.claimAmount',
      },
      {
        label: 'Breakdown of Claim',
        name: 'hasClaimsSuretybond.financialDetails.breakdownOfClaim',
      },
    ],
  },
  {
    name: 'resolutionAndSettlement',
    fields: [
      {
        label: 'Current Status of Claim',
        name: 'hasClaimsSuretybond.resolutionAndSettlement.currentStatusOfClaim',
      },
      {
        label: 'Resolution Efforts',
        name: 'hasClaimsSuretybond.resolutionAndSettlement.resolutionEfforts',
      },
      {
        label: 'Outcome or Settlement',
        name: 'hasClaimsSuretybond.resolutionAndSettlement.outcomeSettlement',
      },
    ],
  },
  {
    name: 'impactOnCompany',
    fields: [
      {
        label: 'Financial Impact',
        name: 'hasClaimsSuretybond.impactOnCompany.financialImpact',
      },
    ],
  },
  {
    name: 'legalAndDocumentation',
    fields: [
      {
        label: 'Legal Representation',
        name: 'hasClaimsSuretybond.legalAndDocumentation.legalRepresentation',
      },
      {
        label: 'Court Filings',
        name: 'hasClaimsSuretybond.legalAndDocumentation.courtFilings',
      },
    ],
  },
  {
    name: 'riskAssessmentAndMitigation',
    fields: [
      {
        label: 'Mitigation Strategies',
        name: 'hasClaimsSuretybond.riskAssessmentAndMitigation.mitigationStrategies',
      },
    ],
  },
  {
    name: 'communication',
    fields: [
      {
        label: 'Regulatory Compliance',
        name: 'hasClaimsSuretybond.communication.regulatoryCompliance',
      },
    ],
  },
  {
    name: 'futureRisks',
    fields: [
      {
        label: 'Potential Future Claims',
        name: 'hasClaimsSuretybond.futureRisks.potentialFutureClaims',
      },
      {
        label: 'Strategic Plans',
        name: 'hasClaimsSuretybond.futureRisks.strategicPlans',
      },
    ],
  },
];

export const buySellAgreementAcc = [
  {
    name: 'genralInfo',
    fields: [
      {
        label: 'Nature of Agreement',
        name: 'hasBuySellAgreement.genralInfo.natureOfAgreement',
      },
      {
        label: 'Date of Agreement',
        name: 'hasBuySellAgreement.genralInfo.dateOfAgreement',
        type: 'date',
        //   refState: buySellAgreementRef,
      },
      {
        label: 'Parties Involved',
        name: 'hasBuySellAgreement.genralInfo.partiesInvolved',
      },
    ],
  },
  {
    name: 'agreementTerms',
    fields: [
      {
        label: 'Triggering Events',
        name: 'hasBuySellAgreement.agreementTerms.triggeringEvents',
      },
      {
        label: 'Valuation Method',
        name: 'hasBuySellAgreement.agreementTerms.valuationMethod',
      },
      {
        label: 'Funding Mechanism',
        name: 'hasBuySellAgreement.agreementTerms.fundingMechanism',
      },
      {
        label: 'Payment Terms',
        name: 'hasBuySellAgreement.agreementTerms.paymentTerms',
      },
    ],
  },
  {
    name: 'ownershipInfo',
    fields: [
      {
        label: 'Ownership Structure',
        name: 'hasBuySellAgreement.ownershipInfo.ownershipStructure',
      },
      {
        label: 'Shareholder/Partner Interests',
        name: 'hasBuySellAgreement.ownershipInfo.shareholderOrPartnerInterests',
      },
    ],
  },
  {
    name: 'financialImpect',
    fields: [
      {
        label: 'Insurance Policies',
        name: 'hasBuySellAgreement.financialImpect.insurancePolicies',
      },
    ],
  },
  {
    name: 'legalDocumentation',
    fields: [
      // {
      //   label: 'Agreement Copy',
      //   name: 'hasBuySellAgreement.legalDocumentation.agreementCopy',
      //   type: 'file',
      //   uploadState: 'agreementCopy',
      // },
      {
        label: 'Legal Representation',
        name: 'hasBuySellAgreement.legalDocumentation.legalRepresentation',
      },
    ],
  },
  {
    name: 'impectOnBussinessOpretions',
    fields: [
      {
        label: 'Operational Impact',
        name: 'hasBuySellAgreement.impectOnBussinessOpretions.operationalImpact',
      },
      {
        label: 'Management Succession',
        name: 'hasBuySellAgreement.impectOnBussinessOpretions.managementSuccession',
      },
    ],
  },
  {
    name: 'complianceAndCommunication',
    fields: [
      {
        label: 'Regulatory Compliance',
        name: 'hasBuySellAgreement.complianceAndCommunication.regulatoryCompliance',
      },
    ],
  },
  {
    name: 'futurePlanning',
    fields: [
      {
        label: 'Review and Updates',
        name: 'hasBuySellAgreement.futurePlanning.reviewAndUpdates',
      },
    ],
  },
  {
    name: 'riskAssessmentAndMitigation',
    fields: [
      {
        label: 'Mitigation Strategies',
        name: 'hasBuySellAgreement.riskAssessmentAndMitigation.mitigationStrategies',
      },
    ],
  },
];
