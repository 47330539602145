import { Box } from '@chakra-ui/react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from 'mantine-react-table';

const AccordianTable = ({
  tableData,
  formik,
  title,
  formikState,
  columns,
}: {
  tableData: LegalFormAccordianTable[] | [];
  formik: Record<string, any>;
  title: string;
  formikState: string;
  columns: MRT_ColumnDef<LegalFormAccordianTable>[] | [];
}) => {
  const table = useMantineReactTable({
    columns: columns as any,
    data: tableData ?? [],
    enableEditing: true,
    editDisplayMode: 'table',
    getRowId: (row, index) => row?.id ?? index + '',
    mantineTableContainerProps: {
      className: 'legal-form-table',
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: 'auto',
      },
    },
    enableToolbarInternalActions: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableRowActions: false,
    enableColumnPinning: true,
    defaultColumn: { minSize: 200, maxSize: 200, size: 200 },
    initialState: {
      density: 'xs',
      columnPinning: { left: ['name'] },
    },
  });

  return (
    <Box maxW={'1550px'} width={'100%'} mx={'auto'}>
      <MantineReactTable table={table} />
    </Box>
  );
};

export default AccordianTable;
