import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProgressInfoAttributes } from '../../../../Pages/Reports/components/data';
import callAPI from '../../../../utils/callAPI';
import initialState, { allWIPdataAPI } from './state';

interface IRemoveWIPData {
  documentId: number;
  progressId: number;
}

export interface IUpdateWIPData extends IRemoveWIPData {
  applicantId: number;
  documentId: number;
  progressId: number;
  progressInfo: Partial<ProgressInfoAttributes>;
}
interface IUpdaterevenueWIPData {
  documentId: number;
  revenueBasis: string;
}

export function isRejectedActionWithPayload(
  action: any
): action is { payload: { errorMessage: string } } {
  return action.payload && typeof action.payload.msg === 'string'
    ? action.payload.msg
    : '';
}

export const getWIPDataByApplicantId = createAsyncThunk(
  'workInProgressSlice/getWIPDataByApplicantId',
  async (
    {
      applicantId,
      showAll,
    }: {
      applicantId: number;
      showAll: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        'wip/create',
        'POST',
        {
          applicantId,
          showAll,
        },
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const updateWIPData = createAsyncThunk(
  'workInProgressSlice/updateWIPData',
  async (
    { documentId, progressId, applicantId, progressInfo }: IUpdateWIPData,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wip/update', 'PUT', {
        id: documentId,
        progressId,
        applicantId,
        progressInfo,
      });
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const addWIPData = createAsyncThunk(
  'workInProgressSlice/addWIPData',
  async (
    {
      documentId,
      applicantId,
      progressInfo,
    }: Pick<IUpdateWIPData, 'documentId' | 'applicantId' | 'progressInfo'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wip/update', 'PUT', {
        id: documentId,
        // progressId,
        applicantId,
        progressInfo,
      });
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const removeWIPData = createAsyncThunk(
  'workInProgressSlice/deleteProgressReport',
  async ({ documentId, progressId }: IRemoveWIPData, { rejectWithValue }) => {
    try {
      const response = await callAPI('wip/remove', 'PUT', {
        id: documentId,
        progressId,
      });
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const refreshProgressReport = createAsyncThunk(
  'workInProgressSlice/RefreshProgressReport',
  async (
    { applicantId }: Pick<IUpdateWIPData, 'applicantId'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wip/refresh', 'POST', {
        applicantId,
      });
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const updateRevenue = createAsyncThunk(
  'workInProgressSlice/updateRevenue',
  async (
    { documentId, revenueBasis }: IUpdaterevenueWIPData,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wip/update-revenue', 'PUT', {
        id: documentId,
        // progressId,
        revenueBasis,
      });
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

const brokerReportSlice = createSlice({
  name: 'workInProgressSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWIPDataByApplicantId.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_WIP_DATA_BY_APPLICATIONID_API';
        state.allWIPdata = action.payload.data;
      })
      .addCase(getWIPDataByApplicantId.pending, (state) => {
        state.status = 'loading';
        state.type = 'GET_WIP_DATA_BY_APPLICATIONID_API';
      })
      .addCase(getWIPDataByApplicantId.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_WIP_DATA_BY_APPLICATIONID_API';
        state.error = isRejectedActionWithPayload(action);
        state.allWIPdata = allWIPdataAPI;
      })
      .addCase(updateWIPData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'UPDATE_WIP_API';
        state.message = action.payload.data;
      })
      .addCase(updateWIPData.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'UPDATE_WIP_API';
      })
      .addCase(updateWIPData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'UPDATE_WIP_API';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(removeWIPData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'REMOVE_WIP_API';
        state.message = action.payload.data;
      })
      .addCase(removeWIPData.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'REMOVE_WIP_API';
      })
      .addCase(removeWIPData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'REMOVE_WIP_API';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(addWIPData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'ADD_WIP_API';
        state.message = action.payload.data;
      })
      .addCase(addWIPData.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'ADD_WIP_API';
      })
      .addCase(addWIPData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'ADD_WIP_API';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(refreshProgressReport.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'REFRESH_WIP_API';
        state.allWIPdata = action.payload;
      })
      .addCase(refreshProgressReport.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'REFRESH_WIP_API';
      })
      .addCase(refreshProgressReport.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'REFRESH_WIP_API';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(updateRevenue.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'UPDATE_REVNUE_API';
        // state.allWIPdata = action.payload;
      })
      .addCase(updateRevenue.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'UPDATE_REVNUE_API';
      })
      .addCase(updateRevenue.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'UPDATE_REVNUE_API';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const brokerReportProgressSlice = brokerReportSlice.reducer;

export default brokerReportProgressSlice;
