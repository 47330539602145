import {
  Divider,
  HStack,
  List,
  ListItem,
  Skeleton,
  SkeletonCircle,
  VStack,
} from '@chakra-ui/react';

const PurchaseHistorySkeleton = () => {
  return (
    <VStack
      alignItems={'start'}
      shadow={'sm'}
      borderWidth="1px"
      borderColor={'gray.400'}
      borderRadius={'xl'}
      p={[4, 6, 6]}
      gap={4}
      flex={1}
      maxW={['100%', '100%', '100%', '100%', '30rem']}
      w={'100%'}
    >
      <VStack alignItems={'flex-start'} w={'100%'} gap={2}>
        <Skeleton height="24px" width="150px" />
        <HStack justifyContent={'space-between'} w={'100%'} flexWrap="wrap">
          <Skeleton height="16px" width="180px" />
        </HStack>
      </VStack>
      <Divider borderColor="gray.400" />
      <List
        spacing={3}
        styleType={'none'}
        w={'100%'}
        border="1px"
        borderColor={'gray.300'}
        rounded={'lg'}
        p={4}
      >
        {[...Array(5)].map((_, index) => (
          <ListItem
            key={index}
            borderBottom="1px"
            borderColor={'gray.300'}
            w={'100%'}
            pb={4}
            _last={{
              border: 0,
              pb: 0,
            }}
          >
            <HStack justifyContent={'space-between'} alignItems={'center'}>
              <VStack gap={2} alignItems={'flex-start'}>
                <Skeleton height="16px" width="100px" />
                <Skeleton height="12px" width="60px" />
                <Skeleton height="16px" width="80px" />
              </VStack>
              <HStack gap={3}>
                <VStack gap={2} alignItems={'flex-end'}>
                  <Skeleton height="20px" width="60px" />
                  <Skeleton height="16px" width="50px" />
                </VStack>
                <SkeletonCircle size="5" />
              </HStack>
            </HStack>
          </ListItem>
        ))}
      </List>
    </VStack>
  );
};

export default PurchaseHistorySkeleton;
