//final BS report calculation Assets/Liabilities

import { BSReportCalculation } from 'src/Redux/OcrReports/state';

// Update totals for BSReportCalculation rows
export function updateBSReportCalculations(
  ocrReportData: Record<string, any>[]
) {
  // Find indices of rows for TotalCurrentAssets, TotalFixedAssets, and TotalAssets
  const totalCurrentAssetsIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalCurrentAssets
  );
  const totalFixedAssetsIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalFixedAssets
  );
  const totalAssetsIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalAssets
  );
  //find index for TotalCurrentLiabilities, TotalLongTermLiabilities, TotalLiabilities, TotalAccountsPayable

  const totalCurrentLiabilitiesIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalCurrentLiabilities
  );
  const totalLongTermLiabilitiesIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalLongTermLiabilities
  );
  const totalLiabilitiesIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalLiabilities
  );
  const totalAccountsPayableIndex = ocrReportData.findIndex((row) =>
    row.Label.includes(BSReportCalculation.TotalAccountsPayable)
  );

  //find index for TotalLiabilitiesAndEquity , TotalEquity
  const totalLiabilitiesAndEquityIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalLiabilitiesAndEquity
  );
  const TotalEquityIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalEquity
  );

  // Calculate TotalCurrentAssets
  if (totalCurrentAssetsIndex !== -1) {
    const totalCurrentAssetsSum = calculateSumAbove(
      ocrReportData,
      totalCurrentAssetsIndex,
      'total'
    );
    // ocrReportData[totalCurrentAssetsIndex].Total = totalCurrentAssetsSum;
    ocrReportData[totalCurrentAssetsIndex] = {
      ...ocrReportData[totalCurrentAssetsIndex],
      Total: Number(parseFloat(totalCurrentAssetsSum.toString()).toFixed(2)),
    };
  }

  // Calculate TotalFixedAssets
  if (totalFixedAssetsIndex !== -1 && totalCurrentAssetsIndex !== -1) {
    const totalFixedAssetsSum = calculateSumBetween(
      ocrReportData,
      totalCurrentAssetsIndex + 1,
      totalFixedAssetsIndex,
      'total'
    );
    // ocrReportData[totalFixedAssetsIndex].Total = totalFixedAssetsSum;
    ocrReportData[totalFixedAssetsIndex] = {
      ...ocrReportData[totalFixedAssetsIndex],
      Total: Number(parseFloat(totalFixedAssetsSum.toString()).toFixed(2)),
    };
  }

  // Calculate TotalAssets
  if (
    ![
      totalAssetsIndex,
      totalCurrentAssetsIndex,
      totalFixedAssetsIndex,
    ].includes(-1)
  ) {
    const totalAssetsSum =
      (ocrReportData[totalCurrentAssetsIndex].Total || 0) +
      (ocrReportData[totalFixedAssetsIndex].Total || 0);
    // ocrReportData[totalAssetsIndex].Total = totalAssetsSum;
    ocrReportData[totalAssetsIndex] = {
      ...ocrReportData[totalAssetsIndex],
      Total: Number(parseFloat(totalAssetsSum.toString()).toFixed(2)),
    };
  }

  //Calculate TotalCurrentLiabilities
  if (totalCurrentLiabilitiesIndex !== -1 && totalAccountsPayableIndex !== -1) {
    const TotalCurrentLiabilitiesSum = calculateSumBetween(
      ocrReportData,
      totalAccountsPayableIndex,
      totalCurrentLiabilitiesIndex,
      'total'
    );

    ocrReportData[totalCurrentLiabilitiesIndex] = {
      ...ocrReportData[totalCurrentLiabilitiesIndex],
      Total: Number(
        parseFloat(TotalCurrentLiabilitiesSum.toString()).toFixed(2)
      ),
    };
  }

  //Calculate TotalLongTermLiabilities
  if (
    totalCurrentLiabilitiesIndex !== -1 &&
    totalLongTermLiabilitiesIndex !== -1
  ) {
    const TotalLongTermLiabilitiesSum = calculateSumBetween(
      ocrReportData,
      totalCurrentLiabilitiesIndex + 1,
      totalLongTermLiabilitiesIndex,
      'total'
    );
    ocrReportData[totalLongTermLiabilitiesIndex] = {
      ...ocrReportData[totalLongTermLiabilitiesIndex],
      Total: Number(
        parseFloat(TotalLongTermLiabilitiesSum.toString()).toFixed(2)
      ),
    };
  }

  // Calculate TotalLiabilities
  if (
    totalLiabilitiesIndex !== -1 &&
    totalCurrentLiabilitiesIndex !== -1 &&
    totalLongTermLiabilitiesIndex !== -1
  ) {
    const TotalLiabilitiesSum =
      (ocrReportData[totalCurrentLiabilitiesIndex].Total || 0) +
      (ocrReportData[totalLongTermLiabilitiesIndex].Total || 0);
    // ocrReportData[totalAssetsIndex].Total = totalAssetsSum;
    ocrReportData[totalLiabilitiesIndex] = {
      ...ocrReportData[totalLiabilitiesIndex],
      Total: Number(parseFloat(TotalLiabilitiesSum.toString()).toFixed(2)),
    };
  }

  // Calculate TotalLiabilitiesAndEquity
  if (
    totalLiabilitiesAndEquityIndex !== -1 &&
    totalCurrentLiabilitiesIndex !== -1 &&
    totalLongTermLiabilitiesIndex !== -1
  ) {
    const TotalLiabilitiesAndEquitySum =
      (ocrReportData[totalLiabilitiesIndex].Total || 0) +
      (ocrReportData[TotalEquityIndex].Total || 0);
    // ocrReportData[totalAssetsIndex].Total = totalAssetsSum;
    ocrReportData[totalLiabilitiesAndEquityIndex] = {
      ...ocrReportData[totalLiabilitiesAndEquityIndex],
      Total: Number(
        parseFloat(TotalLiabilitiesAndEquitySum.toString()).toFixed(2)
      ),
    };
  }

  return ocrReportData;
}

// Helper function to calculate the sum of 'Total' values above a specified index
function calculateSumAbove(
  data: Record<string, any>[],
  endIndex: number,
  labelContains: string
) {
  return data.slice(0, endIndex).reduce((acc, row) => {
    if (row.Label?.toLowerCase()?.includes(labelContains)) {
      const totalValue = parseFloat(row.Total) || 0;
      return acc + totalValue;
    }
    return acc;
  }, 0);
}

// Helper function to calculate the sum of 'Total' values between two indices (inclusive)
function calculateSumBetween(
  data: Record<string, any>[],
  startIndex: number,
  endIndex: number,
  labelContains: string
) {
  return data.slice(startIndex, endIndex).reduce((acc, row) => {
    if (row.Label?.toLowerCase()?.includes(labelContains)) {
      const totalValue = parseFloat(row.Total) || 0;
      return acc + totalValue;
    }
    return acc;
  }, 0);
}

//update record calculation function
// Function to update 'Total' fields for consecutive "Total" rows after editing a row
export function updateConsecutiveTotals(data: Record<string, any>[]) {
  // Loop through all rows to find consecutive "Total" rows
  let priorTotalRowIndex = -1;

  for (let i = 0; i < data.length; i++) {
    if (data[i].Label.toLowerCase()?.includes('total')) {
      if (priorTotalRowIndex === -1) {
        // Case for the first "Total" row: set startIndex from 0 to prior "Total" row
        const startIndex = 0;
        const endIndex = i - 1;

        const sum =
          startIndex <= endIndex
            ? calculateSum(data, startIndex, i - 1)
            : data[i].Total;
        // data[i].Total = sum;
        data[i] = {
          ...data[i],
          Total: Number(parseFloat(sum.toString()).toFixed(2)),
        };
      } else {
        // Case for subsequent "Total" rows
        const startIndex = priorTotalRowIndex + 1;
        const endIndex = i - 1;

        const sum =
          startIndex <= endIndex
            ? calculateSum(data, startIndex, i - 1)
            : data[i].Total;
        // data[i].Total = sum;
        data[i] = {
          ...data[i],
          Total: Number(parseFloat(sum.toString()).toFixed(2)),
        };
      }
      // Update priorTotalRowIndex to the current index for the next iteration
      priorTotalRowIndex = i;
    }
  }
  return data;
}

// Helper function to calculate sum of 'Total' values from startIndex to endIndex (inclusive)
function calculateSum(
  data: Record<string, any>[],
  startIndex: number,
  endIndex: number
) {
  return data.slice(startIndex, endIndex + 1).reduce((acc, row) => {
    // console.log('startIndex', startIndex, endIndex + 1, data);

    const totalValue = parseFloat(row.Total) || 0; // Convert to number, default to 0 if null/undefined
    return acc + totalValue;
  }, 0);
}

//add record calculation function
const updateTotalCalculation = (
  data: Record<string, any>[],
  selectedRow: {
    rowIndex: number;
    rowData: Record<string, any>;
  }
) => {
  const selectedRowIndex =
    data?.findIndex((item) => item?.id === selectedRow?.rowData?.id) ?? 0;
  console.log('selectedRowIndex', selectedRowIndex);

  // Step 2: Find the nearest prior "Total" row
  let priorTotalRowIndex = -1;
  for (let i = selectedRowIndex - 1; i >= 0; i--) {
    if (data[i].Label.includes('Total')) {
      priorTotalRowIndex = i;
      break;
    }
  }

  // Step 3: Define startIndex for summing
  const startIndex = priorTotalRowIndex !== -1 ? priorTotalRowIndex + 1 : 0;

  // Step 4: Calculate the sum of "Total" values from startIndex to selectedRowIndex - 1
  const sum = data.slice(startIndex, selectedRowIndex).reduce((acc, row) => {
    const totalValue = parseFloat(row?.Total) || 0; // Parse to number or default to 0 if null/undefined
    return acc + totalValue;
  }, 0);

  // Step 5: Update the Total value for the row at selectedRow?.rowIndex
  console.log('sum', sum);

  // data[selectedRowIndex].Total = sum;
  data[selectedRowIndex] = {
    ...data[selectedRowIndex],
    Total: Number(parseFloat(sum.toString()).toFixed(2)),
  };

  return data;
};
