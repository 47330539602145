import { Box, Button, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import { ActionIcon, Progress, Title, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import {
  IconDownload,
  IconEdit,
  IconRefresh,
  IconSquarePlus,
  IconTrash,
} from '@tabler/icons-react';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_EditActionButtons,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_TableOptions,
  MRT_ToolbarInternalButtons,
  useMantineReactTable,
} from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { profitLossDiffBgRowList } from 'src/data/reports/profitLossReport';
import { useAppToast } from 'src/hooks';
import {
  getProfitLossData,
  refreshBSPLReport,
} from 'src/Redux/Broker/Reports/APAgingSummary/slice';
import { TFilterAccountPayable } from 'src/Redux/Broker/Reports/APAgingSummary/state';
import {
  AddOcrReportRecord,
  updateOcrReportRecord,
} from 'src/Redux/OcrReports/slice';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { getRandomUUID } from 'src/utils/helpers';
import { getCSVConfigByReportName } from 'src/utils/reports/generateAllReports';
import { formatNumber, validateRequired } from '../MantineTable';
import DeleteBSReportRecordModal from './components/DeleteBSReportRecordModal';
import { updateConsecutiveTotals } from './utils';

interface APTable {
  tableData: Record<string, any>[] | undefined;
  filterType: TFilterAccountPayable;
  isInitialLoading: boolean;
  selectApplicantData: Record<string, any>;
  applicantId: string;
  isEditable: boolean;
  ocrReportId: number | null;
  ocrUpdateLoader: boolean;
  setIsReportDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

interface TProfitLossRow {
  AccountPayableId: number;
  Amount: number | null;
  Value: string;
  Label?: string;
  id?: string;
  Total?: number | null;
}

const ProfitLossTable = ({
  tableData,
  filterType,
  isInitialLoading,
  selectApplicantData,
  applicantId,
  isEditable,
  ocrReportId,
  ocrUpdateLoader,
  setIsReportDelete,
}: APTable) => {
  const accountPayableReport = useAppSelector((state) => state.accountPayable);
  const ocrReportSlice = useAppSelector((state) => state.ocrReportSlice);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedRow, setSelectedRow] = useState<{
    rowIndex: number;
    rowData: Record<string, any>;
  } | null>(null);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  useEffect(() => {
    switch (accountPayableReport.status) {
      case 'succeed': {
        if (accountPayableReport.type === 'REFRESH_PL_REPORT') {
          setIsRefresh(false);
          dispatch(
            getProfitLossData({
              applicantId: Number(applicantId),
              filter: filterType,
            })
          );
        }
        return;
      }
      case 'loading': {
        if (accountPayableReport.type === 'REFRESH_PL_REPORT') {
          setIsRefresh(true);
        }
        return;
      }
      case 'failed': {
        if (accountPayableReport.type === 'REFRESH_PL_REPORT') {
          toast({
            title: accountPayableReport.error,
            status: 'error',
          });
          setIsRefresh(false);
        }
        return;
      }
    }
  }, [accountPayableReport.status]);

  const columns = useMemo<MRT_ColumnDef<TProfitLossRow>[]>(() => {
    if (tableData && tableData[0]) {
      return Object.keys(tableData[0])
        .filter((elm) => !['id'].includes(elm))
        .map((key) => {
          return {
            accessorKey: key,
            header: !['title', 'Label'].includes(key)
              ? key.charAt(0).toUpperCase() + key.slice(1)
              : 'Name',
            Cell: ({ cell }: { cell: any }) => {
              // const cellValue =
              //   !!cell.getValue() &&
              //   !['title', 'Label'].includes(key) &&
              //   typeof cell?.getValue() === 'string'
              //     ? cell?.getValue()?.replace(/,/g, '')
              //     : cell?.getValue();
              return (
                <>
                  {!!cell.getValue() && !['title', 'Label'].includes(key)
                    ? formatNumber(Number(cell?.getValue()))
                    : cell.getValue()}
                </>
              );
            },
            mantineEditTextInputProps: () => ({
              // onChange: (event: any) => {
              //   console.log('cell', cell, event?.target?.value, column, row);
              // },
              type: !['title', 'Label'].includes(key) ? 'number' : 'text',
              variant: 'default',
              // placeholder: '',
              error: !['title', 'Label'].includes(key)
                ? validationErrors?.Total
                : validationErrors?.Label,
              onFocus: () => {
                const keyName = key.charAt(0).toUpperCase() + key.slice(1);
                setValidationErrors({
                  ...validationErrors,
                  [keyName]: undefined,
                });
              },
            }),
          };
        });
    }

    return [];
  }, [filterType, tableData, validationErrors]);

  // const columns = useMemo<MRT_ColumnDef<TProfitLossRow>[]>(
  //   () => [
  //     {
  //       accessorKey: 'title',
  //       header: 'Name',
  //     },
  //     {
  //       accessorKey: 'amount',
  //       header: 'Amount',
  //       Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
  //     },
  //   ],
  //   [filterType]
  // );

  const checkTotalInclude = (row: Record<string, any>) => {
    return row?.[isEditable ? 'Label' : 'title']
      ?.toLowerCase()
      .includes('total');
  };
  const checkRowName = (row: Record<string, any>) => {
    return profitLossDiffBgRowList.includes(row?.title?.toLowerCase());
  };
  const checkRowAmount = (row: Record<string, any>) => {
    return !row?.amount;
  };

  const getTableBodyRowProps = ({ row }: { row: MRT_Row<TProfitLossRow> }) => {
    if (tableData) {
      const isLastRow = row.index === tableData?.length - 1;
      const isTotal = checkTotalInclude(row?.original);
      const diffBgColor =
        checkRowName(row?.original) && checkRowAmount(row?.original);
      return {
        // className: !!diffBgColor ? 'profit_loss_report' : '',
        style: {
          fontWeight: isLastRow || isTotal ? 'bold' : 'normal',
          // background: !!diffBgColor ? '#11468433' : 'transparent',
          // '&:hover': {
          //   background: !!diffBgColor ? '#11468433' : 'transparent',
          // },
        },
      };
    } else return {};
  };

  const replaceNullUndefined = (obj: Record<string, any>) => {
    if (typeof obj === 'object' && obj !== null) {
      const newObj: any = Array.isArray(obj) ? [] : {};

      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          // Recursively call replaceNullUndefined for each element
          newObj[i] = replaceNullUndefined(obj[i]);
        }
      } else {
        // If obj is an object, iterate through its properties
        for (let key in obj) {
          // Recursively call replaceNullUndefined for each property value
          newObj[key] = replaceNullUndefined(obj[key]);
        }
      }
      return newObj;
    } else {
      return obj === null || obj === undefined ? '' : obj;
    }
  };

  const handleExportData = (...data: any) => {
    const config = getCSVConfigByReportName({
      type: 'Profit and Loss',
      title: `${accountPayableReport?.data?.reportInfo?.qboCompanyName} \r\n${accountPayableReport?.data?.reportInfo?.reportName} Summary \r\n${filterType} \r\nAccounting data last updated at ${accountPayableReport?.data?.lastUpdateAt ? accountPayableReport?.data?.lastUpdateAt : new Date()} \r\nDate extracted at ${moment(new Date()).format('DD-MM-YYYY hh:mm A')} \r\n`,
      filename: `${selectApplicantData?.companyName} Profit and Loss Report`,
    });

    const csvConfig = mkConfig(config);

    const refineData = tableData?.map((element: Record<string, any>) => {
      return replaceNullUndefined(element);
    });

    if (refineData && refineData.length > 0) {
      const csv = generateCsv(csvConfig)(refineData);
      download(csvConfig)(csv);
    }
  };

  const handleDeleteOcrRecord = async () => {
    const { id } = selectedRow?.rowData!;

    if (!!ocrReportId && !!id && !!tableData?.length) {
      const removeOcrRecord = tableData.filter((el) => el.id !== id);
      console.log('removeOcrRecord', removeOcrRecord);
      const updatedOcrReportData = updateConsecutiveTotals(removeOcrRecord);
      setIsReportDelete(true);
      // await dispatch(deleteOcrReportRecord({ ocrReportId, recordId: id }));
      await dispatch(
        updateOcrReportRecord({
          id: ocrReportId,
          ocrReportData: updatedOcrReportData,
        })
      );
    }
    setSelectedRow(null);
    close();
  };

  const openDeleteConfirmModal = (row: MRT_Row<TProfitLossRow>) => {
    // console.log('delete-row', row);
    setSelectedRow({ rowIndex: row?.index, rowData: row?.original });
    open();
    // return modals.openConfirmModal({
    //   title: `DELETE`,
    //   children: (
    //     <Text>
    //       Are you sure you want to delete {row?.original?.Label}? This action
    //       cannot be undone.
    //     </Text>
    //   ),
    //   labels: { confirm: 'Delete', cancel: 'Cancel' },
    //   confirmProps: { color: 'red' },
    //   onConfirm: () => {},
    // });
  };

  const handleRowSave: MRT_TableOptions<TProfitLossRow>['onEditingRowSave'] =
    async ({ table, row, values }) => {
      console.log('onSave', table, row, values, tableData);
      const newValidationErrors = validateUser(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      if (!!tableData?.length && !!ocrReportId) {
        const updatedTData = tableData?.map((el, index) =>
          index === row.index ? { ...values, id: row?.original?.id } : el
        );
        const updatedOcrReportData = updateConsecutiveTotals(updatedTData);
        console.log('edit-updatedOcrReportData', updatedOcrReportData);
        dispatch(
          updateOcrReportRecord({
            id: ocrReportId,
            ocrReportData: updatedOcrReportData,
          })
        );
      }
      table.setEditingRow(null); //exit editing mode
    };

  //add record function
  const addRecordBeforeIndex = (
    ocrReportData: Record<string, any>[],
    newRecord: TProfitLossRow,
    index: number
  ) => {
    // Step 1: Insert the new record at selectedRow?.rowIndex
    const updatedData = [
      ...ocrReportData.slice(0, index),
      newRecord,
      ...ocrReportData.slice(index),
    ];
    // console.log('updatedData', updatedData, newRecord);
    return updatedData;
  };

  const handleRowAdd: MRT_TableOptions<TProfitLossRow>['onCreatingRowSave'] =
    async ({ exitCreatingMode, row, table, values }) => {
      console.log('onCreateSave', values, row, table);
      const newValidationErrors = validateUser(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      if (!!tableData?.length && !!ocrReportId && !!selectedRow) {
        const updatedOcrReportData = addRecordBeforeIndex(
          tableData,
          { ...values, Total: Number(values?.Total), id: getRandomUUID() },
          selectedRow?.rowIndex
        );
        console.log('updatedOcrReportData', updatedOcrReportData);
        const updateCalculation = updateConsecutiveTotals(updatedOcrReportData);
        console.log('updateCalculation', updateCalculation);
        await dispatch(
          AddOcrReportRecord({
            id: ocrReportId,
            ocrReportData: updateCalculation,
          })
        );
      }
      setSelectedRow(null);
      exitCreatingMode();
    };

  const table = useMantineReactTable({
    columns: columns as any,
    data: (tableData ?? []) as any,
    mantineCreateRowModalProps: {
      className: 'progress-capital',
    },
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: isEditable,
    getRowId: (row, index) => '' + index,
    enableDensityToggle: false,
    initialState: {
      density: 'xs',
      columnOrder: isEditable
        ? ['mrt-row-actions', 'Label', 'Total']
        : ['title', 'amount'],
    },
    enableStickyFooter: true,
    defaultColumn: { minSize: 40, maxSize: 70, size: 40 },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineToolbarAlertBannerProps: false
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        // overflow: isInitialLoading ? 'hidden' : 'auto',
        maxHeight: 'unset !important',
        overflow: 'visible !important',
      },
      className: 'capital-assets-table',
      // className: 'legal-form-table',
    },
    mantineTableHeadCellProps: {
      className: 'custom-column',
    },
    mantinePaperProps: {
      sx: {
        overflow: 'visible',
      },
    },
    mantineTableHeadProps: {
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
      },
    },
    mantineTableBodyCellProps: (props: any) => {
      console.log('props', props);
      const diffBgColor =
        profitLossDiffBgRowList.includes(
          props?.row?.original?.[isEditable ? 'Label' : 'title']?.toLowerCase()
        ) && !props?.row?.original?.[isEditable ? 'Total' : 'amount'];
      return {
        className: !!diffBgColor ? 'profit_loss_report' : '',
        sx: {
          background: !!diffBgColor ? '#11468433' : 'transparent',
        },
      };
    },
    mantineTableBodyRowProps: getTableBodyRowProps,
    enableColumnPinning: true,
    enablePagination: false,
    positionPagination: 'none',
    enableStickyHeader: false,
    enableBottomToolbar: false,
    onCreatingRowCancel: () => {
      setValidationErrors({});
      setSelectedRow(null);
    },
    onEditingRowCancel: () => {
      setValidationErrors({});
    },
    onEditingRowSave: handleRowSave,
    onCreatingRowSave: handleRowAdd,
    renderCreateRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Add Data</Title>
        <Grid gridTemplateColumns={'1fr'} gap={4}>
          {internalEditComponents}
        </Grid>
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderEditRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Edit Data</Title>
        <Grid gridTemplateColumns={'1fr'} gap={4}>
          {internalEditComponents}
        </Grid>
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 2, //make actions column wider
        minSize: 2,
        maxSize: 2,
      },
    },
    renderRowActions: ({ row }) => {
      console.log('row', row);

      const isLastRow = row.index === tableData?.length! - 1;
      const isTotal = row?.original?.Label?.toLowerCase().includes('total');
      const nextRowData = tableData?.[row.index + 1]?.Total;
      const isPreviousTotalRow =
        tableData?.[row.index - 1]?.Label?.toLowerCase().includes('total');
      const diffBgColor =
        profitLossDiffBgRowList.includes(
          row?.original?.Label?.toLowerCase()!
        ) && !row?.original?.Total;

      if (!isLastRow && !isTotal && !diffBgColor) {
        return (
          <Flex gap="md">
            <Tooltip label="Edit" position="right">
              <ActionIcon
                onClick={() => {
                  table.setEditingRow(row);
                }}
                size={'sm'}
              >
                <IconEdit />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Delete" position="right">
              <ActionIcon
                color="red"
                onClick={() => openDeleteConfirmModal(row)}
                size={'sm'}
              >
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          </Flex>
        );
      } else if (!isLastRow && isTotal && !isPreviousTotalRow) {
        return (
          <Tooltip label="Add" position="right">
            <ActionIcon
              onClick={() => {
                setSelectedRow({
                  rowIndex: row?.index,
                  rowData: row?.original,
                });
                table.setCreatingRow(true);
              }}
              size={'sm'}
            >
              <IconSquarePlus />
            </ActionIcon>
          </Tooltip>
        );
      }
    },
    mantineProgressProps: ({ isTopToolbar }) => ({
      color: 'orange',
      variant: '', //if you want to show exact progress value
      sx: {
        display: isTopToolbar ? 'block' : 'none', //hide bottom progress bar
      },
    }),
    renderTopToolbar: ({ table }) => (
      <Flex direction={'column'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          {!isEditable ? (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                color="lightblue"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={(...data) => handleExportData(data)}
                leftIcon={<IconDownload />}
                variant="filled"
                className="primary-btn"
              >
                Export All Data
              </Button>
              {accountPayableReport?.data && (
                <Flex flexDirection={'column'}>
                  <Flex alignItems={'flex-start'} gap={1}>
                    <ActionIcon
                      style={{ color: '#114684' }}
                      onClick={() =>
                        dispatch(
                          refreshBSPLReport({
                            applicantId: applicantId,
                            filter: filterType,
                            reportName: 'Profit and Loss',
                          })
                        )
                      }
                      size={'sm'}
                    >
                      <Tooltip
                        label={'Refresh Data'}
                        bg="gray.300"
                        color="black"
                        position="right"
                      >
                        <IconRefresh />
                      </Tooltip>
                    </ActionIcon>
                    <Text fontSize={'smaller'}>
                      Accounting data last updated at
                    </Text>
                    <Text fontSize={'smaller'}>
                      {accountPayableReport?.data?.lastUpdateAt}
                    </Text>
                  </Flex>
                  <Text>
                    <span>Profit and Loss as of&nbsp;</span>
                    {/* <Tooltip
                      label={'Start Date'}
                      bg="gray.300"
                      color="black"
                      position="top"
                    >
                      <span style={{ cursor: 'pointer' }}>
                        {`${moment(accountPayableReport?.data?.reportInfo?.startPeriod).format('MMMM DD, YYYY')} -`}
                        &nbsp;
                      </span>
                    </Tooltip> */}
                    <Tooltip
                      label={'End Date'}
                      bg="gray.300"
                      color="black"
                      position="top"
                    >
                      <span
                        style={{ cursor: 'pointer' }}
                      >{`${moment(accountPayableReport?.data?.reportInfo?.endPeriod).format('MMMM DD, YYYY')}`}</span>
                    </Tooltip>
                  </Text>
                </Flex>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              {!isInitialLoading && (
                <Text>
                  <span>Profit and Loss as of&nbsp;</span>
                  <Tooltip
                    label={'Updated Date'}
                    bg="gray.300"
                    color="black"
                    position="top"
                  >
                    <span
                      style={{ cursor: 'pointer' }}
                    >{`${moment(ocrReportSlice?.ocrReportList?.[0]?.updatedInfo).format('MMMM DD, YYYY')}`}</span>
                  </Tooltip>
                </Text>
              )}
            </Box>
          )}
          <Flex py={1} alignItems={'center'}>
            <Flex alignItems={'center'} gap={4} p={3}>
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToolbarInternalButtons table={table} />
            </Flex>
            {/* <Divider
              size="md"
              orientation="vertical"
              h={40}
              style={{ alignSelf: 'center' }}
            />
            <Box className="pagination-wrapper">
              <MRT_TablePagination position="top" table={table} />
            </Box> */}
          </Flex>
        </Flex>
        {isRefresh && <Progress value={100} animate={true} />}
      </Flex>
    ),
    onIsFullScreenChange: (value) => setIsFullScreen(value),
    state: {
      isFullScreen: isFullScreen,
      isLoading: isInitialLoading || isRefresh,
      isSaving: ocrUpdateLoader,
    },
  });
  return (
    <>
      <ModalsProvider>
        <Box>
          <MantineReactTable table={table} key={'profit_loss_report'} />
        </Box>
      </ModalsProvider>
      <DeleteBSReportRecordModal
        isOpen={opened}
        onClose={close}
        onConfirm={handleDeleteOcrRecord}
        ocrUpdateLoader={ocrUpdateLoader}
        selectedRow={selectedRow}
      />
    </>
  );
};

export default ProfitLossTable;

const checkTotalInclude = (value: string) => {
  return value?.toLowerCase().includes('total');
};

function validateUser(user: TProfitLossRow) {
  return {
    Label: !validateRequired(user?.Label!)
      ? 'Name is Required'
      : checkTotalInclude(user?.Label!)
        ? 'Name cannot contain the word total'
        : '',
    // Total: !validateRequired(user?.Total?.toString()!)
    //   ? 'Total is Required'
    //   : '',
  };
}
