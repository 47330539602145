import { Box, Flex, Select, Text } from '@chakra-ui/react';
import { ActionIcon, Divider, Loader, Progress, Tooltip } from '@mantine/core';
import { modals, ModalsProvider } from '@mantine/modals';
import { IconEye, IconTrash } from '@tabler/icons-react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  useMantineReactTable,
} from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { FaRegShareSquare } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAppToast } from 'src/hooks';
import { getApplicationsByApplicant } from 'src/Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { ApplicantBrokerage } from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import {
  deleteOCRReport,
  getOcrScanReports,
  removeOcrReportSliceType,
} from 'src/Redux/OcrReports/slice';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';

export interface reportInfo {
  type: string;
  timeFrame: string;
  reportName: string;
  fiscalYearStartPeriodDate?: string;
  fiscalYearEndPeriodDate?: string;
}

export interface ocrReportTable {
  reportType: string;
  description: string;
  updatedAt: string;
  id: string;
  ocrReportData: Record<string, any>[];
  applicantId: number;
  applicationId: number;
  reportInfo: reportInfo;
  applicantName: string;
  fiscalYear: string;
}

const OCRReports = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useAppToast();
  const [applicantId, setApplicantId] = useState<string>('');
  const [applicationId, setApplicationId] = useState<string>('');
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [applicationOptions, setApplicationOptions] = useState<
    Record<string, any>[]
  >([]);
  const [loader, setLoader] = useState<{
    applicantLoader: boolean;
    applicationLoader: boolean;
  }>({
    applicantLoader: false,
    applicationLoader: false,
  });
  const [tableContentLoader, setTableContentLoader] = useState<boolean>(false);
  const [tableData, setTableData] = useState<ocrReportTable[]>([]);

  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );
  const createApplicationForm = useAppSelector(
    (state) => state.createApplicationFormSlice
  );
  const ocrReportSlice = useAppSelector((state) => state.ocrReportSlice);
  const userType = localStorage.getItem('userType');
  const userID = localStorage.getItem('userID');

  useEffect(() => {
    if (userType === 'broker') {
      if (!!brokerageDashboard.applicantsByBrokrageData?.length) {
        setApplicantOptions(brokerageDashboard.applicantsByBrokrageData);
      } else {
        dispatch(ApplicantBrokerage());
      }
      getOCRReportList();
    } else if (userType === 'applicant' && userID) {
      setApplicantId(userID);
      // dispatch(fetchApplicantUserData());
    }

    return () => {
      dispatch(removeOcrReportSliceType({}));
    };
  }, []);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData } = brokerageDashboard;
    switch (status) {
      case 'loading':
        {
          if (type === 'GET_APPLICANT_BY_BROKERAGE') {
            setLoader({ ...loader, applicantLoader: true });
          }
        }
        break;
      case 'succeed':
        {
          if (
            type === 'GET_APPLICANT_BY_BROKERAGE' &&
            !!applicantsByBrokrageData?.length
          ) {
            setApplicantOptions(applicantsByBrokrageData);
            setLoader({ ...loader, applicantLoader: false });
          }
        }
        break;
      case 'failed':
        {
          if (type === 'GET_APPLICANT_BY_BROKERAGE') {
            setLoader({ ...loader, applicantLoader: false });
          }
        }
        break;
      default:
        break;
    }
  }, [brokerageDashboard.status]);

  useEffect(() => {
    if (!!applicantId) {
      dispatch(
        getApplicationsByApplicant({
          applicantId: Number(applicantId),
        })
      );
    }
    getOCRReportList();
  }, [applicantId]);

  useEffect(() => {
    const { status, type, applications } = createApplicationForm;
    switch (status) {
      case 'loading':
        {
          if (type === 'GET_APPLICATIONS_BY_APPLICANT_ID') {
            setLoader({ ...loader, applicationLoader: true });
          }
        }
        break;
      case 'succeed':
        {
          if (
            type === 'GET_APPLICATIONS_BY_APPLICANT_ID' &&
            !!applications?.length
          ) {
            setApplicationOptions(applications);
            setLoader({ ...loader, applicationLoader: false });
          }
        }
        break;
      case 'failed':
        {
          if (type === 'GET_APPLICATIONS_BY_APPLICANT_ID') {
            setLoader({ ...loader, applicationLoader: false });
          }
        }
        break;
      default:
        break;
    }
  }, [createApplicationForm.status]);

  useEffect(() => {
    getOCRReportList();
  }, [applicationId]);

  useEffect(() => {
    const { status, type, ocrReportList, msg } = ocrReportSlice;
    console.log('ocr-loader', type, status);

    switch (status) {
      case 'loading':
        {
          if (type === 'GET_OCR_REPORT_LIST') {
            setTableContentLoader(true);
          }
        }
        break;
      case 'succeed':
        {
          if (type === 'GET_OCR_REPORT_LIST' && ocrReportList) {
            const ocrTData = getOCRReportTableData(ocrReportList);
            setTableData(ocrTData);
            setTableContentLoader(false);
          }
        }
        if (type === 'DELETE_OCR') {
          toast({
            title: msg,
            status: 'success',
          });
          getOCRReportList();
        }
        break;
      case 'failed':
        {
          if (type === 'GET_OCR_REPORT_LIST') {
            // setTableContentLoader(false);
            setTableData([]);
          }
        }
        if (type === 'DELETE_OCR') {
          toast({
            status: 'error',
            title: msg,
          });
        }
        break;
      default:
        break;
    }
  }, [ocrReportSlice.status]);

  const getOCRReportList = () => {
    if (!!applicantId && !!applicationId) {
      dispatch(
        getOcrScanReports({
          applicantId: Number(applicantId),
          applicationId: Number(applicationId),
        })
      );
    } else if (!!applicantId) {
      dispatch(
        getOcrScanReports({
          applicantId: Number(applicantId),
        })
      );
    } else {
      dispatch(getOcrScanReports({}));
    }
  };

  const getFiscalYearDate = (reportInfo: reportInfo) => {
    if (
      !!reportInfo?.fiscalYearStartPeriodDate &&
      !!reportInfo?.fiscalYearEndPeriodDate
    ) {
      return `${moment(reportInfo?.fiscalYearStartPeriodDate).format('MMM DD, YYYY')} - ${moment(reportInfo?.fiscalYearEndPeriodDate).format('MMM DD, YYYY')}`;
      // return `${reportInfo?.fiscalYearStartPeriodDate} - ${reportInfo?.fiscalYearEndPeriodDate}`;
    } else {
      return '01/01/2023 - 01/01/2024';
    }
  };

  const getOCRReportTableData = (
    ocrData: Record<string, any>[]
  ): ocrReportTable[] => {
    const data = ocrData?.map((report) => ({
      reportType: `${report.reportInfo.type + ' -'} ${report.reportInfo.reportName}`,
      description: report.reportInfo.timeFrame,
      updatedAt: report.updatedInfo,
      id: report.id,
      ocrReportData: report.ocrReportData,
      applicantId: report.applicantId,
      applicationId: report.applicationId,
      reportInfo: report.reportInfo,
      applicantName: report.applicant.companyName,
      fiscalYear: getFiscalYearDate(report?.reportInfo),
    }));
    return data;
  };

  const columns = useMemo<MRT_ColumnDef<ocrReportTable>[]>(
    () => [
      {
        accessorKey: 'applicantName',
        header: 'Applicant Name',
        size: 20,
      },
      {
        accessorKey: 'fiscalYear',
        header: 'Year',
        size: 20,
      },
      {
        accessorKey: 'reportType',
        header: 'Type',
        size: 20,
        // Cell: ({ cell, row }: { cell: any; row: any }) => {
        //   const { reportInfo } = row?.original;
        //   if (
        //     Object.values(ocrCombinedReportList)
        //       .map((el) => el.toString())
        //       .includes(reportInfo?.reportName)
        //   ) {
        //     return (
        //       <Box
        //         display={'flex'}
        //         gap={2}
        //         flexWrap={'nowrap'}
        //         wordBreak={'break-all'}
        //         alignItems={'flex-start'}
        //       >
        //         <Text>{row?.original?.reportType}</Text>
        //         <Box as="span">
        //           <Tooltip
        //             // fontSize="md"
        //             w={'max-content'}
        //             label={'This report is exported from combined file'}
        //             position={'right'}
        //           >
        //             <Box pt={1}>
        //               <HiMiniInformationCircle size={14} cursor={'pointer'} />
        //             </Box>
        //           </Tooltip>
        //         </Box>
        //       </Box>
        //     );
        //   } else {
        //     return cell.getValue();
        //   }
        // },
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 20,
        maxSize: 20,
      },
      {
        accessorKey: 'updatedAt',
        header: 'Time',
        size: 20,
        maxSize: 20,
        Cell: ({ cell }: { cell: any }) => {
          return moment(cell.getValue()).format('DD-MM-YYYY HH:MM A');
        },
      },
    ],
    []
  );

  const getReportType = (row: MRT_Row<ocrReportTable>) => {
    const { reportName } = row?.original?.reportInfo;
    const isCombinedFile = reportName?.includes('Combined File');
    const reportKey = isCombinedFile
      ? reportName?.split('-')[0]?.trim()
      : reportName;
    switch (reportKey) {
      case 'Account Receivable Aging': {
        return 'account-receivable';
      }
      case 'Account Payable Aging': {
        return 'account-payable';
      }
      case 'Balance Sheet': {
        return 'balance-sheet';
      }
      case 'Profit and Loss': {
        return 'profit-loss';
      }
      default:
        break;
    }
  };

  const openDeleteConfirmModal = (row: MRT_Row<ocrReportTable>) => {
    console.log('delete-row', row);
    return modals.openConfirmModal({
      title: `DELETE`,
      children: (
        <Text>
          Are you sure you want to delete{' '}
          <Box as="span" fontWeight={'600'}>
            {row?.original?.reportType} of year {row?.original?.fiscalYear}
          </Box>
          ? This action cannot be undone.
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: {
        color: 'red',
      },
      onConfirm: async () => {
        const { id } = row?.original;
        if (!!id) {
          await dispatch(deleteOCRReport({ ocrReportId: Number(id) }));
        }
      },
    });
  };

  const table = useMantineReactTable({
    columns: columns,
    data: tableData as any,
    mantineCreateRowModalProps: {
      className: '',
    },
    // layoutMode: 'grid',
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'custom', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    getRowId: (row, index) => row?.id?.toString() ?? '' + index,
    defaultColumn: { minSize: 10, maxSize: 10, size: 10 },
    initialState: {
      density: 'xs',
      columnPinning: { left: ['mrt-row-actions'] },
    },
    // mantineTableProps: {
    //   sx: {
    //     tableLayout: 'fixed',
    //   },
    // },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        // overflow: false ? 'hidden' : 'auto',
        maxHeight: 'unset !important',
        overflow: 'visible !important',
      },
      // className: 'upload-reports-table',
    },
    mantinePaperProps: {
      sx: {
        overflow: 'visible',
      },
    },
    mantineTableHeadProps: {
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
      },
    },
    // mantineTableHeadCellProps: {
    //   className: 'custom-column',
    // },
    mantineTableHeadCellProps: {
      className: 'custom-column',
      sx: {
        '& .mantine-TableHeadCell-Content': {
          justifyContent: 'space-between',
        },
      },
    },
    enableRowActions: true,
    enableTopToolbar: true,
    enablePagination: true,
    positionPagination: 'none',
    enableBottomToolbar: false,
    // enableExpanding: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 2, //make actions column wider
        minSize: 2,
        maxSize: 2,
        mantineTableHeadCellProps: {
          sx: {
            zIndex: 999,
            boxShadow: 'inset 0 0 0 1px lightgray',
          },
        },
      },
    },
    renderRowActions: ({ row }) => (
      <Flex gap={'lg'}>
        <Tooltip label="View" position="right">
          <ActionIcon
            onClick={() => {
              console.log('row', row);
              const { applicantId, applicationId, reportInfo } = row?.original;
              const reportType = getReportType(row);
              dispatch(removeOcrReportSliceType({}));
              const navigateUrl = `/${userType}/report/${reportType}?ocrReportId=${row?.id}&applicantId=${applicantId}&applicationId=${applicationId}&reportType=${reportInfo.type}&reportName=${reportInfo.reportName}&reportTimeFrame=${reportInfo.timeFrame}`;
              navigate(navigateUrl);
            }}
            size={'sm'}
          >
            <IconEye />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete" position="right">
          <ActionIcon
            color="red"
            onClick={() => openDeleteConfirmModal(row)}
            size={'sm'}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>
        {userType === 'broker' && (
          <Tooltip label="Assign to Applicant" position="right">
            <ActionIcon
              // onClick={() => openDeleteConfirmModal(row)}
              size={'sm'}
            >
              <FaRegShareSquare />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
    renderTopToolbar: ({ table }) => {
      console.log('tableData', tableData);

      return (
        <Flex direction={'column'}>
          <Flex
            py={1}
            alignItems={'center'}
            flexWrap={'wrap'}
            justifyContent={'flex-end'}
          >
            <Flex alignItems={'center'} gap={4} p={3}>
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToolbarInternalButtons table={table} />
            </Flex>
            <Divider
              size="md"
              orientation="vertical"
              h={40}
              style={{ alignSelf: 'center' }}
            />
            <Box className="pagination-wrapper">
              <MRT_TablePagination position="top" table={table} />
            </Box>
          </Flex>
          {tableContentLoader && <Progress value={100} animate={true} />}
        </Flex>
      );
    },
    state: {
      showProgressBars: tableContentLoader,
      isLoading: tableContentLoader,
    },
  });

  return (
    <Box>
      <Flex alignItems={'left'} flexDirection={'column'} columnGap={5} py={5}>
        <Flex gap={3} flexWrap={'wrap'}>
          {userType === 'broker' && (
            <Box maxW="max-content">
              <Select
                w="100%"
                onChange={(event) => {
                  setApplicantId(event.target.value);
                  setApplicationId('');
                }}
                value={applicantId}
                name="applicantId"
                borderRadius="5px"
                bg={'#114684'}
                color={'white'}
                textAlign={'center'}
              >
                <option
                  value={''}
                  style={{ color: 'black' }}
                  label="Select Applicant"
                />
                {Array.isArray(applicantOptions) &&
                  applicantOptions?.map((user?: any, index?: number) => (
                    <option
                      style={{ color: 'black' }}
                      key={index}
                      value={user?.applicantId ?? ''}
                      label={user?.companyName ?? ''}
                    />
                  ))}
              </Select>
            </Box>
          )}
          {loader?.applicantLoader && (
            <Flex alignItems={'center'}>
              <Loader size={'sm'} h={'100%'} />
            </Flex>
          )}
          {!!applicantId && (
            <Box maxW="max-content">
              <Select
                w="100%"
                onChange={(event) => {
                  setApplicationId(event.target.value);
                }}
                value={applicationId}
                name="applicationId"
                borderRadius="5px"
                bg={'#114684'}
                color={'white'}
                textAlign={'center'}
              >
                <option
                  value={''}
                  style={{ color: 'black' }}
                  label="Select Application"
                />
                {Array.isArray(applicationOptions) &&
                  applicationOptions?.map((user?: any, index?: number) => (
                    <option
                      style={{ color: 'black' }}
                      key={index}
                      value={user?.applicationId ?? ''}
                      label={user?.applicationName ?? ''}
                    />
                  ))}
              </Select>
            </Box>
          )}
          {loader?.applicationLoader && (
            <Flex alignItems={'center'}>
              <Loader size={'sm'} h={'100%'} />
            </Flex>
          )}
        </Flex>
        <ModalsProvider>
          <Box maxW={'1550px'} w={'100%'} py={10} mx={'auto'}>
            <MantineReactTable table={table} key={'ocr-report-list'} />
          </Box>
        </ModalsProvider>
      </Flex>
    </Box>
  );
};

export default OCRReports;
