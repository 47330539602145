import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { FileInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconFile, IconFileCv } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { HiEye } from 'react-icons/hi2';
import { MdClear } from 'react-icons/md';
import { getFileNames } from 'src/utils/helpers';
import { dynamicConditionCheck } from './AccordianInput';
import LegalFormAccDocumentListing from './LegalFormAccDocumentListing';

const FileList = ({
  name,
  fileList,
  uploadState,
  handleRemoveFile = (
    arg1: {
      link: string;
      linkId: string;
      urlKey: string;
    },
    arg2: string
  ) => {},
  isReadOnly,
}: {
  name: string;
  fileList?: Record<string, any>;
  uploadState: string;
  handleRemoveFile?: (
    arg1: {
      link: string;
      linkId: string;
      urlKey: string;
    },
    arg2: string,
    arg3: string
  ) => void;
  isReadOnly?: boolean;
}) => {
  return (
    <Box
      // border={'1px solid gray'}
      my={2}
    >
      {fileList &&
        fileList?.[uploadState]?.length > 0 &&
        fileList?.[uploadState]?.map((item: any, index: number) => {
          return (
            <Flex
              direction={'row'}
              justifyContent={'space-between'}
              flexWrap={'nowrap'}
              alignItems={'center'}
              // sx={{
              //   borderBottom: '1px solid gray',
              //   '&:last-child': {
              //     borderBottom: 'none',
              //   },
              // }}
              p={1}
              key={index?.toString()}
            >
              <Box
                display={'flex'}
                gap={1}
                flexWrap={'nowrap'}
                alignItems={'center'}
              >
                <IconFile style={{ width: 18, height: 18 }} stroke={1.5} />
                <Text>{item?.urlKey?.split('/').pop()}</Text>
              </Box>
              <Tooltip fontSize="md" label="Remove File" placement={'auto'}>
                <Box
                  cursor={isReadOnly ? 'not-allowed' : 'pointer'}
                  onClick={
                    isReadOnly
                      ? () => {}
                      : () => handleRemoveFile(item, uploadState, name)
                  }
                  aria-readonly={isReadOnly}
                >
                  <MdClear size={18} />
                </Box>
              </Tooltip>
            </Flex>
          );
        })}
    </Box>
  );
};

const AccordianUpload = ({
  uploadState,
  formik,
  name,
  value,
  accordianSectionName,
  isOpen,
  accordianState,
  accordianName,
  accordianParentSectionName,
  fileList,
  setFileList,
  handleRemoveFile,
  isHistoryMode = false,
  isReadOnly,
  currentHistoryData = {},
}: {
  uploadState: string;
  formik: Record<string, any>;
  name: string;
  value: {
    link: string;
    linkId: string;
    urlKey: string;
  }[];
  accordianSectionName: string;
  isOpen: boolean;
  accordianState: any[];
  accordianName: string;
  accordianParentSectionName: string;
  fileList?: Record<string, any>;
  setFileList?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  handleRemoveFile?: (
    arg1: {
      link: string;
      linkId: string;
      urlKey: string;
    },
    arg2: string,
    arg3: string
  ) => void;
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
}) => {
  console.log(
    'fileInput',
    uploadState,
    formik?.values?.[uploadState],
    name,
    value
  );
  const [opened, { open, close }] = useDisclosure(false);
  // const [fileList, setFileList] = useState<Record<string, any>>({});
  const [fileNames, setFileNames] = useState<string | null>(null);
  // const fileNames = value ? getFileNames(value) : null;

  useEffect(() => {
    setFileNames(getFileNames(value as any));
  }, [formik.values]);

  const bgColor = dynamicConditionCheck(
    currentHistoryData,
    name,
    isHistoryMode,
    '#114684',
    'transparent'
  );

  const color = dynamicConditionCheck(
    currentHistoryData,
    name,
    isHistoryMode,
    'white',
    'black'
  );

  console.log('color', color, name, currentHistoryData);

  return (
    <>
      <FileInput
        rightSection={
          fileNames && !isOpen ? (
            <Tooltip fontSize="md" label="View Files" placement={'auto'}>
              <Box
                onClick={() => {
                  open();
                }}
                cursor={'pointer'}
              >
                <HiEye
                  style={{ height: 20, width: 22, color: color }}
                  stroke="1.5"
                />
              </Box>
            </Tooltip>
          ) : (
            <IconFileCv
              style={{ width: 18, height: 18, color: color }}
              stroke={1.5}
            />
          )
        }
        // rightSectionPointerEvents="none"
        styles={{
          input: {
            // maxWidth: '350px',
            border: '1px solid #7f7f7f',
            borderRadius: '5px',
            paddingTop: '6px',
            paddingBottom: '6px',
            cursor: isReadOnly ? 'not-allowed' : 'pointer',
            background: bgColor,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&:hover': {
              border: '1px solid #7f7f7f',
              cursor: isReadOnly ? 'not-allowed' : 'pointer',
            },
            '&:focus': {
              border: '1px solid #7f7f7f',
            },
          },
          placeholder: {
            color: `${color} !important`,
          },
        }}
        // @ts-ignore
        placeholder={fileNames && !isOpen ? fileNames : 'Upload files'}
        // label="Upload files"
        multiple
        onChange={(payload: File[]) => {
          console.log('payload', payload);
          formik?.setFieldValue(uploadState, payload);
          // const formData = new FormData();
          // payload?.forEach((file: File) => {
          //   formData.append('files', file);
          // });
          // console.log('formData', formData);
        }}
        value={formik?.values?.[uploadState] ?? []}
        description="The file format should be - xlsx, xls, csv, pdf, doc, docx"
        // withAsterisk
        variant="filled"
        accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        readOnly={isReadOnly}
      />

      {isOpen && (
        <FileList
          name={name}
          fileList={fileList}
          uploadState={uploadState}
          handleRemoveFile={handleRemoveFile}
          isReadOnly={isReadOnly}
        />
      )}

      {opened && (
        <LegalFormAccDocumentListing
          isOpen={opened}
          onClose={close}
          modalTitle={'Legal Form'}
          formik={formik}
          accordianSectionName={accordianSectionName}
          accordianState={accordianState}
          accordianName={accordianName}
          accordianParentSectionName={accordianParentSectionName}
          uploadState={uploadState}
          name={name}
          isHistoryMode={isHistoryMode}
          isReadOnly={isReadOnly}
          currentHistoryData={currentHistoryData}
        />
      )}
    </>
  );
};

export default AccordianUpload;
