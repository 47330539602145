import { Box, Button, Tooltip as ChakraTooltip, Flex } from '@chakra-ui/react';
import { Divider, Progress } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import axios from 'axios';
import JSZip from 'jszip';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  useMantineReactTable,
} from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { CgFileDocument } from 'react-icons/cg';
import { useZipFromLinks } from 'src/hooks';
import { TZipName } from 'src/Pages/Upload/types';
import { getFileNameFromURL } from 'src/utils/helpers';
import {
  cleanCaseNumberObjects,
  cleanObject,
  findMaxDateTime,
  getFolderName,
  LegalTableDesKeyMapping,
  restructureObject,
} from './utils';

export interface LegalFormUploadFilesTableProps {
  isTableLoading: boolean;
  zipName: TZipName;
  legalFormDocuments: Record<string, any>;
}

export interface LegalFormTable {
  type: string;
  description: string;
  documentType: string;
  id: number;
  files: {
    updatedInfo: string;
    link: string;
    linkId: number | string;
    urlKey: string;
  }[];
}

const LegalFormUploadFilesTable = ({
  isTableLoading,
  zipName,
  legalFormDocuments,
}: LegalFormUploadFilesTableProps) => {
  // hookes

  const { createZipFromLinks, loading, error } = useZipFromLinks();

  const [tableData, setTableData] = useState<LegalFormTable[]>([]);

  const [loader, setLoader] = useState({
    btnLoader: false,
    isZipDownloading: false,
  });

  console.log('legalFormDocuments', legalFormDocuments);

  function getLegalDocumentTableData(legalData: any) {
    const result: LegalFormTable[] = [];
    let idCounter = 1;

    // Helper function to traverse nested objects/arrays
    function traverse(obj: any, type: any, parentKey: any) {
      if (Array.isArray(obj)) {
        obj.forEach((item) => {
          if (item.caseNumber) {
            traverse(item, type, item.caseNumber); // For "Federal Tax" cases
          } else {
            traverse(item, type, parentKey); // Other cases
          }
        });
      } else {
        for (const key in obj) {
          if (Array.isArray(obj[key])) {
            // Check for 'files' array with 'urlKey' and create entry
            const filesArray = obj[key];
            if (filesArray.some((file: any) => file.urlKey)) {
              result.push({
                type,
                description: LegalTableDesKeyMapping[parentKey] ?? parentKey,
                documentType: key,
                id: idCounter++,
                files: filesArray.map((file: any) => ({
                  link: file.link || null,
                  linkId: file.linkId || null,
                  urlKey: file.urlKey || null,
                  updatedInfo: file?.updatedInfo || null,
                })),
              });
            }
          } else if (typeof obj[key] === 'object') {
            // Recursive call, updating 'parentKey' with the current key
            traverse(obj[key], type, key);
          }
        }
      }
    }

    // Start traversing for each main key in legalData
    for (const type in legalData) {
      const typeData = legalData[type];
      const initialParentKey = type === 'Federal Tax' ? null : type;
      traverse(typeData, type, initialParentKey);
    }

    return result;
  }

  useEffect(() => {
    const cleanCaseNumber = cleanObject(
      cleanCaseNumberObjects(legalFormDocuments)
    );
    if (cleanCaseNumber && Object.keys(cleanCaseNumber)?.length > 0) {
      // const sampleData = {
      //   'Guarantees for Indebtedness': {
      //     'Guarantee Details': {
      //       financialDetails: {
      //         'Terms of Indebtedness': [
      //           {
      //             link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //             linkId: '7bit',
      //             urlKey: 'applicant/legal/7mb.pdf-1728044954278',
      //           },
      //           {
      //             link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //             linkId: '7bit',
      //             urlKey: 'applicant/legal/7mb1.pdf-1728044954278',
      //           },
      //         ],
      //       },
      //     },
      //     'Legal and Regulatory Compliance': {
      //       guaranteeAgreementDocumentation: {
      //         'Legal Documents': [
      //           {
      //             link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //             linkId: '7bit',
      //             urlKey: 'applicant/legal/7mb.pdf-1728044954278',
      //           },
      //           {
      //             link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //             linkId: '7bit',
      //             urlKey: 'applicant/legal/7mb1.pdf-1728044954278',
      //           },
      //         ],
      //       },
      //     },
      //   },
      //   'Change in Control': {
      //     documentation: {
      //       'Regulatory Filings': [
      //         {
      //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //           linkId: '7bit',
      //           urlKey: 'applicant/legal/7mb.pdf-1728044954278',
      //         },
      //         {
      //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //           linkId: '7bit',
      //           urlKey: 'applicant/legal/7mb1.pdf-1728044954278',
      //         },
      //       ],
      //       'Legal Documentation': [
      //         {
      //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //           linkId: '7bit',
      //           urlKey: 'applicant/legal/7mb.pdf-1728044954278',
      //         },
      //         {
      //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //           linkId: '7bit',
      //           urlKey: 'applicant/legal/7mb1.pdf-1728044954278',
      //         },
      //       ],
      //       'Shareholder Agreements': [
      //         {
      //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //           linkId: '7bit',
      //           urlKey: 'applicant/legal/7mb.pdf-1728044954278',
      //         },
      //         {
      //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //           linkId: '7bit',
      //           urlKey: 'applicant/legal/7mb1.pdf-1728044954278',
      //         },
      //       ],
      //     },
      //   },
      //   'Federal Tax': [
      //     {
      //       caseNumber: '23132084',
      //       'Court Filing': [
      //         {
      //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //           linkId: '7bit',
      //           urlKey: 'applicant/legal/7mb.pdf-1728044954278',
      //         },
      //       ],
      //     },
      //     {
      //       caseNumber: '202303170256',
      //       'Payment Plan': [
      //         {
      //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/TOC.pdf-1728044984926?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=40f3382c6b2590d960cebc95ca2d92fd0bc12d73b375b2b7ad4b8c145031699e&X-Amz-SignedHeaders=host&x-id=GetObject',
      //           linkId: '7bit',
      //           urlKey: 'applicant/legal/TOC.pdf-1728044984926',
      //         },
      //       ],
      //     },
      //   ],
      //   Suits: [
      //     {
      //       caseNumber: 'A2401607',
      //       'Court Filing': [
      //         {
      //           link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/applicant/legal/7mb.pdf-1728044954278?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOU4IJDSDX5%2F20241017%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241017T113852Z&X-Amz-Expires=604800&X-Amz-Signature=053d75643d4d86774b9797cef28f1d8fd264522345183fd50da78c2364d0e0f2&X-Amz-SignedHeaders=host&x-id=GetObject',
      //           linkId: '7bit',
      //           urlKey: 'applicant/legal/7mb.pdf-1728044954278',
      //         },
      //       ],
      //     },
      //   ],
      // };
      const tData = getLegalDocumentTableData(cleanCaseNumber);
      setTableData(tData); // Assuming setTableData is used to update your state
      console.log('tData', cleanCaseNumber, tData); // Debugging info to show final results
    }
  }, [legalFormDocuments]);

  const columns = useMemo<MRT_ColumnDef<LegalFormTable>[]>(
    () => [
      {
        accessorKey: 'type',
        header: 'Type',
        size: 20,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 20,
        maxSize: 20,
      },
      {
        accessorKey: 'documentType',
        header: 'Document Type',
        size: 20,
        maxSize: 20,
      },
      {
        accessorKey: 'updatedInfo',
        header: 'Time',
        size: 20,
        maxSize: 20,
        Cell: ({ cell, row }) => {
          const fileListDates = row?.original?.files?.map(
            (elm) => elm?.updatedInfo
          );
          // const dateArray = [
          //   '2024-10-18T09:09:52.000Z',
          //   '2024-10-16T15:30:00.000Z',
          //   '2024-10-20T16:00:00.000Z',
          //   '2024-10-20T11:25:00.000Z', // Same date and time as above
          //   '2024-10-19T08:00:00.000Z',
          //   '2024-10-18T09:09:52.000Z', // Same as the first one
          // ];
          const maxDateTime = findMaxDateTime(fileListDates);
          console.log(
            'maxDateTime',
            moment(maxDateTime).format('DD-MM-YYYY hh:mm A'),
            fileListDates
          );

          return moment(new Date()).format('DD-MM-YYYY hh:mm A');
        },
      },
      {
        accessorKey: 'files',
        header: 'Files',
        Cell: ({ cell, row }) => {
          return (
            <ChakraTooltip fontSize="md" label="File Download" placement="auto">
              <Box>
                <CgFileDocument
                  size={20}
                  onClick={() => {
                    createZipFromLinks({
                      links: (cell?.getValue() as any[])?.map(
                        (item: any) => item?.link
                      ),
                      zipName: `${zipName.applicantName} - ${zipName.applicationName || ''}`,
                    });
                  }}
                />
              </Box>
            </ChakraTooltip>
          );
        },
      },
    ],
    []
  );

  // function used to create folder/subfolder same as res object structure(to ctreate nested subfolder for nested object)
  const processNestedData = async (
    obj: any,
    folder: any,
    mainKey: string
  ): Promise<void> => {
    // console.log('Object.keys(obj)', Object.keys(obj));
    const promises = Object.keys(obj).map(async (key, index) => {
      // console.log('key333', key, mainKey);

      if (Array.isArray(obj[key])) {
        // Only create the folder if the array has items
        if (obj[key].length > 0) {
          // console.log('obj[key]222', obj[key], obj, key);

          // const subFolderName = getFolderName(key); // Rename numeric keys
          // console.log('subFolderName', subFolderName, key);

          const subFolder = folder.folder(key); // Create folder for the array
          const links = obj[key]?.map((y: Record<string, any>) => y.link);

          await Promise.all(
            links.map(async (link: string, i: number) => {
              try {
                const response = await axios.get(link, {
                  responseType: 'arraybuffer',
                });
                axios.defaults.headers.post['access-control-allow-origin'] =
                  '*';
                const name = getFileNameFromURL(link);
                // console.log('file-response', response.data, name);

                subFolder &&
                  subFolder.file(`${name || `file` + i}`, response.data); // Adjust file name and extension
              } catch (err) {
                // console.log('api-err', err);
              }
            })
          );
        }
      } else if (typeof obj[key] === 'object') {
        // Recursively process nested objects, skipping empty objects or arrays
        // console.log('obj[key]111', obj[key], obj, key, mainKey);

        const subFolderName = getFolderName(key, mainKey, obj[key]); // Rename numeric keys
        const subFolder = folder.folder(subFolderName); // Create a folder for the object key
        await processNestedData(obj[key], subFolder, mainKey); // Recursively process nested objects
      }
    });
    await Promise.all(promises);
  };

  const createZipStructure = async (data: Record<string, any>) => {
    const zipFolderName = `${zipName.applicantName} - ${zipName.applicationName}_Legal`;
    const zip = new JSZip();

    // Loop over top-level keys in the data object
    const promises = Object.keys(data).map(async (mainKey) => {
      const mainValue = data[mainKey];

      // Create a main folder for each top-level key
      const mainFolder = zip.folder(mainKey);

      // Process the nested structure inside each main folder
      await processNestedData(mainValue, mainFolder, mainKey);
    });

    await Promise.all(promises);

    // Generate the zip
    const zipBlob = await zip.generateAsync({ type: 'blob' });

    console.log('zipBlob', zipBlob);
    // Create a download link for the zip file
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.download = `${zipFolderName}.zip`;
    downloadLink.click();
  };

  const handleExportLegalFormData = async () => {
    setLoader({ ...loader, isZipDownloading: true });

    //arrange zip folderwise data
    //if object key has multiple object then add folder,if single object then don't add folder,and add nested object key is array then add that upload field folder.
    const folderStructureFormat = restructureObject(legalFormDocuments);
    console.log('folderStructureFormat', folderStructureFormat);

    //clean caseNumberKey and then cleanObject to remove blank array
    const cleanCaseNumber = cleanObject(
      cleanCaseNumberObjects(folderStructureFormat)
    );
    console.log('cleanCaseNumber', cleanCaseNumber);

    // await createZipStructure(folderStructureFormat);
    await createZipStructure(cleanCaseNumber);
    setLoader({ ...loader, isZipDownloading: false });
  };

  const table = useMantineReactTable({
    columns: columns,
    data: tableData as any,
    mantineCreateRowModalProps: {
      className: '',
    },
    // layoutMode: 'grid',
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'custom', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    getRowId: (row, index) => row?.id?.toString() ?? '' + index,
    defaultColumn: { minSize: 10, maxSize: 10, size: 10 },
    initialState: {
      density: 'xs',
      columnPinning: { left: ['mrt-row-actions'] },
    },
    // mantineTableProps: {
    //   sx: {
    //     tableLayout: 'fixed',
    //   },
    // },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: isTableLoading ? 'hidden' : 'auto',
      },
      className: 'upload-reports-table',
    },
    mantineTableHeadCellProps: {
      sx: {
        '& .mantine-TableHeadCell-Content': {
          justifyContent: 'space-between',
        },
      },
    },
    enableRowActions: false,
    enableTopToolbar: true,
    enablePagination: true,
    positionPagination: 'none',
    enableBottomToolbar: false,
    // enableExpanding: false,
    renderTopToolbar: ({ table }) => {
      console.log('tableData', tableData, legalFormDocuments);

      return (
        <Flex direction={'column'}>
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            flexWrap={'wrap'}
            ml={'12px'}
          >
            <Button
              color="lightblue"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportLegalFormData}
              leftIcon={<IconDownload />}
              variant="filled"
              className="primary-btn"
              isDisabled={
                tableData.length === 0 ||
                Object.keys(legalFormDocuments)?.length === 0
              }
              isLoading={loader?.btnLoader || loader?.isZipDownloading}
              loadingText="Download"
            >
              Download
            </Button>
            <Flex py={1} alignItems={'center'} flexWrap={'wrap'}>
              <Flex alignItems={'center'} gap={4} p={3}>
                <MRT_GlobalFilterTextInput table={table} />
                <MRT_ToolbarInternalButtons table={table} />
              </Flex>
              <Divider
                size="md"
                orientation="vertical"
                h={40}
                style={{ alignSelf: 'center' }}
              />
              <Box className="pagination-wrapper">
                <MRT_TablePagination position="top" table={table} />
              </Box>
            </Flex>
          </Flex>
          {isTableLoading && <Progress value={100} animate={true} />}
        </Flex>
      );
    },
    state: {
      showProgressBars: isTableLoading,
      isLoading: isTableLoading,
    },
  });

  return (
    <Box maxW={'1550px'} w={'100%'} py={5}>
      <MantineReactTable table={table} key={'upload'} />
    </Box>
  );
};

export default LegalFormUploadFilesTable;
