import { Box, Heading, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

type FullScreenLayoutPropsType = {
  children: React.ReactNode;
  title?: string;
};

const FullScreenLayout = ({ children, title }: FullScreenLayoutPropsType) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        pl={4}
        py={2}
        display={'flex'}
        justifyContent={'start'}
        alignItems={'center'}
        gap={4}
        cursor={'pointer'}
        borderBottomWidth="1px"
      >
        <Tooltip label="Go back" placement="right" hasArrow>
          <Box>
            <MdArrowBack
              onClick={() => {
                navigate(-1);
              }}
              color="#114684"
              size={32}
              style={{
                backgroundColor: '#ffa011',
                borderRadius: '50%',
                padding: '2px',
              }}
            />
          </Box>
        </Tooltip>

        <Heading fontSize={'20px'} fontWeight={'600'}>
          {title}
        </Heading>
      </Box>

      <Box px={4} maxWidth={'100vw'}>
        {children}
      </Box>
    </>
  );
};

export default FullScreenLayout;
