export const getAccordianSectionName: { [key: string]: string } = {
  guaranteeDetails: 'Guarantee Details',
  impactOnFinancialHealth: 'Impact on Financial Health',
  legalAndRegulatoryCompliance: 'Legal and Regulatory Compliance',
  globalUltimate: 'Global Ultimate',
  domesticUltimate: 'Domestic Ultimate',
  parent: 'Parent',
  changeInControl: 'Change in Control',
  changeInOwnership: 'Change in Ownership',
  changeInManagement: 'Change in Management',
  documentation: 'Documentation',
  futurePlans: 'Future Plans',
  generalInformation: 'General Information',
  claimDetails: 'Claim Details',
  projectDetails: 'Project Details',
  financialDetails: 'Financial Details',
  resolutionAndSettlement: 'Resolution and Settlement',
  impactOnCompany: 'Impact on Company',
  legalAndDocumentation: 'Legal and Documentation',
  riskAssessmentAndMitigation: 'Risk Assessment and Mitigation',
  communication: 'Communication',
  futureRisks: 'Future Risks',

  genralInfo: 'General Information',
  agreementTerms: 'Agreement Terms',
  ownershipInfo: 'Ownership Information',
  financialImpect: 'Financial Impact',
  legalDocumentation: 'Legal and Documentation',
  impectOnBussinessOpretions: 'Impact on Business Operations',
  complianceAndCommunication: 'Compliance and Communication',
  futurePlanning: 'Future Planning',
  // riskAssessmentAndMitigation: 'Risk Assessment and Mitigation'

  hasOutstandingJudgments: 'Judgements',
  hasOutstandingSuits: 'Suits',
  hasOutstandingClaims: 'Claims',
};

export const getFormikStateOfAccordianState: { [key: string]: string } = {
  receivershipOrBankruptcyAcc: 'hasBankruptcyHistory',
  guaranteesForOtherPartiesAcc: 'hasGuarantees',
  relatedCompaniesAcc: 'hasRelatedCompanies',
  changesInManagementAcc: 'hasChangesInManagement',
  bondClaimsAcc: 'hasClaimsSuretybond',
  buySellAgreementAcc: 'hasBuySellAgreement',
  // outstandingJudgmentsAcc
};

export function getHistoryDataOfAccordianState(data: any, key: string) {
  // Check if the key exists in the data object
  if (data && data?.[key]) {
    return data[key];
  } else {
    return null;
  }
}

export const checkHasGuarantees = (obj: any) => {
  // Check if hasGuarantees is not null
  if (obj.hasGuarantees !== null) {
    // Get all keys of the object
    const keys = Object.keys(obj);

    // Iterate through each key except hasGuarantees
    for (let key of keys) {
      // Ignore the hasGuarantees key
      if (key !== 'hasGuarantees') {
        // If any key inside has a non-null value, return true
        if (obj[key] !== null) {
          return true;
        }
      }
    }
    // If all keys (excluding hasGuarantees) are null, return false
    return false;
  }

  // hasGuarantees is null, return false
  return false;
};

export const hasNonNullOrObject = (obj: any) => {
  return obj
    ? obj &&
        typeof obj === 'object' &&
        Object.values(obj).some((value) =>
          value !== null && typeof value === 'object'
            ? Object.keys(value).length > 0
            : value !== null
        )
    : false;
};

//hasNonNullOrObject check for nested object value
export const IsNonNullOrObject: any = (obj: any) => {
  return obj
    ? obj &&
        typeof obj === 'object' &&
        Object.values(obj).some((value) =>
          value !== null && typeof value === 'object'
            ? Object.keys(value).length > 0 && IsNonNullOrObject(value)
            : !!value
        )
    : false;
};

export const getNonNullSections = (
  guaranteesArray: any[],
  data: any
): any[] => {
  return guaranteesArray
    .map((section) => {
      const sectionName = section.name;
      const sectionData = data?.[sectionName];

      // Skip the section if its data is null or not an object
      if (!sectionData || typeof sectionData !== 'object') return null;

      // Check if the section has sub-sections or fields directly
      if (section.subSection) {
        const nonNullSubSections = section.subSection
          .map((subSection: any) => {
            const subSectionName = subSection.name;
            const subSectionData = sectionData?.[subSectionName];
            console.log('subSectionData', subSectionData);

            if (!subSectionData || typeof subSectionData !== 'object')
              return null;

            // Filter fields based on non-null, non-empty values
            const nonNullFields = subSection.fields.filter((field: any) => {
              const fieldData =
                subSectionData[field.name.split('.').pop() as string];
              return (
                fieldData !== null &&
                fieldData !== undefined &&
                fieldData !== ''
              );
            });

            // Only include the subSection if it has non-null fields
            return nonNullFields.length > 0
              ? { ...subSection, fields: nonNullFields }
              : null;
          })
          .filter((subSection: any) => subSection !== null);

        return nonNullSubSections.length > 0
          ? { ...section, subSection: nonNullSubSections }
          : null;
      } else if (section.fields) {
        // Handle fields directly in the section
        const nonNullFields = section.fields.filter((field: any) => {
          const fieldData = sectionData[field.name.split('.').pop() as string];
          return (
            fieldData !== null && fieldData !== undefined && fieldData !== ''
          );
        });

        return nonNullFields.length > 0
          ? { ...section, fields: nonNullFields }
          : null;
      }

      return null;
    })
    .filter((section: any) => section !== null);
};

export const liensTableUploadFileds = ['courtFilings', 'paymentPlanUpload'];

export const legalFormAccUploadFields = [
  'termsOfIndebtedness',
  'legalDocuments',
  'legalDocumentation',
  'shareholderAgreements',
  'regulatoryFilings',
  'agreementCopy',
];

export const liensTableSchemaArrayFields = [
  'nameOfLienHolder',
  'nameOfDebtor',
  'nameOfPlaintiff',
  'nameOfDefendant',
  'nameOfDebtors',
  'nameOfCreditors',
];

export const liensTableSchemaObjectFields = ['filingAmount'];

//pdf
export const getAccordianSubSectionName: { [key: string]: string } = {
  guarantorInformation: 'Guarantor Information',
  beneficiaryInformation: 'Beneficiary Information',
  guaranteeAgreementDetails: 'Guarantee Agreement Details',
  financialDetails: 'Financial Details',
  collateralAndSecurity: 'Collateral and Security',
  currentStatusOfIndebtedness: 'Current Status of Indebtedness',
  guaranteeAgreementDocumentation: 'Guarantee Agreement Documentation',
  changeinShareholder: 'Change in Shareholder',
  minorityshareholder: 'Minority Shareholder',
  majorityshareholder: 'Majority Shareholder',
  generalInfoRelatedCompanies: 'General Information',
  companyDetails: 'Company Details',
  operationalAndManagementDetails: 'Operational and Management Details',
};
